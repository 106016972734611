.socialPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();
    @include aecf8dbe_5e54_4dfb_99b3_ca43f3a588f4();

    .-creditsBar {
        margin-bottom: 1.2rem;
        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .-settings {
        width: 100%;

        > .-list {
            @include flex(flex-start, flex-start, column);
            width: 100%;
            margin-bottom: 1.2rem;

            > .-item {
                &.-disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                    pointer-events: none;
                    filter: alpha(opacity=50);
                    zoom: 1;
                    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
                    -moz-opacity: 0.5;
                    -khtml-opacity: 0.5;
                }
                @include flex(space-between, center, row);
                padding: 1rem;
                margin-bottom: 1.2rem;
                width: 100%;
                background-color: #ffffff;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
                user-select: none;

                > .-action {
                    @include flex(space-between, center, row);

                    > .-btn {
                        width: 100%;
                        @include flex(space-between, center, row);
                        background-color: $color2Hex;
                        padding: 1rem;
                        border-radius: $borderRadiusXl;

                        @media (max-width: $sm) {
                            @include flex(flex-start, center, column);
                            width: 15rem;
                        }

                        > .-header {
                            @include flex(space-between, center, row);

                            @media (max-width: $sm) {
                                @include flex(flex-start, center, column);
                                margin-bottom: 1rem;
                            }

                            > .-text {
                                margin-right: 2rem;

                                @media (max-width: $sm) {
                                    margin-right: 0;
                                }

                                > .-title {
                                    font-size: $fontSize;
                                    font-weight: $fontWeightLg;
                                }

                                > .-subTitle {
                                    font-size: 14px;
                                    font-weight: $fontWeightSm;
                                }
                            }
                            > .-error {
                                color: $colorRedIndianHex;
                                font-size: 12px;
                            }
                            > .-logo {
                                @include flex(space-between, flex-end, row);

                                @media (max-width: $sm) {
                                    @include flex(flex-start, center, row);
                                }

                                > img {
                                    width: 32px;
                                    height: 32px;
                                }

                                > .-icon {
                                    background-color: #ffffff;
                                    border-radius: $borderRadiusXl;
                                    padding: 2px;
                                    margin-left: -1rem;
                                    margin-right: 1rem;

                                    > .svg-inline--fa {
                                        height: 16px !important;
                                    }
                                }
                            }
                        }
                        > .-icon {
                            margin-left: 1rem;
                            cursor: pointer;

                            > .svg-inline--fa {
                                height: 24px !important;
                            }
                        }
                    }

                    > .--error {
                        border: solid 1px $colorRedIndianHex;
                    }
                }

                > .-icon {
                    @include flex(flex-start, center, row);
                    > .svg-inline--fa {
                        height: 32px !important;
                    }
                    > .-title {
                        margin-left: 2rem;
                        font-size: 20px;
                        font-weight: $fontWeightLg;
                    }
                }
            }
        }
    }

    @media (max-width: $sm) {
        padding: 1.2rem 1rem 2rem;
    }
}
