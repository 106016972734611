@import '../base';
@import 'bridge/index';
@import 'css_var';
@import 'organization/index';
@import 'template/index';
@import 'user/index';
@import 'video/index';
@import 'workspace/index';

body {
    background-color: transparent;
}

a {
    user-select: none;

    &:hover,
    &:focus,
    &:active {
        user-select: none;
    }
}

.noscript {
    text-align: center;
    background: white;
    margin: 3rem;
    padding: 2rem;
    border: 0.3rem solid lightseagreen;
    font-weight: bold;
}

input::placeholder,
textarea::placeholder,
.-select [class$='-placeholder'] {
    color: darkgrey !important;
}

.html {
    min-height: 100%;
    font-weight: $fontWeightMd;
    background-color: $colorGreyCloudHex;
    transition: all 0s !important;

    &.\/modalOpen {
        background-color: white;

        .flashBox {
            @media (min-width: $sm + 1) {
                top: 0;
            }
        }
    }

    &.\/mode {
        &\/demo {
            #tarteaucitronRoot {
                display: none !important;
            }
        }
    }

    body {
        width: 100%;
        font-family: $font, sherif;
        font-size: $fontSize;
        background-color: transparent;
        color: $colorGreySlateHex;
        // this will prevent any css bug when Stripe (or others) open modals, etc.
        position: initial !important;
        inset: initial !important;
        padding-right: 0 !important;
    }

    // bug fix, with videos hiding browser dom elements
    video {
        opacity: 0.99;
    }
}

.root {
    transition: all 0s !important;

    > .-core {
        transition: all 0s !important;

        &.\/hidden {
            @include hidden();

            header {
                display: none;
            }
        }

        &.\/sdkMode {
            padding: 0;
        }
    }
}

.appLoader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.innerLoader {
    position: absolute;
    right: 50%;
    top: 50%;
}

textarea {
    @include scrollbar();
}

* {
    //transition: all 0.3s;
    box-sizing: border-box;
}

[aria-label='animation'] {
    transition: all 0s !important;

    * {
        transition: all 0s !important;
    }
}

button,
input,
select,
textarea {
    font-family: $font, sherif;
    font-size: $fontSize;
    transition: all 0s !important;
}

label {
    margin-bottom: 0.1rem;
    color: lightslategray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    margin-bottom: 0;
    margin-top: 0;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.chrome-picker {
    transition: all 0s;

    * {
        transition: all 0s;
    }
}

@font-face {
    font-family: $font;
    src: url('/font/Muli/Muli-Black.ttf');
    font-weight: $fontWeightXl;
}

@font-face {
    font-family: $font;
    src: url('/font/Muli/Muli-Bold.ttf');
    font-weight: $fontWeightLg;
}

@font-face {
    font-family: $font;
    src: url('/font/Muli/Muli-Regular.ttf');
    font-weight: $fontWeightMd;
}

@font-face {
    font-family: $font;
    src: url('/font/Muli/Muli-Light.ttf');
    font-weight: $fontWeightSm;
}

@font-face {
    font-family: $font;
    src: url('/font/Muli/Muli-ExtraLight.ttf');
    font-weight: $fontWeightXs;
}

.cursorPointer {
    cursor: pointer;
}

.discreetLink {
    @include discreetLink();
}

.link {
    @include link();
}

.hidden {
    @include hidden();
}

.tlHidden {
    @include tlHidden();
}

.hHidden {
    @include hHidden();
}

.dDidden {
    @include dHidden();
}

.oHidden {
    @include oHidden();
}

.vHidden {
    @include vHidden();
}

.cHidden {
    @include cHidden();
}

.flex {
    @include flex();
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
}
