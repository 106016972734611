.switch {
    &.\/checked {
        .react-switch {
            &-handle {
                background: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
            }
            &-bg {
                background: color(1, $l: calc(var(--color-1-dark) * (var(--color-1-l) - 10%) + var(--color-1-light) * 28%)) !important;
            }
        }
    }
}
