.template\:modal {
    &.\/font {
        .-content {
            @include flex();
            width: 100%;
            .-upload {
                width: 100%;
                z-index: 999999;
            }
            .or {
                margin: 1.2rem 0 0.8rem;
                width: 100%;
            }
            .-family,
            .-style,
            .-postscriptName {
                width: 100%;
                margin-bottom: 0.4rem;
            }
            .-submit {
                margin-top: 1rem;
            }
        }
    }
}
