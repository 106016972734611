.bo\:listItemPage.\/template {
    .-inputs {
        flex-flow: row wrap;
    }
    .-header {
        .-info {
            @include flex();
            width: 100%;
            margin-bottom: 0.3rem;
            .-separator {
                margin: 0 0.3rem;
            }
            .-testVideo {
                width: 100%;
            }
        }
        .-state {
            .dropdown-menu {
                text-align: left;
            }
            > button {
                @include flex(center, center, row);
            }
        }
    }
    .-generalForm {
        .-source,
        .-parent,
        .-organization,
        .-workspace,
        .-library,
        .-level,
        .-name,
        .-description,
        .-categories {
            width: 100%;
            margin-bottom: 0.7rem;
        }
        .-source {
            width: calc(50% - 0.4rem);
            margin-right: 0.8rem;
        }
        .-parent {
            width: calc(50% - 0.4rem);
        }
    }
    .-configForm {
        @include flex();
        width: 100%;
        position: relative;
        margin-top: 1rem;
        &.\/mode {
            &\/advanced {
                .animation {
                    transition: 0s;
                    padding: 2.4rem 0 200vh;
                }
                .video\:studio {
                    @include hidden();
                    .video\:studioCompo {
                        content-visibility: hidden !important;
                    }
                }
            }
            &\/config {
                .-form,
                .video\:studio.\/mode\/preview {
                    @include hidden();
                    .video\:studioCompo {
                        content-visibility: hidden !important;
                    }
                }
            }
            &\/preview {
                .-form,
                .video\:studio.\/mode\/config {
                    @include hidden();
                    .video\:studioCompo {
                        content-visibility: hidden !important;
                    }
                }
            }
        }
        .-mode {
            @include flex(space-around, center, row);
            position: absolute !important;
            top: -4.4rem;
            right: 2rem;
            font-size: 82.5%;
            * {
                user-select: none;
            }
            [disabled] {
                opacity: 0.3;
                ~ label {
                    opacity: 0.3;
                }
            }
            > label {
                transform: rotateZ(-90deg);
                color: darkgrey;
                border-style: solid;
                border-width: 0 0 1px;
                border-color: darkgrey;
                width: 4rem;
                text-align: center;
                margin: 0 -1rem;
            }
            span {
                color: grey;
                font-size: 85%;
            }
            > .-buttons {
                @include flex(center, center, row);
                > .-preview,
                > .-softReset,
                > .-hardReset,
                > .-resetPreviewSet,
                > .-foldAll,
                > .-unfoldAll {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin: 0 0.2rem;
                    cursor: pointer;
                    border-radius: 10rem;
                    border-style: solid;
                    border-width: 0.08rem;
                    background-color: white;
                    > .-shell {
                        @include flex();
                    }
                }
                > .-foldAll,
                > .-unfoldAll {
                    border-color: darkturquoise;
                    svg {
                        fill: darkturquoise;
                        * {
                            fill: darkturquoise;
                        }
                    }
                }
                > .-preview {
                    border-color: $colorGreenGrassHex;
                    svg {
                        fill: $colorGreenGrassHex;
                        * {
                            fill: $colorGreenGrassHex;
                        }
                    }
                }
                > .-softReset,
                > .-resetPreviewSet {
                    border-color: coral;
                    svg {
                        fill: coral;
                        * {
                            fill: coral;
                        }
                    }
                }
                > .-hardReset {
                    border-color: indianred;
                    svg {
                        fill: indianred;
                        * {
                            fill: indianred;
                        }
                    }
                }
            }
        }
        .-form {
            @include flex();
            max-width: 50rem;
            width: 100%;
            .-config,
            .-delta {
                width: 100%;
                margin: 0.7rem;
            }
        }
        .-competitor {
            @include flex();
            .-portrait {
                margin: 1rem;
                border-radius: 500px;
                overflow: hidden;
                border-color: var(--color-1-hsl);
                border-width: 0.2rem;
                border-style: solid;
            }
            .-names {
                width: 100%;
                font-size: 110%;
                font-weight: bold;
                text-align: center;
                color: var(--color-1-hsl);
            }
            .-wait {
                width: 100%;
                text-align: center;
            }
        }
        .video\:studio {
            width: 100%;
        }
    }
    .-children {
        @include flex(center, flex-start, row wrap);
        padding: 0.5rem 1rem 0 1rem;
        width: 100%;
        .-child {
            @include flex(center, flex-start, row wrap);
            align-content: baseline;
            background-color: white;
            position: relative;
            width: 10rem;
            min-height: 10rem;
            padding: 1rem;
            margin: 0.7rem;
            border-radius: $borderRadiusMd;
            border-width: 0.2rem;
            border-style: solid;
            &:hover,
            &:focus {
                @include shadow();
                .-buttons {
                    > * {
                        @include flex();
                        visibility: visible;
                        width: 1.7rem;
                        height: 1.7rem;
                        svg {
                            width: 1.1rem !important;
                            * {
                                display: initial;
                            }
                        }
                    }
                    .-preview,
                    .-validate {
                        border-color: var(--color-1-hsl);
                    }
                    .-draft {
                        border-color: grey;
                    }
                    .-remove {
                        border-color: $colorRedIndianHex;
                    }
                }
            }
            &.\/draft {
                border-color: lightgrey;
            }
            &.\/valid {
                border-color: limegreen;
            }
            &.\/almostValid {
                border-color: orange;
            }
            .-buttons {
                @include flex(flex-start, flex-start, column);
                position: absolute;
                top: 0.7rem;
                left: calc(100% - 0.8rem);
                > * {
                    margin-bottom: 0.6rem;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    cursor: pointer;
                    border-color: transparent;
                    border-radius: 10rem;
                    border-style: solid;
                    border-width: 0.08rem;
                    background-color: white;
                    > .-shell {
                        @include flex();
                    }
                    svg {
                        width: 0 !important;
                        * {
                            display: none;
                        }
                    }
                }
                .-preview,
                .-validate {
                    color: var(--color-1-hsl);
                }
                .-draft {
                    color: grey;
                }
                .-remove {
                    color: $colorRedIndianHex;
                }
            }
            .-name {
                max-width: 80%;
                word-break: break-word;
                text-align: center;
                font-weight: $fontWeightXl;
                margin-bottom: 0.4rem;
                &.\/none {
                    opacity: 0.6;
                }
            }
            .-special,
            .-workspace,
            .-organization,
            .-library,
            .-categories {
                width: 100%;
                margin-bottom: 0.4rem;
                text-align: center;
                > div {
                    width: 100%;
                    &:first-of-type {
                        font-size: 80%;
                        opacity: 0.6;
                    }
                }
            }
            .-teenSwitch {
                @include flex(center, center, row wrap);
                margin-top: 0.8rem;
                > span {
                    @include flex(center, center, row);
                    margin-top: 0.4rem;
                    width: 100%;
                }
            }
        }
        .-addChildForm {
            @include flex(center, flex-start, row wrap);
            position: relative;
            border-radius: $borderRadiusMd;
            width: 17rem;
            padding: 0 1rem;
            margin: 0.7rem;
            .or {
                margin: 0;
                padding: 0;
                .-body {
                    padding: 0 0.3rem;
                }
            }
            .-name,
            .-workspace,
            .-organization,
            .-library {
                width: 100%;
                margin-bottom: 0.3rem;
            }
            .-mother {
                @include flex(flex-end, flex-end);
                font-size: 80%;
                width: 100%;
                user-select: none;
                label {
                    &:before,
                    &:after {
                        transform: translateY(-0.2rem);
                    }
                }
            }
            .-validate {
                margin-top: 0.6rem;
                text-decoration: underline;
                cursor: pointer;
                color: var(--color-1-hsl);
                user-select: none;
            }
        }
    }
    .-submit {
        &.\/waiting {
            cursor: wait;
        }
    }
}
