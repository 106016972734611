.video {
    position: relative;
    .-play {
        height: 4rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.7;
        z-index: 2;
        svg {
            height: 100%;
            -webkit-filter: drop-shadow(0 0 0.2rem #{$colorGreySlateHex});
            filter: drop-shadow(0 0 0.2rem #{$colorGreySlateHex});
            fill: white;
        }
    }
    .-volume {
        position: absolute;
        bottom: 1rem;
        right: -3rem;
        -webkit-filter: drop-shadow(0 0 0.2rem #{$colorGreySlateHex});
        filter: drop-shadow(0 0 0.2rem #{$colorGreySlateHex});
        fill: white;
        z-index: 1;
        * {
            fill: white;
        }
        &.\/mute {
            opacity: 0.6;
        }
    }
    &:hover,
    &:focus {
        .-volume {
            right: 1rem;
        }
    }
    img,
    video {
        width: 100%;
    }
}
