.bo\:listItemPage.\/video {
    .-header {
        .-generationMetrics {
            @include flex();
            margin-bottom: 0.3rem;
            opacity: 0.7;
            .-author,
            .-download {
                width: 100%;
                text-align: center;
            }
            .-more {
                color: var(--color-1-hsl);
                text-decoration: underline;
                font-size: 80%;
                cursor: pointer;
            }
            .-state {
                .dropdown-menu {
                    text-align: left;
                }
                span {
                    border: none;
                    color: white;
                    padding: 0.1rem 0.4rem;
                    border-radius: 0.4rem;
                    font-size: 90%;
                }
            }
            .\/export {
                .-duration,
                .-percentage {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
            table {
                td,
                th {
                    padding: 0.4rem 1rem;
                }
                thead {
                    border-color: lightgrey;
                    border-style: solid;
                    border-width: 0 0 1px;
                    background-color: slategrey;
                    color: white;
                }
                tbody {
                    tr {
                        &:nth-child(odd) {
                            background-color: white;
                        }
                    }
                }
            }
        }
        .-state {
            margin: 0.2rem 0 0.6rem 0;
        }
    }
    .-generalForm {
        .-name,
        .-description {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }
    .-dataForm {
        @include flex();
        max-width: 50rem;
        width: 100%;
        .-data,
        .-assets,
        .-lastSentBotData,
        .-lastErrorDetails {
            width: 100%;
            margin: 0.7rem;
        }
    }
}
