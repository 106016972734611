.-register {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: 100vh;

    .-main {
        display: flex;
        flex-direction: column;
        width: 40vw;

        .-loginSuggestion {
            color: #2c2c2c;
        }

        form {
            padding-top: 0 !important;
            h1,
            > span {
                margin: 0.5rem;
            }

            .-submit {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
            }
        }

        @media (max-width: $lg) {
            width: 100%;
            padding: 0 1rem;
        }
    }

    .-background-video,
    .-background-gif {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        transition: all 0.2s ease-in-out;
        border-radius: 2rem;
        width: 70vw;
        margin-right: 4rem;

        .-video {
            flex-grow: 1;
            border-bottom-left-radius: 2rem;
            border-top-right-radius: 2rem;
            overflow: hidden;
        }

        .-video > iframe,
        .-video > img {
            height: 100%;
            width: 100%;
            margin: 0;
            border: none;
        }

        .-video > img {
            object-fit: contain;
            object-position: center;
        }

        @media (max-width: $lg) {
            display: none;
        }
    }

    .-background-video {
        height: calc(70% - 2rem);
        display: flex;
        gap: 2rem;
    }

    .-background-gif {
        max-height: calc(70% - 2rem);
    }
}
