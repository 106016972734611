.category\:itemPage {
    padding: 2rem;
    @media (max-width: 768px) {
        padding: 0;
    }
    .videoCard {
        @media (max-width: 768px) {
            margin: 0;
        }
    }
}
