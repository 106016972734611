.organization\:whitelabelPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();

    .-creditsBar {
        margin-bottom: 1.2rem;

        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    position: relative;

    .or {
        margin-top: 1.5rem;
        width: 50%;
        color: grey;
        @media (max-width: $sm) {
            width: 100%;
        }
    }

    .-advanced {
        @include flex();
        position: absolute !important;
        top: 2rem;
        right: 2rem;
        width: 6rem;
        text-align: center;

        &Mode {
            @include flex();
            padding: 1.8rem 0.4rem 0;

            .-label {
                margin-left: 1rem;
                max-width: 24rem;
                @media (max-width: $sm) {
                    margin-top: 0.8rem;
                }
                @media (max-width: $xs) {
                    text-align: center;
                }
            }
        }

        .-caption {
            display: inline-block;
            font-size: 70%;
            width: 100%;
        }

        @media (max-width: $sm) {
            position: relative !important;
            top: 0;
            right: 0;
            margin-bottom: 1.2rem;
        }
    }

    .-logo,
    .-metaThumbnail > .-uploader {
        width: 15rem;
        height: 8rem;

        .uploader-zone {
            width: 15rem;
            height: 8rem;
            @media (max-width: $sm) {
                max-width: 100%;
            }
        }

        @media (max-width: $sm) {
            max-width: 100%;
        }
    }

    .-form {
        @include flex();
        width: 100%;

        &.\/general {
            @include flex(center, flex-start, row wrap);
            margin-top: 1rem;
            width: 50rem;
            max-width: 100%;

            > * {
                margin: 0.4rem;
            }

            .-name,
            .-domainName,
            .-metaTitle,
            .-metaDescription,
            .-metaThumbnail {
                width: calc(100% - 2 * 0.4rem);
            }

            .-metaThumbnail {
                @include flex();
                margin: 1.4rem 0 0.4rem;
            }

            .-domainName {
                .input-group-append {
                    position: relative;
                    z-index: 3;
                }

                input {
                    position: relative;
                    z-index: 2;
                }

                .-done {
                    position: relative;
                    background-color: limegreen;
                    color: white;
                    padding: 0.3rem;
                    border-radius: 50px;
                    font-size: 120%;
                    z-index: 3;
                }

                .-helper {
                    background-color: $colorOrangeNoteBackgroundHex;
                    color: $colorOrangeNoteTextHex;
                    position: relative;
                    top: -0.2rem;
                    padding: 1rem;
                    z-index: 1;
                    border-radius: 0 0 $borderRadiusMd $borderRadiusMd;
                    border: 1px solid lightgrey;

                    strong {
                        color: $colorOrangeNoteTextHex;
                    }

                    .-record {
                        background-color: yellow;
                        color: grey;
                        padding: 0.2rem 0.4rem;
                        font-weight: bold;
                    }

                    &.\/cnameReady {
                        background-color: $colorBlueNoteBackgroundHex;
                        color: $colorBlueNoteTextHex;

                        strong {
                            color: $colorBlueNoteTextHex;
                        }
                    }

                    &.\/httpsReady {
                        background-color: $colorGreenNoteBackgroundHex;
                        color: $colorGreenNoteTextHex;

                        strong {
                            color: $colorGreenNoteTextHex;
                        }
                    }
                }
            }

            .-submit {
                margin: 2rem 1rem 0.4rem;
            }
        }

        &.\/colorScheme {
            @include flex();
            width: 100%;

            .-pickers {
                @include flex();
                width: 100%;
                user-select: none;

                .-picker {
                    @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                    position: relative;
                    margin: 1rem;
                    user-select: none;

                    .-name {
                        display: inline-block;
                        user-select: none;
                        font-weight: bold;
                        width: 100%;
                        color: grey;
                        font-style: italic;
                        text-align: center;
                    }

                    .-reset {
                        position: absolute;
                        user-select: none;
                        top: calc(100% - 1rem);
                        right: -1rem;
                    }
                }
            }

            .-submit {
                margin: 1.4rem 1rem 0.6rem;
                @media (max-width: $sm) {
                    margin: 1rem;
                }
            }
        }
    }
}
