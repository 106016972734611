.video\:studio {
    padding-bottom: 5rem;

    .-snippet {
        border-radius: $borderRadiusMd;
        background-color: white;
        width: 320px;
        margin: 2rem;
        user-select: none;
        transition: 0s background-color;

        &.\/topSticky {
            &.\/sdk {
                top: 1rem;
            }
            @media (min-width: $sm + 1) {
                position: sticky;
                top: calc(#{$headerHeight} + 2rem);
            }
        }

        &.\/bottomSticky {
            @media (min-width: $sm + 1) {
                position: sticky;
                bottom: 2rem;
                align-self: flex-end; // this is needed for sticky to understand bottom here (revert of usual top usage)
            }
        }

        .-header {
            @include flex();
            position: relative;

            .-buttons {
                @include flex(flex-end, center, row);
                @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                width: 100%;
                position: absolute;
                top: 1rem;
                right: 1rem;

                .-button {
                    &.\/refresh {
                        transition: all 0s;

                        &.\/on {
                            cursor: initial;

                            > .-shell {
                                animation-name: flash;
                                animation-iteration-count: infinite;
                                animation-duration: 1.4s;
                                animation-timing-function: linear;
                            }

                            &.\/definitelyOn {
                                cursor: pointer;

                                > .-shell {
                                    border-color: white !important;
                                    color: white !important;
                                    background-color: rgba(0, 0, 0, 0.5) !important;
                                    animation-name: fd59274a_35cf_4e06_9f1d_73fbbcaddb5b;
                                    animation-iteration-count: infinite;
                                    animation-duration: 2s;
                                    animation-timing-function: linear;
                                }
                            }
                        }

                        * {
                            transition: all 0s;
                        }
                    }
                }
            }

            .-preview {
                width: 100%;
                border-radius: $borderRadiusMd $borderRadiusMd 0 0;
                overflow: hidden;
                position: relative;

                img {
                    border-radius: $borderRadiusMd;
                    width: 100%;
                }
                video {
                    background-color: lightgrey;
                }
            }

            .-title {
                @include flex(space-between, flex-start, row);
                padding: 0.8rem 1rem;
                width: 100%;
                position: relative;
                text-align: left;

                span {
                    display: inline-block;
                    width: calc(100% - 6.8rem);
                    font-weight: $fontWeightLg;
                    text-transform: uppercase;
                    line-height: 120%;
                }

                .-quickActs {
                    @include flex(center, center, row);
                    position: absolute;
                    top: 0.5rem;
                    right: 1rem;

                    svg {
                        width: 1rem;
                        cursor: pointer;
                    }

                    > * {
                        margin-left: 0.5rem;
                    }

                    .-danimCreator {
                        svg {
                            width: 1.6rem;
                        }
                    }

                    .-api,
                    .-save {
                        color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                    }

                    .-remove {
                        color: $colorRedIndianHex;
                    }
                }
            }

            .-meta {
                @include flex(space-between, flex-start, row);
                padding: 0 1rem;
                font-size: 80%;
                width: 100%;
                margin-bottom: 0.6rem;
            }
        }

        .-layers,
        .-music,
        .-ratio,
        .-theme,
        .-fonts,
        .-workspace,
        .-name,
        .-description,
        .-firstname,
        .-lastname,
        .-email,
        .-generatedInCloud {
            width: calc(100% - 2 * 1rem);
            margin: 0.5rem 1rem;

            label {
                font-weight: $fontWeightLg;
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                padding-bottom: 0.2rem;
            }
        }

        .-layers {
            @include flex(stretch, center, row);
            gap: 1rem;

            .-background,
            .-overlay {
                width: calc(100% - 1rem);

                label {
                    margin-bottom: 0;
                }

                .uploader-zone {
                    height: 4rem !important;

                    &-fog-img {
                        width: 2.5rem;
                    }
                }
            }
        }

        .-cloudCompatibilityWarning {
            background-color: $colorOrangeNoteBackgroundHex;
            color: $colorOrangeNoteTextHex;
            padding: 0.7rem 1rem;

            .-contact {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .-beingTested {
            background-color: $colorBlueNoteBackgroundHex;
            color: $colorBlueNoteTextHex;
            padding: 0.7rem 1rem;
        }

        .-generatedInCloud,
        .-fonts {
            .-label {
                @include flex(flex-start, center, row);

                .-config {
                    margin-left: 0.4rem;
                    cursor: pointer;
                }
            }

            .-warning {
                display: inline-block;
                margin-top: 0.5rem;
                padding: 0.4rem 0.6rem;
                border-radius: $borderRadiusMd;
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
            }
        }

        .-generatedInCloud {
            @include ca64ead0_6994_48d1_b530_1779a4ccb5f6('.-label >');

            .-opt {
                width: 100%;
                position: relative;

                .-fav {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    .-star {
                        color: $colorYellowStarHex;
                    }
                }

                &.\/default {
                    .-fav {
                        visibility: visible;
                    }
                }

                &:not(.\/default) {
                    .-fav {
                        visibility: hidden;
                        cursor: pointer;
                    }
                }
            }

            [class$='-singleValue'] {
                width: 100%;
            }

            [class$='-control'] {
                .-fav {
                    visibility: hidden !important;
                }
            }

            [class$='-menu'] {
                [class$='-option'] {
                    @include flex(flex-start, center, row);

                    &:hover {
                        .-fav {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .-terms {
            width: calc(100% - 2 * 1rem);
            margin: 0.5rem 1rem;
        }

        .-ratio {
            @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();

            label {
                @include flex(flex-start);
            }

            &.\/off,
            &.\/private {
                opacity: 0.3;
            }

            &.\/private {
                &:not(.\/off) {
                    &:hover,
                    &:focus {
                        opacity: 1;
                    }
                }
            }

            .-item {
                &Shell {
                    @include flex(flex-start, center, row);

                    small {
                        opacity: 0.8;
                        margin-left: 0.3rem;
                    }

                    .-icon {
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .-music {
            position: relative;

            &.\/private {
                opacity: 0.3;
            }

            &.\/private {
                &:hover,
                &:focus {
                    opacity: 1;
                }
            }

            label {
                @include flex(flex-start);
            }

            .uploader {
                width: 100%;

                svg {
                    width: auto;
                    height: 1.6rem;
                }
            }

            &:hover {
                .-buttons {
                    display: flex;
                }
            }

            &.\/off {
                .uploader {
                    @include hidden();
                }
            }

            .-buttons {
                @include flex(center, center, row);
                display: none;
                position: absolute;
                top: 0;
                right: 0;

                > * {
                    cursor: pointer;
                    margin-left: 0.3rem;
                }

                .-fav {
                    color: $colorYellowStarHex;

                    &Import {
                        color: darkorange;
                    }
                }
            }

            .-switch {
                margin-left: 0.4rem;
                position: relative;
                height: 0; // That will avoid parent height change
                top: -1.1rem;
                left: 0.2rem;

                .switch {
                    transform-origin: bottom left;
                    transform: scale(0.7);
                }
            }

            .-file {
                .-uploader-icon {
                    &:hover {
                        color: $colorYellowStarHex;
                    }
                }
                .-caption {
                    @include flex(center, center, row);
                    padding: 0;

                    svg {
                        width: 1rem;
                        height: 1rem;
                        color: $colorYellowStarHex;
                    }
                }
            }
        }

        .-fonts {
            @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();

            &.\/foldable {
                > label {
                    cursor: pointer;

                    .-foldToggle {
                        margin-left: 0.3rem;
                    }
                }
            }

            &.\/folded {
                label {
                    color: darkgrey;
                }

                .-items {
                    display: none;
                }

                .tooltipBox {
                    .-shell {
                        color: darkgrey;
                        border-color: darkgrey;
                    }
                }
            }

            .-font {
                @include flex(center, center, row);
                @include cbf5e87d_4e22_40bb_be00_c3baf6923eb5();
                width: 100%;
                padding-bottom: 0.3rem;

                &:last-of-type {
                    padding-bottom: 0 !important;
                }

                .-select {
                    width: 100%;
                }
            }

            .-items {
                label {
                    font-size: 80%;
                    padding: 0;
                }
            }

            .-add {
                position: relative;
                overflow: hidden;
                cursor: pointer;
            }

            .-upload {
                @include hidden();
            }
        }

        .-theme,
        .-font {
            @include dd6e8d3d_56eb_437c_8c60_038739b38d08();
        }

        .-theme,
        .-font.\/addable {
            @include e5a05cb1_2d23_4a00_aa46_1440e5b177a4();
        }

        .-theme {
            @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();

            * {
                caret-color: transparent;
            }

            label {
                @include flex(flex-start);
            }

            &.\/off,
            &.\/private {
                opacity: 0.3;
            }

            &.\/private {
                &:not(.\/off) {
                    &:hover,
                    &:focus {
                        opacity: 1;
                    }
                }
            }

            [class$='singleValue'] {
                .tooltipBox {
                    display: none;
                }
            }

            .-item {
                &Shell {
                    @include flex(space-between, center, row);
                }
            }

            .-root {
                @include flex(flex-start, center, row);

                .-item {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0.4rem;
                    border-radius: 100%;
                    border-color: lightgrey;
                    border-style: solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    border-width: 0.04rem;
                    font-size: 0.8rem;
                }

                .tooltipBox {
                    margin-left: 0;
                }
            }
        }

        .-description {
            textarea {
                min-height: 6rem;
            }
        }

        > .-buttons {
            margin-top: 1rem;
            border-radius: 0 0 $borderRadiusMd $borderRadiusMd;

            > .-shell {
                @include flex();
                margin-top: 0.5rem;

                > * {
                    width: 50%;
                    flex-grow: 1;

                    button {
                        width: 100%;
                    }
                }

                &.\/fixed {
                    font-size: 110%;
                    z-index: $zIndexTooltip - 10;
                    position: fixed;
                    bottom: 0;
                    right: 50%;
                    transform: translate(50%, 0);

                    &.\/hidden {
                        transform: translate(50%, 120%);
                    }

                    .-button {
                        margin: 0 0.2rem;
                        width: 2.6rem;
                        border-radius: 500px 500px 0 0;

                        svg ~ span,
                        img ~ span,
                        .image ~ span {
                            display: none;
                        }

                        svg,
                        img,
                        .image {
                            margin-right: 0;
                        }

                        .-shell {
                            padding: 0.5rem 0.75rem 0.8rem;
                        }

                        .-animation {
                            @include flex(center, center, row);
                            transform: scale(0.5);
                        }
                    }
                }
            }

            .-button {
                border-radius: 0.3rem;
                font-size: 95%;

                svg,
                img,
                .image {
                    margin-right: 0.6rem;
                }

                &.\/createToBeSentPreview,
                &.\/updateAndGeneratePreview {
                    background: linear-gradient(45deg, $colorGreySlateHex, $colorGreySpaceHex);

                    * {
                        color: white;
                    }
                }

                &.\/createToBeSent,
                &.\/updateAndGenerate,
                &.\/save {
                    width: 100%;
                    @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                }
                &.\/save {
                    background: #d5d5d5 !important;
                    margin-bottom: 0.5rem;
                    .-shell {
                        color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6() !important;
                    }
                }
            }
        }

        @media (min-width: 2101px) {
            width: 26rem;
        }
        @media (max-width: $sm) {
            width: 100%;
            margin: 1rem;
            border-radius: 0;
        }
    }
}
