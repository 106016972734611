.connect\:user\:entryPage {
    @include fb2f896b_f808_47ee_9bf2_216cadc0488a();

    .-form {
        height: 100%;

        .-subtitle {
            font-weight: $fontWeightLg;
        }

        .-speech {
            padding: 0.5rem;
            text-align: center;
        }

        .-firstname,
        .-lastname,
        .-company,
        .-phone,
        .-job,
        .-email,
        .-password,
        .-organization,
        .-sponsor,
        .-locale {
            margin: 0.5rem;
        }

        .-locale {
            margin-bottom: 1rem;
        }

        .-interests {
            margin: 0.2rem 0.5rem 0.8rem;

            &.\/shifted {
                [class$='-menu'] {
                    transform: translate(calc(100% + 0.7rem), calc(100% + 0.6rem));
                }
            }

            .-opt {
                @include flex(flex-start);
                color: initial;

                img {
                    background: black;
                    height: 1.2rem;
                    padding: 0.1rem 0.22rem;
                    border-radius: 0.3rem;
                    margin-right: 0.4rem;
                }
            }
        }

        .-company,
        .-email,
        .-password,
        .-interests,
        .-organization,
        .-firstname,
        .-lastname,
        .-phone,
        .-job,
        .-sponsor,
        .-locale {
            width: 100%;
        }

        .-terms,
        .-news {
            width: 100%;
            margin: 0 0.5rem;
            font-size: 85%;

            label {
                > div {
                    position: relative;
                    bottom: 0.18rem;
                }
            }
        }

        .localeSwitcher {
            width: 100%;
            margin-top: 1.6rem;
        }

        .-submit {
            margin: 1rem 0.5rem 0.5rem 0.5rem;
            width: 100%;
        }

        @media (max-width: $sm) {
            background-color: transparent;
            // color: white;
            .form-text.text-muted {
                color: lightgrey !important;
            }
        }
        @media (max-width: $xs) {
            padding: 2rem 0.5rem;
        }
    }

    .-speechLoading {
        padding-top: 1rem;

        .-loading {
            text-align: center;
            display: block;
        }
    }

    .-loginSuggestion {
        width: 100%;
        margin-top: 1rem;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        color: white;
    }
}
