.video\:libraryPage {
    @include flex(flex-start, center, column);
    .-title {
        width: 100%;
        text-align: left;
        font-size: 130%;
        font-weight: 900;
        padding: 1.1rem;
        padding-left: 1.2rem;
        svg {
            margin-right: 0.8rem;
        }
    }
}

.html[class*='/routed/frontOffice/'][class*='front-office'] {
    &[class*='::video/library'] {
        background-color: white;
    }
}
