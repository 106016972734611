.flashBox {
    z-index: $zIndexFlashbox;
    position: fixed;
    top: $headerHeight;
    right: 0;
    max-width: 100%;
    &.\/mode {
        &\/demo {
            top: 0;
        }
    }
    .-flashes {
        @include flex(flex-start, flex-end, column);
        padding: 0.7rem;
        max-width: 100%;
        .-flash {
            position: relative;
            user-select: none;
            margin: 0.5rem;
            font-size: 110%;
            padding: 0.5rem 0.8rem;
            border-radius: $borderRadiusMd;
            background-color: white;
            box-shadow: 0 0.1rem 0.2rem darkslategrey;
            transition: all 0s;
            &.\/animated {
                animation-name: slideInRight;
                animation-duration: 0.14s;
            }
            &.\/danger {
                background-color: $colorRedIndianHex;
                color: white;
            }
            &.\/warning {
                background-color: coral;
                color: white;
            }
            &.\/success {
                background-color: $colorGreenGrassHex;
                color: white;
            }
            &.\/info {
                background-color: cornflowerblue;
                color: white;
            }
            .-close {
                width: 1.4rem;
                height: 1.4rem;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                cursor: pointer;
                fill: slategrey;
                * {
                    fill: slategrey;
                }
            }
            .-message {
                width: 100%;
                transition: all 0s;
            }
            .-buttons {
                @include flex(flex-end, center, row);
                padding-top: 0.5rem;
                margin-top: 0.5rem;
                width: 100%;
                border-style: solid;
                border-color: lightgrey;
                border-width: 1px 0 0 0;
            }
            @media (min-width: $sm + 1) {
                min-width: 15rem;
            }
        }
    }
    @media (max-width: $sm) {
        top: initial;
        right: initial;
        padding: 0.5rem;
        bottom: 0;
        left: 0;
        font-size: 100%;
    }
}
