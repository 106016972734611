@import '../var';

// Note: using var(--foo) is better than $foo when possible, because it's friendlier to console debugging (e.g. Chrome)

@mixin b8a59b65_d65d_4cc1_9525_3dc18ffc6538() {
    --theme-whitelabel: 0;
    --theme-nowhitelabel: calc(1 - var(--theme-whitelabel));

    @include b09c0293_e031_46f5_8d72_04ef7cac53a1(
        1,
        0,
        '#{$color1HslH}',
        '#{$color1HslS}%',
        '#{$color1HslL}%',
        '#{$color1RgbR}',
        '#{$color1RgbG}%',
        '#{$color1RgbB}%'
    );
    @include b09c0293_e031_46f5_8d72_04ef7cac53a1(
        2,
        1,
        '#{$color2HslH}',
        '#{$color2HslS}%',
        '#{$color2HslL}%',
        '#{$color2RgbR}',
        '#{$color2RgbG}%',
        '#{$color2RgbB}%'
    );
}

@mixin b09c0293_e031_46f5_8d72_04ef7cac53a1($idx, $light, $h, $s, $l, $r, $g, $b) {
    $-color: '--color-#{$idx}';

    #{$-color}-default: 0;

    #{$-color}-light: $light;
    #{$-color}-dark: calc(1 - var(#{$-color}-light));

    #{$-color}-h: #{$h};
    #{$-color}-s: #{$s};
    #{$-color}-l: #{$l};
    #{$-color}-hsl: hsl(var(#{$-color}-h), var(#{$-color}-s), var(#{$-color}-l));

    #{$-color}-r: #{$r};
    #{$-color}-g: #{$g};
    #{$-color}-b: #{$b};
    #{$-color}-rgb: hsl(var(#{$-color}-r), var(#{$-color}-g), var(#{$-color}-b));

    #{$-color}-l-margin-dark: var(#{$-color}-l);
    #{$-color}-l-margin-light: calc(100% - var(#{$-color}-l));
}

:root {
    @include b8a59b65_d65d_4cc1_9525_3dc18ffc6538();
}
