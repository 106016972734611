@import '~nprogress/nprogress.css';

#nprogress {
    .bar {
        background: var(--color-1-hsl);
    }
    .peg {
        box-shadow: 0 0 10px var(--color-1-hsl), 0 0 5px var(--color-1-hsl);
    }
}
