.mockingStrip {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    z-index: $zIndexImpersonatorStrip;
    .-items {
        @include flex(center, center, column);
        padding: 0.5rem;
        position: absolute;
        top: 80%;
        right: 0;
        border-radius: $borderRadiusMd 0 0 $borderRadiusMd;
        background: rgba(0, 0, 255, 0.3);
        transform: translate(0, -50%);
        &.\/hidden {
            transform: translate(100%, -50%);
        }
        .-item {
            @include flex();
            margin: 0.5rem;
            width: 3rem;
            height: 3rem;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 500rem;
            cursor: pointer;
            svg {
                fill: white;
                > * {
                    fill: white;
                }
            }
        }
    }
    .-flag {
        @include flex();
        position: absolute;
        top: 80%;
        right: 0;
        width: 2rem;
        height: 4rem;
        background: rgba(0, 0, 255, 0.3);
        border-radius: $borderRadiusMd 0 0 $borderRadiusMd;
        transform: translate(0, -50%);
        svg {
            width: 3rem;
            height: 1.5rem;
            opacity: 0;
            color: white;
        }
        &.\/hidden {
            transform: translate(100%, -50%);
        }
        &.\/mocking {
            width: 4rem;
            svg {
                opacity: 1;
            }
        }
    }
}
