.bo\:listItemPage {
    @include flex(center, center, column);
    padding: 1rem 1rem 3.5rem 1rem;
    .or {
        width: 60%;
        margin-top: 1.5rem;
        color: lightslategray;
    }
    > .-submit {
        z-index: 99999;
        font-size: 115%;
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }
    .-back {
        @include discreetLink();
        margin-bottom: 1rem;

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .-header {
        @include flex(center, center, column);
        width: 100%;
        text-align: center;
        h1 {
            margin-top: 1rem;
            font-size: 130%;
            font-weight: $fontWeightXl;
        }
        .-shell {
            &.\/fixed {
                @include flex(space-between, center, row);
                @include shadow(0, 0);
                font-size: 80%;
                z-index: $zIndexHeader - 10;
                position: fixed;
                top: $headerHeight;
                left: 0;
                width: 100%;
                padding: 0.5rem 1.3rem;
                background-color: white;
                &.\/hidden {
                    transform: translateY(-120%);
                }
            }
        }
    }
    .-generalForm {
        @include flex(center, center, column wrap);
        border-radius: $borderRadiusMd;
        width: 100%;
        label {
            width: 100%;
        }
        .-submit {
            margin-top: 1rem;
        }
        .-uploader {
            margin: 1rem;
        }
        .-inputs {
            @include flex(center, center, column);
            width: 100%;
            padding: 1rem;
            position: relative;
            max-width: 50rem;
        }
    }
}
