.bo\:listItemPage.\/library {
    .-generalForm {
        .-publicName,
        .-privateName,
        .-slug,
        .-public,
        .-templatesType,
        .-completionScenario,
        .-description {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }

    .-templates {
        @include flex(center, flex-start, row wrap);
        padding: 0.5rem 1rem 0 1rem;
        width: 100%;

        > .-template {
            @include flex(center, flex-start, row wrap);
            align-content: baseline;
            background-color: white;
            position: relative;
            width: 13rem;
            min-height: 10rem;
            padding: 1rem;
            margin: 0.7rem;
            border-radius: $borderRadiusMd;
            border-width: 0.2rem;
            border-style: solid;

            &:hover,
            &:focus {
                @include shadow();

                .-buttons {
                    > * {
                        @include flex();
                        visibility: visible;
                        width: 1.7rem;
                        height: 1.7rem;

                        svg {
                            width: 1.1rem !important;

                            * {
                                display: initial;
                            }
                        }
                    }

                    .-remove {
                        border-color: $colorRedIndianHex;
                    }
                }
            }

            &.\/draft {
                border-color: lightgrey;
            }

            &.\/valid {
                border-color: limegreen;
            }

            &.\/almostValid {
                border-color: orange;
            }

            .-thumbnail {
                width: 100%;
                height: 8rem;
                margin-bottom: 0.6rem;
                border-radius: $borderRadiusMd;
                background-position: center;
                background-size: cover;
            }

            .-buttons {
                @include flex(flex-start, flex-start, column);
                position: absolute;
                top: 0.7rem;
                left: calc(100% - 0.8rem);

                > * {
                    margin-bottom: 0.6rem;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    cursor: pointer;
                    border-color: transparent;
                    border-radius: 10rem;
                    border-style: solid;
                    border-width: 0.08rem;
                    background-color: white;

                    > .-shell {
                        @include flex();
                    }

                    svg {
                        width: 0 !important;

                        * {
                            display: none;
                        }
                    }
                }

                .-remove {
                    color: $colorRedIndianHex;
                }
            }

            .-name {
                max-width: 80%;
                word-break: break-word;
                text-align: center;
                font-weight: $fontWeightXl;
                margin-bottom: 0.4rem;

                &.\/none {
                    opacity: 0.6;
                }
            }

            .-from {
                width: 100%;
                text-align: center;
                font-size: 80%;
                opacity: 0.6;
                margin-bottom: 0.4rem;
            }

            .-name,
            .-special,
            .-workspace,
            .-organization {
                width: 100%;
                margin-bottom: 0.4rem;
                text-align: center;

                > div {
                    width: 100%;

                    &:first-of-type {
                        font-size: 80%;
                        opacity: 0.6;
                    }
                }
            }
        }

        .-addTemplateForm {
            @include flex(center, flex-start, row wrap);
            position: relative;
            border-radius: $borderRadiusMd;
            width: 17rem;
            padding: 0 1rem;
            margin: 0.7rem;

            .-from {
                width: 100%;
                text-align: center;
                font-size: 80%;
                opacity: 0.6;
                margin-bottom: 0.4rem;
            }

            .-name,
            .-workspace,
            .-organization,
            .-template {
                width: 100%;
                margin-bottom: 0.3rem;
            }

            .-validate {
                margin-top: 0.6rem;
                text-decoration: underline;
                cursor: pointer;
                color: var(--color-1-hsl);
                user-select: none;
            }
        }
    }
}
