.input {
    display: inline-block;
    .-fileHelper {
        label {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        &.\/warning {
            color: darkorange;
        }
        &.\/error {
            color: $colorRedIndianHex;
        }
    }
    .-shell {
        @include flex(center, stretch, row);
        .input-group {
            &-prepend {
                span {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &-append {
                span {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
    .-input {
        width: 100% !important;
        &.\/editor {
            height: 30rem !important;
            transition: all 0s !important;
            * {
                transition: all 0s !important;
            }
        }
        &.\/fileHelped {
            border-top-width: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        &.\/warning {
            border-color: darkorange;
        }
        &.\/error {
            border-color: red;
        }
        &.\/prepended {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.\/appended {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
