.bo\:filters {
    @include flex();
    width: 100%;
    margin: 0 0.5rem 1rem;

    .-form {
        @include flex(center, flex-end, row wrap);
        background-color: white;
        border-radius: $borderRadiusMd;
        padding: 0.5rem;

        .-custom {
            margin-left: 0.5rem;
            min-width: 12rem;
        }

        .custom-checkbox {
            &.-potentialSponsor,
            &.-signed {
                margin-bottom: 1rem;
            }
        }

        > * {
            margin: 0.5rem;

            &:not(button),
            .datePicker {
                width: auto;
                min-width: 16rem;
            }
        }
    }
}
