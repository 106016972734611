@import '../../base';
@import 'bridge/index';
@import 'user/index';

.html[class*='/routed/connect'] {
    @include flex();
    background: #eeeeee;

    padding: 0;

    background-size: 200% 200%;
    background-position: 50% 0;

    transition: background-position 1s !important;

    &.\/entered {
        @media (min-width: $sm + 1) {
            background-position: 0 50%;
        }
    }

    .root {
        > .-core {
            position: relative;

            @media (max-width: $sm) {
                bottom: 0;
            }
        }
    }

    .header {
        top: $loaderHeight;
    }

    .flashBox {
        @media (min-width: $sm + 1) {
            top: 0;
        }
    }

    .loader {
        &:before {
            background-color: white;
        }
    }
}

.register {
    &\:logo {
        margin: 0 auto;
        height: 2.2rem;
    }
}

.connect {
    &\:logo {
        display: block;
        position: relative;
        z-index: 1;
        width: 18.75rem;
        margin: 0 auto;

        &.\/custom {
            @include flex();
            position: absolute;
            top: 0.6rem;
            left: 50%;
            height: 2.2rem;
            transform: translateX(-50%);
        }

        > div {
            z-index: 999;
            margin: -30px auto !important;
            @media (max-width: $sm) {
                margin: 0 auto -60px auto !important;
                z-index: -1;
            }
        }
    }
}

.html[class*='connect/register'] {
    height: 100vh;
    background-color: white;

    .root > .-core {
        padding: 0;
    }
}
