@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
    &-popper {
        z-index: 2;
        transition: all 0s !important;
        * {
            transition: all 0s !important;
        }
    }
    &__close-icon:after {
        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
    }
    &__navigation {
        outline: none !important;
        &:hover,
        &:focus {
            outline: none !important;
        }
    }
    &__day {
        &--keyboard {
            &-selected {
                background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                &:hover,
                &:focus {
                    background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                }
            }
        }
    }
    &__time {
        &-list {
            @include scrollbar(0);
            &-item {
                &--selected {
                    background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                }
            }
        }
    }
    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
        &:hover,
        &:focus {
            background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
        }
    }
    .react-datepicker__time-container .react-datepicker__time {
        overflow: hidden;
    }
    &-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            width: 100%;
        }
        input {
            width: 100%;
            display: block;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            outline: none;
            &:hover,
            &:focus {
                outline: none;
            }
            &:focus {
                border-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                box-shadow: 0 0 0 0.2rem ac7fc58d_20be_45c1_8b1d_554ad0c3eeef();
            }
        }
    }
}
