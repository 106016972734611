@function ebeed49d_10cb_4593_a48a_dab771353e78() {
    @return color(1, $l: calc(var(--color-1-dark) * #{colorL(1, 100%)} + var(--color-1-light) * #{colorL(1, 20%)}));
}

@mixin ab2e2ba1_a669_4bd9_95d7_715ebbd636f1() {
    @include discreetLink(ebeed49d_10cb_4593_a48a_dab771353e78());
    padding: 0.375rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0s;
}
