@font-face {
    font-family: 'Credit Card';
    src: url('/font/CreditCard/CreditCard.ttf');
}

@keyframes bbd697f6_48fe_4fee_9296_e1230060400a {
    from {
        transform: rotateZ(0);
        opacity: 1;
    }
    to {
        transform: rotateZ(360deg);
        opacity: 1;
    }
}

.organization\:billingPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();

    .-creditsBar {
        margin-bottom: 1.2rem;

        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .-sections {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    > .or {
        width: 50%;
        margin-top: 1.5rem;
        color: lightslategray;
        @media (max-width: $sm) {
            width: 100%;
            margin-top: 0.6rem;
        }
    }

    .-noteOnNextInvoice {
        text-align: center;
        margin-top: 0.8rem;
        padding: 0.8rem 1.2rem;
        background-color: $colorBlueNoteBackgroundHex;
        color: $colorBlueNoteTextHex;
        border-radius: $borderRadiusLg;
        font-size: 90%;

        strong {
            color: $colorBlueNoteStrongTextHex;
        }
    }

    .-backHome {
        @include discreetLink();

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .-generalForm {
        @include shadow($span: 5rem, $color: #e1e1e15e);
        @include flex(center, flex-start, row wrap);
        background-color: white;
        border-radius: $borderRadiusMd;
        padding: 1rem 1rem 4rem;
        margin: 1rem 2.5rem;
        width: 100%;
        max-width: 35rem;
        position: relative;

        .-name,
        .-company,
        .-location,
        .-taxNumber,
        .-sepaLabel {
            width: 100%;
            margin: 0 0.3rem 0.6rem;
        }

        .-firstname,
        .-lastname,
        .-email,
        .-phone {
            width: calc(50% - 2 * 0.3rem);
            margin: 0 0.3rem 0.6rem;

            @media (max-width: $sm) {
                width: 100%;
            }
        }

        .-flag {
            width: 1.2rem;
        }

        .-warning {
            @include flex(flex-end);
            width: 100%;
            margin: 0.3rem;
            font-size: 80%;
            font-style: italic;
            color: grey;
            text-align: justify;
            text-align-last: end;

            > span {
                width: 60%;
            }
        }

        .-submit {
            margin-top: 1rem;
            position: absolute;
            bottom: 1.3rem;
            right: 1.3rem;
            @media (max-width: $sm) {
                position: initial;
                margin-top: 0;
                bottom: 0;
                right: 0;
            }
            @media (max-width: $xs) {
                width: 100%;
            }
        }

        @media (max-width: $sm) {
            align-items: start;
            flex-flow: column;
            padding: 1rem 1rem 2rem;
            margin: 0.4rem 0;
        }
    }

    .-chatbot {
        cursor: pointer;
        color: (var(--color-1-hsl));
    }

    .-plan {
        @mixin dd263376_6baa_41ec_b7a2_af0639b018fb($subType, $position) {
            &.\/#{$subType} {
                thead {
                    th:nth-of-type(#{$position}) {
                        box-shadow: 0 0.4rem 0 var(--color-1-hsl) inset, 0.4rem 0 0 var(--color-1-hsl) inset,
                            -0.4rem 0 0 var(--color-1-hsl) inset;
                    }
                }

                tbody {
                    td:nth-of-type(#{$position}) {
                        box-shadow: 0.4rem 0 0 var(--color-1-hsl) inset, -0.4rem 0 0 var(--color-1-hsl) inset;
                    }

                    tr:nth-last-of-type(2) {
                        td:nth-of-type(#{$position}) {
                            box-shadow: 0 -0.4rem 0 var(--color-1-hsl) inset, 0.4rem 0 0 var(--color-1-hsl) inset,
                                -0.4rem 0 0 var(--color-1-hsl) inset;
                        }
                    }

                    tr:last-of-type {
                        td:nth-of-type(#{$position}) {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        @include dd263376_6baa_41ec_b7a2_af0639b018fb('free', 1);
        @include dd263376_6baa_41ec_b7a2_af0639b018fb('startup', 2);
        @include dd263376_6baa_41ec_b7a2_af0639b018fb('company', 3);
        @include dd263376_6baa_41ec_b7a2_af0639b018fb('agency', 4);
        @include dd263376_6baa_41ec_b7a2_af0639b018fb('custom', 5);

        table {
            margin: 1.4rem 0;
            width: 68rem;
            max-width: 100%;

            td,
            th {
                padding: 0.4rem 1rem;
                position: relative;
            }

            thead {
                border-color: lightgrey;
                border-style: solid;
                border-width: 0 0 1px;
                background: linear-gradient(45deg, $colorGreySlateHex 0%, $colorGreySpaceHex 100%);
                color: white;

                td {
                    @include flex(center, center, row wrap);
                    position: relative;
                    top: 1.2rem;
                }

                th {
                    text-align: center;
                    padding: 1rem 2.8rem 2.4rem;

                    > span {
                        display: inline-block;
                        width: 100%;
                        text-align: center;

                        &:first-of-type {
                            color: lightgrey;
                            font-size: 95%;
                        }

                        &:last-of-type {
                            position: absolute;
                            bottom: 0.4rem;
                            left: 50%;
                            transform: translateX(-50%);

                            > span {
                                color: lightgrey;
                                font-size: 75%;
                            }
                        }
                    }
                }

                svg {
                    width: 4rem;
                    margin: 0.4rem;
                }
            }

            tbody {
                tr {
                    &:nth-child(odd):not(:last-of-type) {
                        background-color: white;
                    }

                    &:last-of-type {
                        td:not(:first-of-type) {
                            padding: 1.4rem 1rem;
                            position: relative;

                            .-now {
                                @include flex(center, center, row);
                                position: absolute !important;
                                bottom: -0.4rem;
                                width: calc(100% - 2 * 1rem);

                                label {
                                    @include flex(center, center, row);
                                }
                            }

                            button {
                                transition: all 0s;
                                width: 9rem;
                                background: var(--color-1-hsl);

                                &.\/scheduled {
                                    background: coral;
                                    position: relative;

                                    .-periodEnd {
                                        font-size: 75%;
                                        color: $colorRedIndianHex;
                                        position: absolute;
                                        bottom: -1.2rem;
                                        right: 0;
                                        font-style: italic;
                                    }

                                    .-doNow {
                                        cursor: pointer;
                                        font-size: 75%;
                                        color: var(--color-1-hsl);
                                        position: absolute;
                                        bottom: -2.1rem;
                                        text-decoration: underline;
                                        right: 0;
                                        font-style: italic;
                                    }
                                }

                                &.\/current {
                                    font-style: italic;
                                }

                                &.\/current {
                                    background: transparent;
                                    font-weight: bold;
                                    cursor: initial;

                                    &:hover,
                                    &:focus {
                                        background: transparent;

                                        .button .-shell {
                                            color: var(--color-1-hsl);
                                            background: transparent;
                                        }
                                    }

                                    .button .-shell {
                                        color: var(--color-1-hsl);
                                        background: transparent;
                                    }
                                }

                                * {
                                    transition: all 0s;
                                }
                            }
                        }
                    }

                    th {
                        font-size: 85%;

                        > span {
                            margin-right: 0.4rem;
                        }
                    }

                    td {
                        text-align: center;
                    }
                }
            }
        }

        .-yes,
        .-no {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-radius: 3rem;
        }

        .-yes {
            background: var(--color-1-hsl);
        }

        .-no {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .-frequency {
            display: block;
            padding: 0.2rem 0.8rem;
            background-color: $colorGreySpaceHex;
            position: relative;
            cursor: pointer;
            user-select: none;

            &:hover,
            &:focus {
                background-color: slategrey;
            }

            &.\/monthly {
                border-radius: 10rem 0 0 10rem;
            }

            &.\/yearly {
                border-radius: 0 10rem 10rem 0;
            }

            &.\/thirdlyInitiated {
                cursor: initial;
                color: white;
                background: var(--color-1-hsl);
            }

            .-promo {
                text-align: center;
                font-size: 70%;
                background-color: $colorRedIndianHex;
                padding: 0 0.2rem;
                border-radius: 2rem;
                position: absolute;
                top: -0.4rem;
                left: calc(100% - 0.8rem);
                min-width: 2.4rem;
            }
        }

        .-preTaxNote {
            font-size: 70%;
            font-style: italic;
            position: absolute;
            bottom: -1rem;
            left: 1rem;
        }
    }

    .-credits {
        @include flex(center, center, row wrap);
        padding: 1.2rem 0 0.4rem;

        strong {
            color: $colorGreenLeafRgb;
        }

        .-currentState {
            width: 100%;
            text-align: center;
        }

        .-buySome {
            background: var(--color-1-hsl);
        }

        .-buySome,
        .-showTransactionsHistory {
            margin: 1rem 0.4rem 0;
        }

        .-transactionsHistory {
            @include flex();
            width: 100%;
            margin-top: 1.6rem;

            table {
                td,
                th {
                    padding: 0.4rem 1rem;
                }

                thead {
                    border-color: lightgrey;
                    border-style: solid;
                    border-width: 0 0 1px;
                    background-color: slategrey;
                    color: white;
                }

                tbody {
                    tr {
                        &:nth-child(odd) {
                            background-color: white;
                        }

                        td {
                            &:nth-of-type(4) {
                                font-weight: bold;

                                &.\/positive {
                                    color: limegreen;
                                }

                                &.\/negative {
                                    color: red;
                                }
                            }

                            &:nth-of-type(5) {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .-loadMore {
                margin-top: 1.4rem;
                display: inline-block;
                width: 100%;
                text-align: center;
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                text-decoration: underline;
                cursor: pointer;
            }

            .animation {
                display: inline-block;
                width: 100%;
                text-align: center;
                margin-top: 1.4rem;
            }
        }
    }

    .-sponsorship {
        @include shadow($span: 5rem, $color: #e1e1e15e);
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 45rem;
        min-height: 10rem;
        border-radius: $borderRadiusMd;
        padding: 1rem;
        background-color: white;
        position: relative;

        .-sponsorshipTitle {
            text-align: center;
            font-size: 1.4em;
            font-weight: 600;
            color: hsla(var(--color-1-h), var(--color-1-s), 40%, 1);
        }

        .-sponsorshipDescription {
            text-align: center;
            color: hsla(var(--color-1-h), var(--color-1-s), 30%, 1);
        }

        .-sponsorshipContent {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .-sponsorSteps {
                display: flex;
                text-align: center;
                flex-wrap: wrap;
                @media (max-width: $sm) {
                    flex-wrap: nowrap;
                    flex-direction: column;
                }

                .-step {
                    flex: 50%;

                    @media (max-width: $sm) {
                        flex: 100%;
                    }

                    .-stepNumber {
                        background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614();
                        padding: 1rem;
                        border-radius: 50%;
                        margin: 1rem auto;
                        height: 3rem;
                        width: 3rem;
                        font-weight: bold;
                        font-size: 1.4em;
                        line-height: 1rem;
                        color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
                    }

                    .-stepTitle {
                        font-weight: 600;
                    }
                }
            }

            .-sponsorCodeContent {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                width: 100%;

                @media (max-width: $sm) {
                    flex-direction: column;
                    gap: 1rem;
                }

                .-sponsorCode {
                    flex: 1;

                    @media (max-width: $sm) {
                        width: 100%;
                    }
                }

                .-sponsorCode .form-control:disabled,
                .form-control[readonly] {
                    margin-right: 1rem;
                    background-color: color(1, $l: 90%, $a: 0.5);
                    @media (max-width: $sm) {
                        margin-right: 0;
                    }
                }

                .-sponsorshipButton {
                    height: calc(1.5em + 0.75rem + 2px);

                    @media (max-width: $sm) {
                        width: 100%;
                    }
                }
            }

            .-sponsorTotalCount {
                font-size: 80%;
                font-style: italic;
                opacity: 0.7;
            }
        }
    }

    .-paymentMethods {
        @include flex(center, center, row wrap);

        > .animation {
            margin: 2rem 0;
        }

        .animation {
            display: inline-block;
        }

        .-add {
            @include flex(center, center, row);
            width: 100%;
            margin-bottom: 1.3rem;

            @media (max-width: $sm) {
                flex-direction: column;
                gap: 1rem;

                .-validate {
                    border-radius: 0.25rem !important;
                }
            }

            .-cardElement,
            .-sepaElement {
                padding: 12px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem 0 0 0.25rem;
                max-height: 44px;
                width: 20rem;
                background: white;
                box-sizing: border-box;

                &Label {
                    @include flex(flex-start, center, row);

                    .form-check-label {
                        align-items: center;
                    }
                }
            }

            .-validate {
                @include flex();
                border-radius: 0 0.25rem 0.25rem 0;
                background-color: var(--color-1-hsl);
                color: ebeed49d_10cb_4593_a48a_dab771353e78();
                height: 44px;
                max-height: 44px;
                cursor: pointer;
                user-select: none;
            }
        }

        .-none {
            display: inline-block;
            margin: 2rem 0;
        }

        .-sepaWarning {
            @include flex(flex-end);
            width: 28rem;
            margin: 0.3rem;
            font-size: 80%;
            font-style: italic;
            color: grey;
            text-align: justify;
            text-align-last: end;

            @media (max-width: $sm) {
                width: 80%;
            }

            > span {
                width: 60%;
            }
        }

        .-items {
            @include flex(center, flex-start, row wrap);
            padding: 1rem;

            .-sepaItem {
                min-height: 6rem !important;
                max-width: 24rem !important;

                &.-shaded {
                    filter: grayscale(100%);
                    webkit-filter: grayscale(100%);
                }

                .-itemBg {
                    background: url('/img/svg/bg_card_default.svg');
                }
            }

            .-cardItem,
            .-sepaItem {
                .-itemBg {
                    position: absolute;
                    background-size: cover;
                    left: -5px;
                    right: -5px;
                    top: -5px;
                    bottom: -5px;
                }

                @include flex(space-between, flex-start, row wrap);
                position: relative;
                box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
                border-radius: $borderRadiusLg;
                overflow: hidden;

                align-items: center;
                color: #ffffff;
                width: 18rem;
                min-height: 10rem;
                padding: 1rem;
                margin: 0.7rem;

                &:hover,
                &:focus {
                    @include shadow();

                    .-setAsDefault,
                    .-remove {
                        background: white;
                        opacity: 1;

                        svg {
                            width: 1.2rem;
                            vertical-align: baseline;
                        }
                    }
                }

                .-default {
                    position: absolute;
                    top: 1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 2;

                    svg {
                        width: 2rem;
                    }
                }

                .-setAsDefault,
                .-remove {
                    position: absolute;
                    right: 0.7rem;
                    cursor: pointer;
                    z-index: 2;
                    border-radius: 100%;
                    aspect-ratio: 1/1;
                    opacity: 0;
                    padding: 0.2rem;

                    svg {
                        width: 0;
                        fill: red;
                    }
                }

                .-remove {
                    top: 0.7rem;
                }

                .-setAsDefault {
                    top: 2.6rem;

                    svg {
                        fill: orange;
                    }
                }

                .-flag,
                .-brand,
                .-bank {
                    height: 1.4rem;
                    width: 1.4rem;
                    margin-right: 0.5rem;
                    position: relative;
                    bottom: 0.02rem;
                }

                .-last4 {
                    font-family: 'Credit Card', sherif;
                    text-align: center;
                    width: 100%;
                    z-index: 1;
                    letter-spacing: 2px;
                    font-size: 1em;
                    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

                    .-hider {
                        display: inline-block;
                        font-weight: $fontWeightXl;
                        margin-right: 0.4rem;
                    }
                }

                .-footer {
                    @include flex(space-around, center, row wrap);
                    width: 100%;
                    z-index: 1;

                    .-name {
                        text-transform: uppercase;
                        font-weight: 600;
                    }

                    .-expiry {
                        @include flex(center, flex-start, column);
                        font-style: normal;

                        :first-child {
                            font-size: 0.45em;
                        }

                        :last-child {
                            font-size: 1em;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .-paymentMethodsOr {
        @media (max-width: $sm) {
            display: none;
        }
    }

    .-invoices {
        width: 100%;
        @include flex(center, center, row wrap);

        .-loader {
            @include flex();
            width: 100%;

            .animation {
                display: inline-block;
                margin: 2rem 0;
            }
        }

        .-none {
            display: inline-block;
            margin: 2rem 0;
        }

        .-items {
            margin: 1.4rem 0;
            width: 40rem;
            max-width: 100%;

            td,
            th {
                padding: 0.4rem 1rem;
            }

            thead {
                border-color: lightgrey;
                border-style: solid;
                border-width: 0 0 1px;
                background-color: slategrey;
                color: white;
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: white;
                    }

                    td {
                        &:first-of-type {
                            &.\/paid {
                                span {
                                    background-color: limegreen;
                                }
                            }

                            &.\/open {
                                span {
                                    background-color: $colorRedIndianHex;
                                }
                            }

                            &.\/retrying {
                                span {
                                    background-color: darkturquoise;
                                }
                            }

                            span {
                                background-color: dimgrey;
                                font-size: 90%;
                                border-radius: $borderRadiusMd;
                                padding: 0.2rem 0.4rem;
                                color: white;
                            }
                        }
                    }
                }

                svg {
                    width: 1.6rem;
                    cursor: pointer;
                    fill: var(--color-1-hsl);

                    &:hover,
                    &:focus {
                        transform: scale(1.1);
                    }

                    &.\/on {
                        animation-name: bbd697f6_48fe_4fee_9296_e1230060400a;
                        animation-iteration-count: infinite;
                        animation-duration: 2s;
                        animation-timing-function: linear;
                    }
                }
            }
        }

        .-more {
            width: 100%;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            color: var(--color-1-hsl);
            user-select: none;
        }
    }

    .-dangerZone {
        padding: 1rem;

        > * {
            margin: 0.5rem;
        }
    }
}
