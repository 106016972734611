@import '_notifications';
@import '_helpCenter';

.html[class*='/routed/backOffice/'] .menu .-item .-inner {
    color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
}

.menu {
    height: $headerHeight;
    box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
    transition: all 0s;

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &[class*='/page/'] {
        .-item {
            &.\/level1 {
                > *:not(.-submenu) {
                    @media (max-width: $sm) {
                        display: none;
                    }
                }

                @media (max-width: $sm) {
                    padding: 0;
                }
            }
        }

        .dropdown {
            &-toggle {
                @media (max-width: $sm) {
                    display: none !important;
                }
            }
        }

        .nav {
            &bar {
                &-nav {
                    > * {
                        @media (max-width: $sm) {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    &.\/home {
        font-size: 110%;
    }

    &.\/open {
        .collapse {
            &:not(.show) {
                display: initial; // bug fix
            }
        }

        .-toggler {
            div {
                &:first-of-type {
                    transform-origin: center;
                    transform: translateY(0.56rem) rotateZ(45deg);
                    width: 1.6rem;
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }

                &:last-of-type {
                    transform-origin: center;
                    transform: translateY(-0.56rem) rotateZ(-45deg);
                    width: 1.6rem;
                }
            }
        }

        @media (max-width: $sm) {
            height: auto;
            padding-top: $headerHeight;
        }
    }

    .nav {
        &bar {
            &-nav {
                @include ae4ed70f_b09b_42d8_aec5_396e40aa14b1();
                @include flex(flex-end, center, row);

                flex-grow: 1;
                position: relative;

                @media (max-width: $sm) {
                    display: initial;
                }
            }

            &-toggler {
                &:focus {
                    outline: none;
                }
            }

            &-collapse {
                transition: all 0s !important;
            }
        }

        &-link {
            @media (max-width: $sm) {
                padding: 0;
                width: 100%;
            }
        }

        &-item {
            @media (max-width: $sm) {
                @include flex(flex-start);
                text-align: left;
                width: 100%;
            }
        }
    }

    .dropdown {
        &-menu {
            @media (max-width: $sm) {
                margin: 0 0 0.4rem;
                background: rgba(0, 0, 0, 0.3);
                box-shadow: none;
                * {
                    color: white;
                }
            }
        }

        &-item {
            &:hover,
            &:focus {
                background-color: transparent;
                outline: none;
            }
        }
    }

    .-back {
        @include fc95b5d7_de7d_40c7_b807_c3a3208cdfcb();
        right: 3rem;

        div {
            &:first-of-type {
                transform-origin: center;
                transform: translate(-100%, 0.76rem) translateX(0.2rem) rotateZ(45deg);
                width: 0.7rem;
            }

            &:last-of-type {
                transform-origin: center;
                transform: translate(-100%, -0.76rem) translateX(0.2rem) rotateZ(-45deg);
                width: 0.7rem;
            }
        }
    }

    .-toggler {
        @include fc95b5d7_de7d_40c7_b807_c3a3208cdfcb();
        right: 1rem;
    }

    .-item {
        margin-left: 0.5rem;
        transition: all 0s;

        * {
            transition: all 0s;
        }

        &.\/videoLibrary,
        &.\/home {
            &.\/single {
                .-caption {
                    border-radius: 500px !important;
                    border: none !important;
                }
            }

            .-tooltip {
                display: none;
            }

            .-inner {
                background: initial !important;
                @media (max-width: 900px) {
                    &:hover {
                        background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614() !important;
                    }
                }
            }

            .nav {
                &-link {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @media (min-width: 900px) {
                margin-left: 0;
                .-inner {
                    padding: 0 !important;

                    .-caption {
                        background-color: color(
                            2,
                            $l: calc(var(--color-2-dark) * (var(--color-2-l) - 20%) + var(--color-2-light) * (var(--color-2-l) - 10%))
                        );
                        display: block;
                        margin: 0;
                        padding: 0.2rem 0.8rem;
                    }

                    svg {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                    }
                }
            }
        }

        &.\/videoLibrary {
            margin-right: 0.65rem;

            .-inner {
                .-caption {
                    border-radius: 0 500px 500px 0;
                    border-style: solid;
                    border-color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
                    border-width: 0 0 0 1px;
                    @media (max-width: $sm) {
                        border: none;
                    }
                }
            }
        }

        &.\/home {
            .-inner {
                .-caption {
                    border-radius: 500px 0 0 500px;
                    @media (max-width: $sm) {
                        border: none;
                    }
                }
            }
        }

        &.\/active {
            .-inner .-shell {
                @media (max-width: $sm) {
                    margin-left: 0.4rem;
                }
            }

            > .-shell > .-inner,
            .-caption,
            > .-inner,
            .-caption {
                //@media (max-width: 900px) {
                //    background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614() !important;
                //}
                &:hover {
                    @media (max-width: 900px) {
                        svg {
                            * {
                                fill: fa06f195_a6c3_4d86_9334_79323bbe5ce3() !important;
                            }
                        }
                    }
                }
            }
        }

        &.\/active,
        &:hover,
        &:focus {
            > .-shell > .-inner,
            .-caption,
            > .-inner,
            .-caption {
                @media (min-width: $sm + 1) {
                    background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614();
                    color: fa06f195_a6c3_4d86_9334_79323bbe5ce3() !important;
                    svg {
                        * {
                            fill: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
                        }
                    }
                    .image {
                        border-color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
                    }
                }
            }
        }

        .-submenu {
            @include ae4ed70f_b09b_42d8_aec5_396e40aa14b1();
            @include flex(center, center, column);
            position: fixed;
            background-color: var(--color-2-hsl);
            bottom: 0;
            left: 0;
            color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            width: $headerHeight;
            height: calc(100vh - #{$headerHeight});
            z-index: -1;

            > * {
                margin: 0.6rem;
                position: relative;
                bottom: calc(#{$headerHeight} / 2);
                @media (max-width: $sm) {
                    margin: 0;
                    bottom: initial;
                    width: 100%;
                }
            }

            @media (max-width: $sm) {
                position: initial;
                top: initial;
                left: initial;
                width: 100%;
                height: auto;
                box-shadow: none;
                background-color: transparent;
            }
        }

        .-inner {
            @include flex(center, center, row);
            color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            position: relative;
            min-width: 2.5rem;
            height: 2.5rem;
            border-radius: 500rem;
            cursor: pointer;

            .betaTag {
                position: absolute;
                bottom: -0.4rem;
                left: 50%;
                transform: translateX(-50%);

                @media (max-width: $sm) {
                    bottom: initial;
                    left: initial;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }

            > a,
            > span,
            .-shell > a,
            .-shell > span {
                @include flex();
                width: 100%;
                height: 100%;
                color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6() !important;
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                @media (max-width: $sm) {
                    justify-content: flex-start;
                }
            }

            &:hover {
                @media (min-width: $sm + 1) {
                    background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614();
                    color: fa06f195_a6c3_4d86_9334_79323bbe5ce3() !important;
                }
            }

            .-shell {
                @include flex(center, center, row);
            }

            .-caption {
                text-align: left;
            }

            .image {
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 500px;
                border-color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
                border-width: 0.13rem;
                border-style: solid;
            }

            svg,
            img,
            .image {
                ~ span {
                    margin-left: 1rem;
                    @media (min-width: $sm + 1) {
                        display: none;
                    }
                }
            }

            @media (max-width: $sm) {
                width: auto;
                height: auto;
                justify-content: flex-start;
                min-width: auto;
            }

            ~ span {
                margin-left: 1rem;
                @media (min-width: $sm + 1) {
                    display: none;
                }
            }
        }

        @media (max-width: $sm) {
            margin-left: 0;
        }
    }

    @media (max-width: $sm) {
        @include flex(space-between, center, row wrap);
    }
}
