@mixin e5a05cb1_2d23_4a00_aa46_1440e5b177a4() {
    [class$='-menu'] {
        [class$='-option'] {
            &:last-child {
                position: sticky;
                bottom: 0;
                background-color: white;
                border-width: 0.05rem 0 0;
                border-style: solid;
                border-color: hsla(0, 0%, 0%, 0.1);
                cursor: pointer;
                font-size: 90%;
                padding: 0;
                box-shadow: 0 2rem 0 2rem white;
                text-align: center;
                span {
                    @include flex();
                    width: 100%;
                    color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                    > * {
                        margin: 0.2rem;
                    }
                }
            }
        }
    }
}

@mixin dd6e8d3d_56eb_437c_8c60_038739b38d08() {
    .-modify {
        display: none;
        color: $colorRedIndianHex;
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);
        cursor: pointer;
        &.alone {
            right: 0.7rem !important;
        }
    }
    .-remove {
        display: none;
        color: $colorRedIndianHex;
        position: absolute;
        top: 50%;
        right: 0.7rem;
        transform: translateY(-50%);
        cursor: pointer;
    }
    [class$='-menu'] {
        padding: 0;
        position: absolute;
        background-color: white;
        > * {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        [class$='-option'] {
            @include flex(space-between, center, row);
            position: relative;
            padding-right: 2rem;
            .-remove {
                display: initial;
            }
        }
    }
}

@mixin cbf5e87d_4e22_40bb_be00_c3baf6923eb5() {
    .-info {
        @include flex(flex-start, flex-start, row);
        flex-grow: 1;
        font-size: 90%;
        .-style {
            font-size: 65%;
            margin-left: 0.1rem;
            opacity: 0.7;
            position: relative;
            bottom: 0.4rem;
        }
    }
}
