$headerHeight: 3.5rem;
$loaderHeight: 0.27rem;

$borderRadiusXl: 2rem;
$borderRadiusLg: 1rem;
$borderRadiusMd: 0.4rem;

$borderWidthMd: 0.15rem;
$borderWidthSm: 0.08rem;

/**
 * Desktop/Mobile:
 *
 * Corresponds to * in : [..xs..*[..sm..*[
 * Example for landscape iPhone : @media(max-width: $sm) {}
 */
$lg: 1199px; // 1200 - 1
$md: 991px; // 992 - 1
$sm: 767px; // 768 - 1
$xs: 543px; // 543 - 1

/**
 * We could have used the ens, but IDEs need explicit sets for us to preview colors
 * Colors
 */
$colorGreySpaceHex: #1d2528;
$colorGreySlateHex: #3e484f;
$colorGreySlateHslH: 205;
$colorGreySlateHslS: 12;
$colorGreySlateHslL: 28;
$colorGreyCottonHex: #e1e1e1;
$colorGreyCloudHex: #f9f9f9;
$colorGreySnowHex: #efefef;

$colorGreenLeafRgb: rgb(4, 160, 93);
$colorGreenGrassHex: #46be8a;
$colorGreenGrassHslH: 154;
$colorGreenGrassHslS: 48;
$colorGreenGrassHslL: 51;
$colorGreenGrassRgb: rgb(70, 190, 138);
$colorGreenLimeHex: #5cd29d;
$colorGreenAppleHex: #92d275;
$colorGreenNoteBackgroundHex: #d0fbd2;
$colorGreenNoteTextHex: #188c1d;
$colorGreenNoteStrongTextHex: #044407;

$colorBlueNoteBackgroundHex: #d6f0f0;
$colorBlueNoteTextHex: #11709c;
$colorBlueNoteStrongTextHex: #043b54;

$colorRedIndianHex: #da5653;

$colorYellowStarHex: #ffcc00;

$colorOrangeNoteBackgroundHex: #fbe6d0;
$colorOrangeNoteTextHex: #cb6800;
$colorOrangeNoteStrongTextHex: #874500;

$color1Hex: #3294a5;
$color1HslH: 189;
$color1HslS: 53;
$color1HslL: 42;
$color1Hsl: unquote('hsl(#{$color1HslH}, unquote(#{$color1HslS}%), unquote(#{$color1HslL}%))');
$color1RgbR: 50;
$color1RgbG: 148;
$color1RgbB: 165;
$color1Rgb: unquote('rgb(#{$color1RgbR}, #{$color1RgbG}, #{$color1RgbB})');

$color2Hex: #eeeeee;
$color2HslH: 0;
$color2HslS: 0;
$color2HslL: 93;
$color2Hsl: unquote('hsl(#{$color2HslH}, unquote(#{$color2HslS}%), unquote(#{$color2HslL}%))');
$color2RgbR: 238;
$color2RgbG: 238;
$color2RgbB: 238;
$color2Rgb: unquote('rgb(#{$color2RgbR}, #{$color2RgbG}, #{$color2RgbB})');

/**
 * Z-Index: related/child for each gravitate around. Ex: child element of menu could have z-index: 300, decisions are local.
 */
$maxZIndex: 9999999;
$zIndexImpersonatorStrip: 900;
$zIndexLoader: 800;
$zIndexFlashbox: 700;
$zIndexTooltip: 700;
$zIndexModal: 600;
$zIndexHeader: 500;
$zIndexFooter: 100;
$zIndexChatbot: 500;

/**
 * Font
 */
$font: 'Muli';
$fontSize: 1rem;

$fontWeightXl: 900;
$fontWeightLg: 700;
$fontWeightMd: 500;
$fontWeightSm: 300;
$fontWeightXs: 100;
