.uploader {
    &.\/warning {
        > .-feedback {
            color: darkorange;
        }
        .uploader {
            &-zone {
                border: 1px solid darkorange;
            }
        }
    }
    &.\/error {
        > .-feedback {
            color: #dc3545;
        }
        .uploader {
            &-zone {
                border: 1px solid red;
            }
        }
    }
    &.\/valid {
        > .-feedback {
            color: limegreen;
        }
    }
    &.\/searchable {
        .uploader {
            &-zone {
                //border-bottom-left-radius: 0;
                //border-bottom-right-radius: 0;
            }
        }
    }
    &-zone {
        border-radius: $borderRadiusMd;
        &-fog {
            &-text {
                font-size: 80%;
            }
            &-caption {
                right: 0 !important;
                left: initial !important;
                transform: none !important;
                font-style: italic;
                color: white !important;
                font-size: 60% !important;
                border-top-right-radius: 0 !important;
                a {
                    color: white;
                }
            }
        }
    }
    label {
        width: 100%;
        text-align: left;
        margin-bottom: 0.3rem;
    }
    > .-feedback {
        width: 100%;
        text-align: left;
        font-size: 80%;
        margin-top: 0.3rem;
    }
    &-url {
        @include flex(center, stretch, row);
        input {
            width: 0.1rem;
            flex-grow: 1;
        }
    }
    .-search {
        @include flex();
        width: 100%;
        text-align: center !important;
        font-size: 85%;
        padding: 0.3rem;
        margin-top: 0.3rem;
        color: var(--color-1-hsl);
        background-color: unset;
        border: 1px solid var(--color-1-hsl);
        cursor: pointer;
        svg {
            margin-right: 0.3rem;
        }
        &:hover {
            transition: all 0.1s ease-in-out;
            background-color: var(--color-1-hsl);
            color: white;
        }
    }
}
