.root {
    > .-core {
        padding: $headerHeight 0 0;
        &.\/mode {
            &\/demo {
                padding: 0;
                .menu {
                    display: none;
                }
                .loader {
                    &:before {
                        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                    }
                }
                .video\:studioPage {
                    .-snippet.\/topSticky,
                    .-chapter .-thumbnailShell,
                    .video\:studioDynamicZone .-available > div {
                        top: 2rem;
                    }
                }
            }
        }
    }
}
