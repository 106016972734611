@import 'studio/mixin';
@import 'studio_composition/mixin';

@keyframes fd59274a_35cf_4e06_9f1d_73fbbcaddb5b {
    from {
        transform: rotateZ(0);
        opacity: 1;
    }
    to {
        transform: rotateZ(360deg);
        opacity: 1;
    }
}
