@function f54756f4_3b93_4bf8_a2f6_cc4b875928a7() {
    @return color(1, $l: calc(var(--color-2-dark) * 100% + var(--color-2-light) * 35%));
}

.error {
    @include flex();

    background: ffd84f41_079b_471a_8d9d_9da4ba58978f(45deg);
    color: white;
    align-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999999999;
    overflow: hidden;
    padding: 0 0 3.8rem 0 !important;
    text-align: center;

    .-statusCode {
        width: 100%;
        font-size: 700%;
    }
    .-message {
        width: 100%;
        font-size: 150%;
    }
    .-redirect {
        width: 100%;
    }
    .-exit {
        text-decoration: underline;
        color: white;
    }
}
