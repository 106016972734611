.menu {
    .-item {
        &.\/helpCenter {
            padding: 0;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
            }

            .-inner {
                position: relative;

                &:hover,
                &:focus,
                &:active {
                    @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                    //background: $color1Hex !important;
                    //color: white;
                }

                .-caption {
                    @media (min-width: $sm + 1) {
                        display: none;
                    }
                }
            }

            ~ .-menu {
                top: calc(100% + 0.8rem);
                right: -0.3rem;
                width: 18rem;
                padding: 0;
                max-height: 18rem;

                .-item {
                    height: 2.6rem;
                    margin-left: 0;

                    &:hover,
                    &:focus,
                    &:active {
                        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                        // background: $color1Hex !important;
                        // color: white;
                        transition: all 0.1s ease-in-out !important;
                        a {
                            color: white;
                        }
                    }

                    .-icon {
                        width: 1rem;
                        margin-right: 1rem;
                    }
                }

                @media (max-width: $sm) {
                    width: 100%;
                    height: 20rem;
                    box-shadow: none;
                    &::-webkit-scrollbar-track {
                        background-color: rgba(0, 0, 0, 0.9);
                    }
                }
            }
        }
    }
}
