.ace {
    &_editor {
        transition: all 0s !important;
        scroll-behavior: initial;
        * {
            transition: all 0s !important;
            scroll-behavior: initial;
        }
    }
    &_layer {
        z-index: 0 !important; // incentive: below react-select menu
    }
}
