.modal {
    @include flex(center, flex-start, row);
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    transition: all 0s !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $zIndexModal;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 8vh 0;

    &.\/closed {
        @include hHidden();
    }

    &.\/loading {
        .-dialog {
            @include hHidden();
        }
        @media (max-width: $sm) {
            padding: 4rem 1rem !important;
            .animation .-dot {
                background-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
            }
        }
    }

    > .animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .-dialog {
        width: 30rem;
        max-width: none;
        position: relative;
        transition: all 0s !important;

        > .-contentShell {
            @include flex();
            transition: all 0s;
            background: white;
            border-radius: 0 0 $borderRadiusMd $borderRadiusMd;
            padding: 1.4rem;
        }

        > .-header {
            position: relative;

            > .-close {
                position: absolute;
                right: 1rem;
                top: 50%;
                fill: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
                width: 2rem;
                cursor: pointer;
                transform: translateY(-50%);
                transition: all 0s;
            }

            > .-title {
                padding: 1rem 4.2rem;
                font-size: 120%;
                text-align: center;
                width: 100%;
                background: var(--color-2-hsl);
                color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
                border-radius: $borderRadiusMd $borderRadiusMd 0 0;

                @media (max-width: $sm) {
                    position: relative;
                    bottom: initial;
                    left: initial;
                    padding: 1rem 4rem 1rem 1rem;
                    border-radius: 0;
                    text-align: left;
                }
            }
        }

        @media (max-width: $sm) {
            margin: 0;
            width: 100% !important;
            min-height: 100vh;
        }
    }

    @media (max-width: $sm) {
        position: initial;
        padding: 0;
        overflow-x: hidden;
        background-color: transparent;
    }
}
