.video\:snippetWaiter {
    @include flex(center, center, column wrap);
    @include shadow();
    background-color: white;
    width: 20rem;
    height: 15rem;
    border-radius: $borderRadiusLg;
    margin: 0.7rem;
    cursor: pointer;
    overflow: hidden;
    .-preview {
        @include loading2();
        flex-grow: 1;
        width: 100%;
    }
    .-footer {
        padding: 0.6rem 4rem 0.6rem 1rem;
        text-align: left;
        width: 100%;
        .-name {
            @include loading2();
            height: 1.3rem;
            border-radius: $borderRadiusMd;
        }
    }
}
