.video\:studioCompo {
    position: relative;
    background-color: #ececec;
    border-radius: $borderRadiusMd;
    padding: 0.1rem 0.2rem 0.22rem;

    &.\/private {
        display: none;
    }

    &.\/irradiating {
        content-visibility: initial !important;

        &:after {
            box-shadow: 0 0 0 100vw var(--color-1-hsl);
            opacity: 0;
            transition: box-shadow 1s, opacity 1s;
        }
    }

    &.\/mode {
        &\/light {
            .-thumbnailShell {
                top: -2rem !important;
            }
        }
    }

    &.\/global {
        .-thumbnailShell {
            display: none;
        }
    }

    &:after {
        z-index: -1;
        display: inline-block;
        content: '';
        box-shadow: none;
        transition: all 0s;
        opacity: 0.8;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
        content-visibility: visible !important; // This will solve bugs like "my select is cut", since "content-visibility: auto" seems to behave like "overflow: hidden"
    }

    &[class*='/width/notInitialized'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row wrap);
                }

                .-inputs {
                    width: 100%;
                    opacity: 0;

                    > * > * > * {
                        width: 1rem;
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 0;
                    opacity: 0;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                }
            }
        }
    }

    &[class*='/width/inf'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 400px - 1.5rem);

                    > * > * > * {
                        width: calc(33% - 1rem);
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 400px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                }
            }
        }
    }

    &[class*='/width/0000010'],
    &[class*='/width/0000011'],
    &[class*='/width/0000012'],
    &[class*='/width/0000013'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 320px - 1.5rem);

                    > * > * > * {
                        width: calc(50% - 1rem);
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 320px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                }
            }
        }
    }

    &[class*='/width/0000009'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 300px - 1.5rem);

                    > * > * > * {
                        width: calc(50% - 1rem);
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 300px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                }
            }
        }
    }

    &[class*='/width/0000007'],
    &[class*='/width/0000008'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 220px - 1.5rem);

                    > * > * > * {
                        width: calc(50% - 1rem);
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 220px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                    border-radius: $borderRadiusMd;
                    background-color: lightgrey;
                }
            }
        }
    }

    &[class*='/width/0000006'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 220px - 1.5rem);

                    > * > * > * {
                        width: 100%;
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 220px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                    border-radius: $borderRadiusMd;
                    background-color: lightgrey;
                }
            }
        }
    }

    &[class*='/width/0000005'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row);
                }

                .-inputs {
                    width: calc(100% - 220px - 1.5rem);

                    > * > * > * {
                        width: 100%;
                    }
                }

                .-thumbnailShell {
                    position: sticky;
                    width: 220px;
                    max-width: 100%;
                    top: calc(#{$headerHeight} + 2rem);
                    border-radius: $borderRadiusMd;
                    background-color: lightgrey;
                }
            }
        }
    }

    &[class*='/width/00000046'],
    &[class*='/width/00000047'],
    &[class*='/width/00000048'],
    &[class*='/width/00000049'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row wrap);
                }

                .-inputs {
                    margin-top: 1.4rem;
                    margin-left: 0;
                    width: 100%;

                    > * > * > * {
                        width: calc(50% - 1rem);
                    }
                }

                .-thumbnailShell {
                    width: 220px;
                    max-width: 100%;
                    border-radius: $borderRadiusMd;
                    background-color: lightgrey;
                    position: relative;
                    top: 0 !important;
                }
            }
        }
    }

    &[class*='/width/zero'] {
        .-chapters {
            .-chapter {
                .-core {
                    @include flex(center, flex-start, row wrap);
                }

                .-inputs {
                    margin-top: 1.4rem;
                    margin-left: 0;
                    width: 100%;

                    > * > * > * {
                        width: 100%;
                    }
                }

                .-thumbnailShell {
                    width: 220px;
                    max-width: 100%;
                    border-radius: $borderRadiusMd;
                    background-color: lightgrey;
                    position: relative;
                    top: 0 !important;
                }
            }
        }
    }

    &.\/mode {
        &\/quicklook {
            .-chapters {
                .-chapter {
                    .-thumbnailShell {
                        position: relative !important;
                        top: 0 !important;
                    }
                }
            }
        }

        &\/config {
            &.\/available {
                margin-bottom: 0 !important;

                &.\/animated {
                    animation-name: headShake;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    transition: border 0s;
                    box-shadow: 0 0 0 0.4rem orange !important;
                    border-color: transparent !important;
                }

                &.\/removed {
                    background-color: rgba(236, 236, 236, 0.2); // #ececec rgba version
                    img {
                        transition: opacity 0.3s;
                        opacity: 0.2;
                    }

                    > .-description {
                        .-description,
                        .-buttons {
                            transition: opacity 0.3s;
                            opacity: 0.2;
                        }
                    }

                    &:hover,
                    &:focus {
                        background-color: rgba(236, 236, 236, 1); // #ececec rgba version
                        img {
                            opacity: 1;
                        }

                        > .-description {
                            .-description,
                            .-buttons {
                                opacity: 1;
                            }
                        }
                    }
                }

                > .-description {
                    padding-right: 0.75rem;

                    .-buttons {
                        @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();

                        .-rename {
                            @include flex();
                            background-color: white;
                            border: 0.1rem solid var(--color-1-hsl);
                            color: var(--color-1-hsl);
                            border-radius: 500rem;
                            width: 1.7rem;
                            height: 1.7rem;

                            > .-shell {
                                @include flex();
                            }
                        }
                    }
                }

                .-chapters .-chapter {
                    .-inputs {
                        label {
                            @include flex(flex-start, flex-end, row);
                            @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                        }
                    }

                    .-thumbnailShell {
                        transition: all 0.3s;
                    }
                }
            }
        }
    }

    &.\/folded,
    &.\/removed {
        box-shadow: none;

        .-chapters {
            height: 0;
            opacity: 0;
            padding: 0 1rem;
            overflow: hidden;
        }
    }

    &.\/unfoldable {
        cursor: not-allowed !important;

        * {
            cursor: not-allowed !important;
        }
    }

    &.\/previewRefreshOn {
        .-inputs {
            background-color: #ececec;
            border-radius: $borderRadiusMd;

            > * {
                visibility: hidden;
            }
        }
    }

    &.\/highlighted {
        border-style: solid;
        border-width: 0.3rem;
        border-color: var(--color-1-hsl);

        .-elected {
            font-size: 55%;
            position: absolute;
            bottom: 0.2rem;
            left: 0.8rem;
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            transform: rotateZ(-90deg);
            transform-origin: left;
        }
    }

    &.\/dragged {
        box-shadow: 0 0 1.5rem 0 rgba(var(--color-1-rgb), 0.3);
    }

    &.\/static {
        margin-bottom: 1rem;
    }

    &.\/removed {
        .-description .-description,
        .-thumbnail {
            opacity: 0.35;
        }
    }

    * {
        transition: all 0s;
    }

    > .-description {
        @include flex(space-between, center, row);
        font-weight: $fontWeightLg;
        padding: 1rem;
        cursor: pointer;
        user-select: none;
        height: 3.4rem;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }

        *:not(input) {
            user-select: none;
        }

        .-thumbnail {
            height: 100%;
            border-radius: $borderRadiusMd;
            margin-right: 0.6rem;
        }

        .-description {
            @include flex(flex-start, center, row);
            flex-grow: 1;
            text-shadow: 0 0 0.5rem white;

            svg {
                margin-right: 0.7rem;
            }

            .input {
                flex-grow: 1;
            }

            .-move {
                cursor: grab;
            }

            .-text {
                flex-grow: 1;
                @media (max-width: $sm) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100vw - 6rem);
                }
            }
        }

        .-buttons {
            @include flex(center, center, row);

            > * {
                margin-left: 0.7rem;
            }

            .-remove,
            .-rename,
            .-up,
            .-down {
                cursor: pointer;
            }

            .-copy,
            .-move,
            .-remove,
            .-cancelRemove,
            .-destroy {
                &.\/off {
                    opacity: 0.3;
                }
            }
        }
    }

    .-chapters {
        padding: 1.5rem;
        background-color: white;
        transition: all 0s;
        border-radius: $borderRadiusMd;

        .-chapter {
            @include flex(center, flex-start, row wrap);
            padding-top: 1.5rem;
            border-color: lightgray;
            border-style: solid;
            border-width: 0.05rem 0 0 0;
            margin-top: 1.5rem;

            &:first-of-type {
                padding-top: 0;
                margin-top: 0;
                border: none;
            }

            .-core {
                width: 100%;
                position: relative;

                .-buttons {
                    @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                    position: absolute;
                    top: -0.8rem;
                    right: -0.8rem;
                }
            }

            .-thumbnail {
                @include shadow();
                width: 100%;
                border-radius: $borderRadiusMd;

                &Shell {
                    position: relative;
                    background-color: lightgrey;
                    border-radius: $borderRadiusMd;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .-buttons {
                        @include flex(flex-end, center, row);
                        @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                        width: 100%;
                        position: absolute;
                        top: 1rem;
                        right: 1rem;

                        .-button {
                            &.\/refresh {
                                transition: all 0s;

                                &.\/on {
                                    cursor: initial;

                                    > .-shell {
                                        animation-name: flash;
                                        animation-iteration-count: infinite;
                                        animation-duration: 1.4s;
                                        animation-timing-function: linear;
                                    }

                                    &.\/definitelyOn {
                                        cursor: pointer;

                                        > .-shell {
                                            border-color: white !important;
                                            color: white !important;
                                            background-color: rgba(0, 0, 0, 0.5) !important;
                                            animation-name: fd59274a_35cf_4e06_9f1d_73fbbcaddb5b;
                                            animation-iteration-count: infinite;
                                            animation-duration: 2s;
                                            animation-timing-function: linear;
                                        }
                                    }
                                }

                                * {
                                    transition: all 0s;
                                }
                            }
                        }
                    }
                }
            }

            .-thumbnailShell {
                position: relative;
                margin-right: 1.5rem;

                .-yours {
                    position: absolute;
                    bottom: 0.6rem;
                    left: 0;
                    padding: 0 0.3rem;
                    background-color: rgba(0, 0, 0, 0.3);
                    color: white;
                    font-size: 80%;
                    border-radius: $borderRadiusMd $borderRadiusMd 0 0;
                    transform-origin: top left;
                    transform: rotateZ(-90deg) translate(-30%, -100%);
                }
            }

            .-inputs {
                @include flex();
                width: calc(100% - 300px - 1.5rem);
                flex-grow: 1;

                .-texts,
                .-files {
                    @include flex(flex-start, flex-start, row wrap);
                    width: 100%;
                    flex-grow: 1;

                    .-row {
                        @include flex(flex-start, flex-start, row wrap);
                        width: 100%;

                        > * {
                            margin: 0 0.5rem 0.7rem;
                        }

                        flex-grow: 1;

                        .select.disabled {
                            cursor: not-allowed;
                        }
                    }

                    label {
                        @include flex(flex-start, center, row);
                    }
                }

                .-input {
                    > * {
                        width: 100%;
                    }

                    [class$='-prepend'],
                    [class$='-append'] {
                        max-width: 45%;

                        > span {
                            font-size: 75%;
                            padding: 0.375rem 0.3rem;
                            width: 100%;
                            max-width: 100%;
                            white-space: pre-line;
                        }
                    }

                    &.\/private {
                        display: none;
                    }

                    &.\/file {
                        .uploader-zone {
                            width: 100% !important;
                            height: 10rem !important;
                        }
                    }

                    .-feedback {
                        width: 100%;
                        margin-top: 0.25rem;
                        font-size: 80%;
                        user-select: none;
                    }
                }
            }

            .-meta {
                width: 100%;
                background-color: #c1d9ff;
                padding: 1rem;
                margin-top: 1.5rem;
                border-radius: $borderRadiusMd;
                position: relative;

                .-svg {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }

                .-title {
                    font-size: 110%;
                    font-weight: $fontWeightLg;
                    margin-bottom: 0.7rem;
                }
            }
        }
    }
}
