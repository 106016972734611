.livestorm {
    @include flex(center, center, row wrap);
    margin-top: 1.4rem;

    > .-title {
        width: 100%;
        text-align: center;
        font-size: 120%;
        font-weight: $fontWeightLg;
    }

    .-description {
        margin: 1.4rem 0;
        padding: 1rem;
        font-weight: 700;
        opacity: 0.8;
        width: 100%;
        text-align: center;
        background-color: color(1, null, null, null, 0.1);
        border-radius: 0.4rem;
        box-shadow: 0 0 0.825rem 0 color(1, null, null, null, 0.1);

        @media (max-width: $sm + 1) {
            text-align: left;
        }
    }

    a {
        @include discreetLink(ebeed49d_10cb_4593_a48a_dab771353e78());
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        background-color: var(--color-1-hsl);
        padding: 0.6rem;
        border-radius: $borderRadiusMd;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
            box-shadow: unset;
        }
    }
}
