.select {
    display: inline-block;
    transition: all 0s !important;
    &.\/warning {
        .-select {
            > div {
                border-color: darkorange;
            }
        }
        .-feedback {
            color: darkorange;
        }
    }
    &.\/error {
        .-select {
            > div {
                border-color: red;
            }
        }
        .-feedback {
            color: $colorRedIndianHex;
        }
    }
    * {
        transition: all 0s !important;
    }
    .-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
    }
    .-select {
        [class$='-menu'] {
            > div {
                @include scrollbar();
            }
        }
        [class$='-placeholder'] {
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
        [class$='-indicatorContainer'] {
            padding: 8px 4px;
        }
    }
}
