.bo\:listItemPage.\/organization {
    .-generalForm {
        > .-logo {
            margin: 1rem;

            .uploader {
                @include flex();

                &-zone {
                    width: 16rem !important;
                    height: 9rem !important;
                }
            }
        }

        .-location,
        .-name,
        .-mother,
        .-advancedMode,
        .-library,
        .-sponsorCode {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }

    .-creditsModules {
        @include flex(center, center, column wrap);
        max-width: 50rem;
        width: 100%;
        padding: 1rem;
        gap: 0.76rem;

        .-imageToVideoLimit,
        .-creditsModulesNoLimit {
            width: 100%;
        }
    }

    .-buttonedLabel {
        @include flex();
    }

    .-defaultTag {
        @include flex();
        padding: 0 0.6rem;

        .-core {
            position: relative;
            bottom: 0.07rem;
            background-color: $colorBlueNoteBackgroundHex;
            color: $colorBlueNoteTextHex;
            padding: 0.1rem 0.5rem;
            border-radius: 5rem;
            font-size: 70%;
            margin-right: 0.4rem;

            &.\/changed {
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
            }

            &.\/default {
                background-color: $colorBlueNoteBackgroundHex;
                color: $colorBlueNoteTextHex;
            }

            &.\/mother {
                background-color: $colorGreenNoteBackgroundHex;
                color: $colorGreenNoteTextHex;
            }

            .tooltipBox {
                cursor: pointer;
                display: inline-block;
                margin-left: 0.4rem;
            }
        }

        .-buttons {
            @include flex();
            gap: 0.4rem;

            .-button {
                @include flex();
                height: 1.4rem;
                width: 1.4rem;
                cursor: pointer;
                padding: 0 0.4rem;
                background-color: white;
                border: 0.1rem solid $colorGreenGrassHex;
                color: $colorGreenGrassHex;
                border-radius: 5rem;
                font-size: 85%;
            }
        }
    }

    .-planForm {
        @include flex(center, center, column wrap);
        max-width: 50rem;
        width: 100%;
        padding: 1rem;
        gap: 0.76rem;

        .-switchEndContainer {
            width: 100%;
            display: flex;
        }

        .-switchEndTrial {
            @include flex();
            margin-top: 1.3rem;
        }

        .-trialFlex {
            @include flex(center, flex-start, row wrap);
            width: 100%;
            gap: 1rem;

            .-trialPeriodSelectEndIn {
                width: 8rem;
            }
        }

        .-externalBilling,
        .-startAt,
        .-endAt,
        .-credits,
        .-type,
        .-subType,
        .-gaugeBehaviour,
        .-storageLimit,
        .-maxWorkspacesCount,
        .-maxMembersPerWorkspaceCount,
        .-generationDailyRateLimit,
        .-maxLicensedWithPeriodicCloudyMembersCount,
        .-maxLicensedWithPeriodicLocalMembersCount,
        .-maxLicensedWithLifetimeLocalMembersCount,
        .-allowedApiAppVideoGenerations,
        .-playerVideosOpenToPublic,
        .-visibleCreditsGauge {
            width: 100%;
        }

        .-externalBilling {
            @include flex();
            margin-bottom: 0.8rem;
        }

        .-trialPeriodEndIn,
        .-trialPeriodSelectEndIn {
            flex-grow: 1;
            @media (max-width: $sm) {
                max-width: 50%;
            }
        }

        .-offeredMonthForFree,
        .-offeredMonthThanksToSponsorship {
            width: 100%;
        }

        .-trialPeriodStartAt,
        .-trialPeriodEndAt {
            max-width: 100%;
            flex-grow: 1;
        }

        .-gaugeBehaviour {
            label {
                width: 100%;
                position: relative;
            }

            .-preview {
                position: absolute;
                width: 8rem;
                top: 100%;
                left: calc(100% + 1.6rem);

                .-step {
                    position: relative;

                    .progress {
                        margin: 0.1rem 0 0.4rem;
                        position: relative;

                        &-bar {
                            background-color: $colorGreenGrassHex !important;
                        }
                    }

                    > span {
                        position: absolute;
                        left: calc(100% + 0.6rem);
                        top: -0.1rem;
                        width: 6rem;
                        font-size: 90%;

                        strong {
                            color: $colorGreenGrassHex;
                        }
                    }
                }

                svg {
                    position: absolute;
                    left: -1.2rem;
                    top: 0.8rem;
                    transform: scale(-1, 1) rotateZ(120deg);
                }
            }
        }

        .-submit {
            margin-top: 1.3rem;
        }
    }

    .-planDealsForm {
        @include flex(center, flex-start);
        width: 50rem;
        padding: 1rem;
        gap: 0.76rem;

        @media (max-width: $sm) {
            width: 100%;
        }

        .-tabs,
        .-deal {
            width: 100%;
        }

        .-tabs {
            @include flex();
            margin-top: 1rem;
            gap: 2.8rem;

            .-tab {
                @include flex();

                &.\/disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }

                .-label {
                    &.\/focused {
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }

                .-since {
                    margin-left: 0.6rem;
                    font-size: 80%;
                    background-color: lightgreen;
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.5rem;
                    color: darkgreen;
                }

                .-forget {
                    margin-left: 0.6rem;

                    .-shell {
                        @include flex();
                        color: red;
                        width: 1.4rem;
                        height: 1.4rem;
                        padding: 0.2rem;
                        background-color: lightpink;
                        border-radius: 50px;
                        cursor: pointer;
                    }

                    svg {
                        width: 0.8rem;
                    }
                }
            }
        }

        .-deal {
            @include flex(center, flex-start);
            gap: 0.76rem;

            .-couponAmount,
            .-couponPercentage,
            .-monthsCount,
            .-monthlyPaid {
                width: 40%;
                flex-grow: 1;
            }
        }

        .-pause {
            @include flex(flex-start, flex-start);
            align-items: center;
            width: 100%;
            gap: 0.76rem;

            .-pauseStartAt {
                width: 33%;
            }
            .-pauseMonthsCount {
                width: 33%;
            }
            .-submit {
                width: 30%;
            }
            .-cancelButton {
                @include flex(flex-start, flex-start, column);
                align-items: center;
                width: 30%;
                gap: 0.76rem;
                .-submit {
                    width: 100%;
                }
                .-cancel {
                    background-color: #ff0000;
                    width: 100%;
                }
            }
        }

        .-userSummary {
            width: 100%;
        }

        .-submit {
            margin-top: 1.3rem;
        }
    }

    .-members {
        @include flex();
        padding: 1.2rem 0.6rem 0.4rem;
        gap: 1.2rem;
        width: 50rem;
        max-width: 100%;

        .-member {
            @include flex();
            @include shadow();
            text-decoration: none;
            color: initial;
            padding: 0.8rem 2rem;
            min-width: 10rem;
            background-color: white;
            border-radius: $borderRadiusMd;
            cursor: pointer;
            position: relative;

            &.\/imaged {
                padding-right: 4.2rem;
            }

            &.\/notLicensed {
                border: 0.2rem solid orange;
            }

            &.\/licensed {
                border: 0.2rem solid limegreen;
            }

            .-portrait {
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 0;
                right: 0;
                border-bottom-left-radius: 100%;
                border-top-right-radius: $borderRadiusMd;
            }

            .-directorCrown {
                color: orange;
                position: absolute;
                top: -1.1rem;
                right: -0.6rem;

                svg {
                    transform: rotateZ(25deg);
                }
            }

            .-licensedOrNot {
                position: absolute;
                bottom: 0;
                right: 0.3rem;

                &.\/yes {
                    color: limegreen;
                }

                &.\/no {
                    color: orange;
                }
            }
        }
    }

    .-whitelabel {
        @include flex(center, flex-start, row wrap);
        width: 50rem;
        max-width: 100%;
    }

    .-whitelabelGeneralForm {
        @include flex(center, flex-start, row wrap);
        margin-top: 1rem;
        width: 100%;

        .-logo,
        .-metaThumbnail > .-uploader {
            width: 15rem;
            height: 8rem;

            .uploader-zone {
                width: 15rem;
                height: 8rem;
                @media (max-width: $sm) {
                    max-width: 100%;
                }
            }

            @media (max-width: $sm) {
                max-width: 100%;
            }
        }

        > * {
            margin: 0.4rem;
        }

        .-name,
        .-domainName,
        .-metaTitle,
        .-metaDescription,
        .-metaThumbnail {
            width: calc(100% - 2 * 0.4rem);
        }

        .-metaThumbnail {
            @include flex();
            margin: 1.4rem 0 0.4rem;
        }

        .-domainName {
            .input-group-append {
                position: relative;
                z-index: 3;
            }

            input {
                position: relative;
                z-index: 2;
            }

            .-done {
                position: relative;
                background-color: limegreen;
                color: white;
                padding: 0.3rem;
                border-radius: 50px;
                font-size: 120%;
                z-index: 3;
            }

            .-helper {
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
                position: relative;
                top: -0.2rem;
                padding: 1rem;
                z-index: 1;
                border-radius: 0 0 $borderRadiusMd $borderRadiusMd;
                border: 1px solid lightgrey;

                strong {
                    color: $colorOrangeNoteTextHex;
                }

                .-record {
                    background-color: yellow;
                    color: grey;
                    padding: 0.2rem 0.4rem;
                    font-weight: bold;
                }

                &.\/cnameReady {
                    background-color: $colorBlueNoteBackgroundHex;
                    color: $colorBlueNoteTextHex;

                    strong {
                        color: $colorBlueNoteTextHex;
                    }
                }

                &.\/httpsReady {
                    background-color: $colorGreenNoteBackgroundHex;
                    color: $colorGreenNoteTextHex;

                    strong {
                        color: $colorGreenNoteTextHex;
                    }
                }
            }
        }

        .-submit {
            margin: 2rem 1rem 0.4rem;
        }
    }

    .-whitelabelColorSchemeForm {
        @include flex();
        width: 100%;

        .-pickers {
            @include flex();
            width: 100%;
            user-select: none;

            .-picker {
                @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                position: relative;
                margin: 1rem;
                user-select: none;

                .-name {
                    display: inline-block;
                    user-select: none;
                    font-weight: bold;
                    width: 100%;
                    color: grey;
                    font-style: italic;
                    text-align: center;
                }

                .-reset {
                    position: absolute;
                    user-select: none;
                    top: calc(100% - 1rem);
                    right: -1rem;
                }
            }
        }

        .-submit {
            margin: 1.4rem 1rem 0.6rem;
            @media (max-width: $sm) {
                margin: 1rem;
            }
        }
    }

    .-dangerZone {
        @include flex();
        width: 50rem;
        padding: 0 1rem 1rem 1rem;
        margin: 1rem;
        border-radius: $borderRadiusMd;

        .-razeForm {
            margin: 0.5rem;
        }
    }
}
