.bo\:listItemPage.\/workspace {
    .-generalForm {
        .-name,
        .-organization,
        .-signature,
        .-location {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }
}
