.homePage {
    @include flex(flex-start, center, column);
    text-align: center;

    .-title {
        width: 100%;
        text-align: left;
        font-size: 130%;
        font-weight: 900;
        padding: 1.1rem;
        padding-left: 1.2rem;
    }
}

.html[class*='/routed/frontOffice/'][class*='front-office::'] {
    &[class*='::home'] {
        @include flex(flex-start, center, column);
        background-color: white;

        body {
            @include flex(flex-start, center, column);
            flex-grow: 1;

            #__next {
                @include flex(flex-start, center, column);
                flex-grow: 1;

                .root {
                    @include flex(flex-start, center, column);
                    flex-grow: 1;
                    width: 100%;

                    > .-core {
                        @include flex(flex-start, center, column);
                        flex-grow: 1;
                        width: 100%;

                        .homePage {
                            @include flex(flex-start, center, column);
                            flex-grow: 1;
                            width: 100%;

                            .template\:library {
                                @include flex(flex-start, center, column);
                                flex-grow: 1;
                                width: 100%;

                                .-templates {
                                    width: 100%;
                                    align-content: flex-start !important;
                                    align-items: flex-start !important;
                                    flex-grow: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
