// We copied some of the Hubspot rules
.chatAlternative {
    @include flex(center, center, row);
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding: 0.3rem 0.8rem;
    border-radius: $borderRadiusLg;
    background-color: var(--color-1-hsl);
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 6px, rgba(0, 0, 0, 0.2) 0 2px 24px;
    cursor: pointer;
    z-index: 2147483647;
    font-weight: bold;
    border-color: ebeed49d_10cb_4593_a48a_dab771353e78();
    border-width: 0.12rem;
    border-style: solid;
    &.\/offline {
        padding: 0.5rem;
        border-radius: 100%;
        .-text {
            font-style: italic;
            font-size: 85%;
            line-height: 120%;
            text-align: right;
            display: inline-block;
            position: absolute;
            right: calc(100% + 0.6rem);
            top: 50%;
            transform: translateY(-50%);
            width: 18rem;
            color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            @media (max-width: $sm) {
                display: none;
            }
        }
    }
    svg {
        width: 32px;
        height: 30px;
        fill: ebeed49d_10cb_4593_a48a_dab771353e78();
    }
    .-text {
        margin-left: 0.4rem;
        color: ebeed49d_10cb_4593_a48a_dab771353e78();
        font-size: 90%;
        @media (max-width: $sm) {
            display: none;
        }
    }
    @media (max-width: $sm) {
        padding: 0.6rem;
        border-radius: 100%;
    }
}
