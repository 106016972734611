.bo\:bot\:modal {
    &.\/deploy {
        .-contentShell {
            width: 30rem;
        }
        .-content {
            @include flex(center, flex-start, row wrap);
            ul {
                margin: 0;
            }
            .-existent,
            .-detached {
                @include flex(flex-start, flex-start, row);
                padding: 0 0.2rem 1rem;
                flex-grow: 1;
                color: grey;
                font-size: 90%;
                span {
                    margin: 0.3rem 0.4rem 0;
                }
            }
            .-flavor,
            .-quantity,
            .-source,
            .-snapshot,
            .-os,
            .-server,
            .-ip,
            .-description,
            .-state,
            .-license {
                width: 100%;
                margin: 0.5rem;
            }
            .-notice {
                width: 100%;
                padding: 1rem 0.5rem 0 0.5rem;
            }
            .-submit {
                margin: 1.5rem 0.5rem 0.5rem 0.5rem;
                width: 100%;
            }
            > .input .-shell {
                width: 100%;
            }
        }
    }
}
