.organization\:workspacesPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();

    .-creditsBar {
        margin-bottom: 1.2rem;
        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .or {
        margin-top: 1.5rem;
        width: 30rem;
        color: lightslategray;
        @media (max-width: $sm) {
            margin-top: 0.8rem;
            width: 100%;
        }
    }

    .-workspaces {
        @include flex(center, flex-start, row wrap);
        width: 100%;
        padding: 0.5rem;

        svg {
            transition: all 0.3s;
        }

        .uploader-zone {
            width: 8rem;
            height: 8rem;
        }

        .-items {
            @include flex(center, flex-start, row wrap);
            width: 100%;
            margin-bottom: 1rem;

            .-workspace {
                @include flex();
                @include discreetLink();
                margin: 0.5rem;
                width: 13rem;
                background-color: white;
                border-radius: $borderRadiusMd;
                padding: 1rem;
                cursor: pointer;
                position: relative;

                &:hover {
                    @include shadow();
                    opacity: 1;

                    .-edit {
                        visibility: visible;
                        height: 2rem;
                    }
                }

                .-edit {
                    visibility: hidden;
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    height: 0;
                }

                .-logo {
                    @include flex(center, center, row wrap);
                    width: 6rem;
                    height: 6rem;
                    border-radius: 500rem;
                    overflow: hidden;
                    color: white;
                    text-transform: uppercase;
                    font-size: 130%;
                    font-weight: bold;
                }

                .-name {
                    padding-top: 0.6rem;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .-addForm {
            @include flex(center, center, column);
            width: 100%;

            .-name {
                width: 15rem;
                margin: 0.5rem 0.5rem 1rem;
                @media (max-width: $xs) {
                    width: 100%;
                }
            }

            .-submit {
                margin-top: 1.2rem;
            }
        }
    }

    .-showAddForm {
        @include flex();
        width: 100%;

        span {
            text-align: center;
            margin-top: 1rem;
            text-decoration: underline;
            cursor: pointer;
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            user-select: none;
        }

        &.\/afterPurchase {
            span {
                @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                padding: 0.3rem 0.6rem;
                text-decoration: none;
                border-radius: $borderRadiusMd;
            }
        }
    }
}
