.user\:creatorHomePage {
    @include flex();
    padding: 1rem;
    text-align: center;
    h1 {
        font-size: 140%;
        font-weight: $fontWeightXl;
        width: 100%;
        margin-top: 1rem;
        padding: 0.4rem;
    }
    h2 {
        font-size: 110%;
        font-weight: $fontWeightXl;
        width: 100%;
        padding-top: 2rem;
    }
    ul {
        text-align: left;
        max-width: 40rem;
        margin: 3rem 1rem 1.6rem;
        span {
            display: inline-block;
            width: 100%;
            margin-bottom: 0.4rem;
        }
        li {
            padding: 0.2rem 0;
            font-size: 85%;
        }
        a {
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            &:hover,
            &:focus {
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            }
        }
    }
    > .-buttons {
        @include flex();
        width: 100%;
        > .-button {
            @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
            @include flex(center, center, row wrap);
            display: inline-block;
            border-radius: $borderRadiusMd;
            margin: 1rem 0.5rem;
            padding: 1rem;
            width: 10rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            span {
                display: inline-block;
                width: 100%;
                margin-top: 0.6rem;
            }
            svg {
                height: 2rem;
                fill: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
                * {
                    fill: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
                }
            }
            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }
    }
    > .-btns {
        @include flex(center, center, column wrap);
        width: 100%;
        padding: 0.6rem 1rem 0.8rem;
        > .-btn {
            @include flex(center, center, row);
            background-color: var(--color-2-hsl);
            color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            padding: 0.4rem 1rem;
            position: relative;
            border-radius: 500rem;
            cursor: pointer;
            margin: 0.3rem 0.4rem;
            svg {
                margin-right: 0.6rem;
            }
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
    > .-footer {
        width: 100%;
        color: grey;
        cursor: pointer;
        a {
            color: grey;
            text-decoration: underline;
            &:hover,
            &:focus {
                text-decoration: underline;
                color: grey;
            }
        }
    }
}
