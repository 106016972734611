.organization\:apiAppPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();

    .-creditsBar {
        margin-bottom: 1.2rem;

        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .-creds {
        @include flex(center, center, column);
        width: 100%;

        .-cred {
            width: 100%;
            max-width: 23.4rem;
            margin: 0.5rem;

            .-label {
                .-copy {
                    cursor: pointer;
                    color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                    font-weight: bold;
                }
            }
        }
    }

    .-docs {
        margin-top: 1rem;

        span {
            font-style: italic;
        }
    }
}
