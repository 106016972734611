.bo\:listItemPage.\/user {
    .-header {
        @include flex(center, center, column);
        width: 100%;
        font-weight: $fontWeightLg;

        h1 {
            margin-top: 0 !important;
        }

        .-state {
            margin-bottom: 0.4rem;

            &.\/ready {
                color: limegreen;
            }

            &.\/pendingWithEmailSent {
                color: orange;
            }

            &.\/pendingWithNoEmailSentYet {
                @include flex(center, center, column);
                max-width: 40rem;
                color: orange;
                background-color: lightyellow;
                padding: 1rem 1rem 1.3rem 1rem;
                margin: 1rem;
                border-radius: $borderRadiusMd;
            }

            &.\/locked {
                color: red;
            }

            .dropdown-menu {
                text-align: left;
            }

            .-img {
                width: 3rem;
                transform: rotate(-15deg);
                margin: 0 1rem 1rem 0;
            }

            .-speech {
                padding: 0.7rem;
                font-size: 90%;
                font-style: italic;
                opacity: 0.4;
                color: initial;
            }
        }

        .-title {
            position: relative;
            margin-top: 1rem;

            .-crown {
                position: absolute;
                top: -1rem;
                right: -1rem;
                color: orange;
                transform: rotateZ(25deg);
            }
        }

        .-info {
            font-weight: initial;
        }
    }

    .-generalForm {
        .-uploader {
            margin: 1rem;

            .uploader-zone {
                width: 12rem !important;
                height: 12rem !important;
            }
        }

        .-studio,
        .-organization,
        .-firstname,
        .-lastname,
        .-email,
        .-phone,
        .-job,
        .-director,
        .-interests {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }

    .-organizationBadges {
        @include flex(center, stretch, row wrap);
        padding: 0.5rem 10rem 0;
        width: 100%;

        .-organizationBadge {
            @include flex(flex-start, center, column wrap);
            background-color: white;
            position: relative;
            width: 10rem;
            min-height: 10rem;
            padding: 1rem;
            margin: 0.7rem;
            border-radius: $borderRadiusMd;

            &:hover,
            &:focus {
                @include shadow();

                .-remove,
                .-signatureManager,
                .-signatureDirector {
                    svg {
                        width: 1.2rem;
                    }
                }
            }

            .-remove,
            .-signatureDirector {
                position: absolute;
                top: 0.7rem;
                right: 0.7rem;
                cursor: pointer;
            }

            .-signatureManager {
                position: absolute;
                top: 2.4rem;
                right: 0.7rem;
                cursor: pointer;
            }

            .-remove {
                svg {
                    width: 0;
                    fill: red;
                }
            }

            .-signatureDirector,
            .-signatureManager {
                svg {
                    width: 0;
                    fill: var(--color-1-hsl);
                }
            }

            .-badgeIcon {
                height: 4rem;
            }

            .-signature,
            .-signatureMissing {
                padding-top: 1rem;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 0.3rem;

                .-text {
                    font-size: 70%;
                    text-align: center;
                    font-style: italic;
                    font-weight: bold;
                }
            }

            .-signature {
                color: var(--color-1-hsl);
            }

            .-signatureMissing {
                color: #de4242;
                cursor: pointer;
            }

            .-workspace {
                width: 100%;
                text-align: center;
                font-size: 80%;
                opacity: 0.6;
                margin-top: 1rem;
            }

            .-badge {
                width: 100%;
                margin-top: 0.8rem;
                text-align: center;
                font-weight: $fontWeightXl;
            }
        }

        .-addBadgeForm {
            @include flex(center, flex-start, row wrap);
            position: relative;
            border-radius: $borderRadiusMd;
            width: 17rem;
            height: 10rem;
            padding: 0 1rem;
            margin: 0.7rem;

            .-workspace {
                width: 100%;
                margin-bottom: 0.3rem;
            }

            .-badge {
                width: 100%;
            }

            .-validate {
                margin-top: 1rem;
                text-decoration: underline;
                cursor: pointer;
                color: var(--color-1-hsl);
                user-select: none;
            }
        }
    }

    .-dangerZone {
        @include flex();
        width: 50rem;
        padding: 0 1rem 1rem 1rem;
        margin: 1rem;
        border-radius: $borderRadiusMd;

        .-lockUserForm,
        .-addDirectorBadgeForm,
        .-removeDirectorBadgeForm,
        .-copyReinitiatePasswordLink,
        .-copyActivationLink {
            margin: 0.5rem;
        }

        .-pokeUserForActivationForm {
            padding-left: 0.5rem;

            .-dot {
                background-color: white !important;
            }
        }
    }
}
