.bo\:listBox.\/user {
    .-mosaic {
        .-item {
            @include flex(center, flex-start, row wrap);
            padding: 1rem;
            width: 10rem;
            .image {
                border-radius: 500rem;
                width: 6rem;
                height: 6rem;
            }
            &:hover {
                .image {
                    transform: scale(1.1);
                }
            }
            .-names {
                width: 100%;
                padding-top: 0.5rem;
                text-align: center;
            }
        }
    }
}
