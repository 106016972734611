@mixin e32d2781_67c8_4008_9b12_a5ad124b565c($width: 13rem) {
    @include flex();
    background-color: white;
    text-align: left;
    font-size: 130%;
    font-weight: $fontWeightXl;
    margin-right: 1rem;

    svg {
        margin-right: 0.8rem;
    }

    @media (max-width: $sm) {
        width: 100%;
    }
}
