.workspace\:modal {
    &.\/remove {
        .-dialog {
            @media (min-width: $sm + 1) {
                padding-bottom: 20rem;
            }
        }
        .-content {
            @include flex(center, center, row wrap);
            > .-toWorkspace {
                width: 100%;
                margin: 0.4rem 0;
                .-option {
                    @include flex(flex-start, center, row);
                    width: 100%;
                    > span {
                        @include flex(center, center, row);
                        width: 1.8rem;
                        height: 1.8rem;
                        border-radius: 10rem;
                        text-transform: uppercase;
                        color: white;
                        margin-right: 0.6rem;
                    }
                }
            }
            > .-submit {
                margin-top: 1rem;
            }
        }
    }
}
