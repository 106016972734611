.tooltipBox {
    position: relative;
    > .-shell {
        width: 100%;
        height: 100%;
    }
    > .-tooltip {
        position: absolute;
        padding: 0.5rem 1rem;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        border-radius: $borderRadiusMd;
        font-size: 90%;
        user-select: none;
        z-index: $zIndexTooltip;
        white-space: nowrap;
    }
}
