@mixin f6fe0b12_cb47_44a1_9adf_54a7fd355e8b() {
    @include flex(flex-start, center, column wrap);
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-right: 1rem;
    padding-left: calc(1rem + #{$headerHeight});
    min-height: calc(100vh - #{$headerHeight}); // page height may be quite short if no member yet

    > .-title {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        font-size: 130%;
        font-weight: $fontWeightXl;
        user-select: none;
        svg {
            margin-left: 0.6rem;
        }
        * {
            user-select: none;
        }
        @media (max-width: $xs) {
            padding: 0;
        }
    }

    @media (max-width: $sm) {
        padding: 1rem 1rem 2rem;
    }
}

@mixin aecf8dbe_5e54_4dfb_99b3_ca43f3a588f4() {
    .-speech {
        text-align: center;
        padding: 0 1rem;
        width: 100%;
        .-part1 {
            font-weight: $fontWeightLg;
            opacity: 0.8;
        }
        .-part2 {
            opacity: 0.5;
        }
        @media (max-width: $xs) {
            padding: 0.5rem 0 0;
        }
    }
}
