.bo\:listItemPage.\/templateSource {
    .-header {
        .-meta {
            @include flex(center, center, row);
            font-size: 80%;
            .-separator {
                margin: 0 0.3rem;
            }
            .-duration {
                svg {
                    margin-right: 0.2rem;
                }
            }
        }
        .-state {
            margin: 0.2rem 0 0.6rem 0;
            .dropdown-menu {
                text-align: left;
            }
        }
    }
    .-generalForm {
        .-name,
        .-description,
        .-open,
        .-level,
        .-config,
        .-matter,
        .-draft {
            width: 100%;
            margin-bottom: 0.7rem;
        }
        .-thumbnails {
            @include flex(center, flex-end, row wrap);
            width: 100%;
            padding: 0.5em;
            .-row {
                @include flex(center, stretch, row wrap);
                margin-bottom: 0.8rem;
                width: 100%;
                .-ratioGroup {
                    @include flex(center, center, column);
                    small {
                        color: grey;
                    }
                }
                .-title {
                    width: 100%;
                    text-align: center;
                    text-decoration: underline;
                    margin-bottom: 0.3rem;
                }
            }
            .-thumbnail {
                margin: 0.5em !important;
                width: 14rem !important;
                text-align: center !important;
                .uploader-zone {
                    height: 10rem !important;
                }
            }
        }
        .-uploader {
            &.\/preview {
                .uploader {
                    &-zone {
                        width: 20rem !important;
                    }
                }
            }
        }
    }
    .-configForm {
        @include flex();
        max-width: 50rem;
        width: 100%;
        position: relative;
        .-config {
            width: 100%;
            margin: 0.7rem;
        }
        .-fileMode {
            @include flex(center, center, column);
            font-size: 82.5%;
            z-index: 90;
            position: absolute !important;
            left: 51.4rem;
            top: 27.4rem;
            * {
                user-select: none;
            }
            [disabled] {
                opacity: 0.3;
                ~ label {
                    opacity: 0.3;
                }
            }
            > label {
                transform: rotateZ(-90deg);
                position: absolute;
                left: -2.9rem;
                color: darkgrey;
                border-style: solid;
                border-width: 0 0 1px;
                border-color: darkgrey;
                width: 4rem;
                text-align: center;
            }
            span {
                color: grey;
                font-size: 85%;
            }
        }
        .-thumbnails {
            @include flex(center, flex-end, row wrap);
            width: 100%;
            padding: 1rem 0 20rem;
            .-row {
                @include flex(center, stretch, row wrap);
                margin-bottom: 0.8rem;
                width: 100%;
                .-ratioGroup {
                    @include flex(center, center, column);
                    small {
                        color: grey;
                    }
                }
                .-title {
                    width: 100%;
                    text-align: center;
                    text-decoration: underline;
                    margin-bottom: 0.3rem;
                }
            }
            .-thumbnail {
                margin: 0.5em !important;
                width: 14rem !important;
                text-align: center !important;
                .uploader-zone {
                    height: 10rem !important;
                }
            }
        }
        .-music {
            .-file {
                margin: 0.5em !important;
                width: 14rem !important;
                text-align: center !important;
            }
        }
        .-music,
        .-fonts,
        .-staticFonts {
            @include flex();
            padding: 1rem 0 20rem;
            width: 100%;
            .-row {
                @include flex(center, flex-start);
                margin-bottom: 0.8rem;
                width: 100%;
                height: 6.8rem;
                .-title {
                    width: 100%;
                    text-align: center;
                    text-decoration: underline;
                    margin-bottom: 0.3rem;
                }
                .animation {
                    width: 100%;
                    text-align: center;
                    padding: 0.2rem;
                }
            }
        }
        .-fonts,
        .-staticFonts {
            @include dd6e8d3d_56eb_437c_8c60_038739b38d08();
            .-font {
                @include e5a05cb1_2d23_4a00_aa46_1440e5b177a4();
                @include cbf5e87d_4e22_40bb_be00_c3baf6923eb5();
                width: 20rem;
            }
            .-upload {
                @include hidden();
            }
        }
        .-nothingToConfigure {
            color: grey;
            font-style: italic;
            padding-bottom: 60rem;
            text-align: center;
            width: 100%;
        }
    }
    .-seedlings {
        @include flex(center, flex-start, row wrap);
        padding: 0.5rem 1rem 0 1rem;
        width: 100%;
        .-seedling {
            @include flex(center, flex-start, row wrap);
            align-content: baseline;
            background-color: white;
            position: relative;
            width: 10rem;
            min-height: 10rem;
            padding: 1rem;
            margin: 0.7rem;
            border-radius: $borderRadiusMd;
            border-width: 0.2rem;
            border-style: solid;
            &:hover,
            &:focus {
                @include shadow();
                .-buttons {
                    > * {
                        @include flex();
                        visibility: visible;
                        width: 1.7rem;
                        height: 1.7rem;
                        svg {
                            width: 1.1rem !important;
                            * {
                                display: initial;
                            }
                        }
                    }
                    .-preview,
                    .-validate {
                        border-color: var(--color-1-hsl);
                    }
                    .-draft {
                        border-color: grey;
                    }
                    .-remove {
                        border-color: $colorRedIndianHex;
                    }
                }
            }
            &.\/draft {
                border-color: lightgrey;
            }
            &.\/valid {
                border-color: limegreen;
            }
            &.\/almostValid {
                border-color: orange;
            }
            .-buttons {
                @include flex(flex-start, flex-start, column);
                position: absolute;
                top: 0.7rem;
                left: calc(100% - 0.8rem);
                > * {
                    margin-bottom: 0.6rem;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    cursor: pointer;
                    border-color: transparent;
                    border-radius: 10rem;
                    border-style: solid;
                    border-width: 0.08rem;
                    background-color: white;
                    > .-shell {
                        @include flex();
                    }
                    svg {
                        width: 0 !important;
                        * {
                            display: none;
                        }
                    }
                }
                .-preview,
                .-validate {
                    color: var(--color-1-hsl);
                }
                .-draft {
                    color: grey;
                }
                .-remove {
                    color: $colorRedIndianHex;
                }
            }
            .-name {
                max-width: 80%;
                word-break: break-word;
                text-align: center;
                font-weight: $fontWeightXl;
                margin-bottom: 0.4rem;
                &.\/none {
                    opacity: 0.6;
                }
            }
            .-special,
            .-workspace,
            .-organization,
            .-library,
            .-categories {
                width: 100%;
                margin-bottom: 0.4rem;
                text-align: center;
                > div {
                    width: 100%;
                    &:first-of-type {
                        font-size: 80%;
                        opacity: 0.6;
                    }
                }
            }
            .-teenSwitch {
                @include flex(center, center, row wrap);
                margin-top: 0.8rem;
                > span {
                    @include flex(center, center, row);
                    margin-top: 0.4rem;
                    width: 100%;
                }
            }
        }
        .-addSeedlingForm {
            @include flex(center, flex-start, row wrap);
            position: relative;
            border-radius: $borderRadiusMd;
            width: 17rem;
            padding: 0 1rem;
            margin: 0.7rem;
            .or {
                margin: 0;
                padding: 0;
                .-body {
                    padding: 0 0.3rem;
                }
            }
            .-name,
            .-workspace,
            .-organization,
            .-library {
                width: 100%;
                margin-bottom: 0.3rem;
                user-select: none;
            }
            .-mother {
                @include flex(flex-end, flex-end);
                font-size: 80%;
                width: 100%;
                user-select: none;
                label {
                    &:before,
                    &:after {
                        transform: translateY(-0.2rem);
                    }
                }
            }
            .-validate {
                margin-top: 0.6rem;
                text-decoration: underline;
                cursor: pointer;
                color: var(--color-1-hsl);
                user-select: none;
            }
        }
    }
}
