.video\:snippet {
    @include shadow();
    @include discreetLink();
    width: 20rem;
    border-radius: $borderRadiusLg;
    margin: 0.7rem;
    z-index: 0;
    position: relative;
    transition: all 0.3s;
    &.\/enterable {
        cursor: pointer;
    }
    &:hover {
        box-shadow: 0 0.2rem 0.7rem #c6c6cb;
        > .-buttons {
            > * {
                width: 1.7rem !important;
                height: 1.7rem !important;
                visibility: visible;
                svg {
                    width: auto !important;
                    * {
                        display: initial !important;
                    }
                }
            }
        }
        @media (min-width: $md + 1) {
            transform: translateY(-0.4rem);
            transition-timing-function: ease-out;
        }
    }
    .-preview {
        @include flex(center, center, row);
        flex-grow: 1;
        height: 11.25rem;
        width: 100%;
        position: relative;
        background-color: $colorGreySnowHex;
        img,
        video {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            z-index: 1;
            &:focus {
                outline: none;
            }
        }
        &Shell {
            @include flex();
            position: relative;
            border-radius: $borderRadiusLg $borderRadiusLg 0 0;
            flex-grow: 1;
            width: 100%;
            overflow: hidden;
            > img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .-progress {
        height: 0.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 100px 100px 0;
        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
        box-shadow: 100px 9999px 0 9999px lightgrey;
        z-index: 1;
    }
    .-footer {
        @include flex(flex-start, center, row wrap);
        padding: 0.6rem 6.8rem 0.6rem 1rem;
        text-align: left;
        width: 100%;
        position: relative;
        background-color: white;
        z-index: 1;
        border-radius: 0 0 $borderRadiusLg $borderRadiusLg;
        .-name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 95%;
            font-weight: $fontWeightMd;
            margin-right: 0.5rem;
            line-height: 110%;
            margin-bottom: 0.3rem;
        }
        .-render {
            @include flex();
            position: absolute;
            bottom: 0.3rem;
            right: 0.8rem;
            background: white;
            width: 2rem;
            height: 2rem;
            z-index: 2;
            color: color(1, $l: 40%);
            border-radius: 40px;
            .-shell {
                @include flex();
            }
        }
        .-tag {
            background-color: orange;
            border-radius: 500rem;
            padding: 0.1rem 0.5rem;
            font-size: 80%;
            margin: 0 0.3rem 0.3rem 0;
            color: white;
            &.\/error {
                background-color: red;
            }
            &.\/draft {
                background-color: grey;
            }
            &.\/toBeSent {
                background-color: dodgerblue;
            }
            &.\/done {
                background-color: $colorGreenGrassHex;
            }
            &.\/beingCompleted {
                background-color: #c873ff;
            }
            &.\/toBeCompleted {
                background-color: #dea6ff;
            }
        }
    }
    > .-buttons {
        @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
        position: absolute;
        top: 1rem;
        right: -0.7rem;
        z-index: 2;
        > * {
            margin-bottom: 0.6rem;
            width: 0 !important;
            height: 0 !important;
            visibility: hidden;
            svg {
                width: 0 !important;
                * {
                    display: none !important;
                }
            }
        }
        .-remove {
            * {
                border-color: indianred !important;
                fill: indianred;
            }
        }
    }
}
