.template\:modal {
    &.\/configFonts {
        .-content {
            @include flex(center, flex-start);
            width: 100%;
            background-color: white;
            padding: 1rem;
            border-bottom-left-radius: $borderRadiusMd;
            border-bottom-right-radius: $borderRadiusMd;
            &Shell {
                @include flex(center, flex-start);
                padding: 0 0 2rem;
                min-height: 70vh;
                background-color: transparent;
            }
            .-tabs {
                @include flex(center, center, row);
                > * {
                    margin: 0.4rem 1.2rem 1.6rem;
                }
            }
            .-fonts {
                @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                width: 100%;
                .-row {
                    margin-bottom: 1.6rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    > .-title {
                        @include flex(center, center, row wrap);
                        text-align: center;
                        > .-text {
                            width: 100%;
                            font-weight: bold;
                            margin-bottom: 0.2rem;
                        }
                        .-warning {
                            border-radius: $borderRadiusMd;
                            font-size: 75%;
                            padding: 0.1rem 0.6rem;
                            margin: 0 0 0.4rem;
                            background-color: $colorOrangeNoteBackgroundHex;
                            color: $colorOrangeNoteTextHex;
                        }
                    }
                    .-note {
                        margin-top: 0.5rem;
                        padding: 0.3rem 0.6rem;
                        font-size: 90%;
                        border-radius: $borderRadiusMd;
                        background-color: $colorGreySnowHex;
                        color: grey;
                    }
                    .-font {
                        @include flex(center, center, row);
                        @include cbf5e87d_4e22_40bb_be00_c3baf6923eb5();
                        @include dd6e8d3d_56eb_437c_8c60_038739b38d08();
                        @include e5a05cb1_2d23_4a00_aa46_1440e5b177a4();
                        width: 100%;
                        .-select {
                            width: 100%;
                        }
                    }
                }
                .-upload {
                    @include hidden();
                }
            }
        }
    }
}
