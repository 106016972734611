.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zIndexHeader;
    transition: all 0s !important;
    background-color: var(--color-2-hsl);
    * {
        outline: none;
        &:hover,
        &:focus {
            outline: none;
        }
    }
}

.html[class*='/routed/backOffice/'] {
    .header {
        background-color: skyblue;
        .-logo {
            position: relative;
            &:after {
                content: 'BackOffice';
                position: absolute;
                bottom: 0;
                right: -4.4rem;
                color: black;
                opacity: 0.8;
                font-size: 80%;
                font-style: italic;
            }
        }
    }
}
