.bo\:homePage {
    @include flex();
    text-align: center;
    font-size: 120%;
    padding: 3rem 2rem;
    .-caption {
        width: 100%;
        font-style: italic;
        font-weight: $fontWeightLg;
        font-size: 130%;
    }
}
