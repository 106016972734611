.bo\:listItemPage.\/category {
    .-generalForm {
        .-publicName,
        .-privateName,
        .-description,
        .-displayRank {
            width: 100%;
            margin-bottom: 0.7rem;
        }
    }
}
