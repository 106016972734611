@keyframes a2e19162_00de_41f2_9821_cdb6f9e83c17 {
    from {
        transform: scale(0.8);
        opacity: 0.8;
    }
    40% {
        transform: scale(1.2);
        opacity: 1;
    }
    80% {
        transform: scale(0.8);
        opacity: 0.8;
    }
    to {
        transform: scale(0.8);
        opacity: 0.8;
    }
}

.animation {
    &.\/discreet {
        .-dot {
            width: 0.4rem;
            height: 0.4rem;
        }
    }
    .-dot {
        margin: 0 0.2rem;
        display: inline-block;
        border-radius: 500rem;
        transform: scale(0.9);
        opacity: 0.8;
        width: 0.8rem;
        height: 0.8rem;
        animation-name: a2e19162_00de_41f2_9821_cdb6f9e83c17;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        &:first-of-type {
            animation-delay: 0s;
        }
        &:nth-of-type(2n + 0) {
            animation-delay: 0.1s;
        }
        &:last-of-type {
            animation-delay: 0.2s;
        }
    }
}
