/**
 * Preimport the mixin tree, that will then be available to every file
 */
@import 'bridge/mixin';
@import 'lib/mixin';
@import 'office/mixin';

@mixin discreetLink($color: #{$colorGreySlateHex}) {
    color: $color;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        color: $color;
        text-decoration: none;
    }
}

@mixin link() {
    color: #007bff;
    &:hover,
    &:focus,
    &:active {
        color: #007bff;
        text-decoration: underline;
    }
}

@mixin hidden($position: fixed) {
    position: $position !important;
    top: 9999px !important;
    left: 9999px !important;
}

@mixin tlHidden() {
    position: fixed !important;
    bottom: 9999px !important;
    right: 9999px !important;
}

@mixin dHidden() {
    display: none;
}

@mixin oHidden() {
    opacity: 0;
}

@mixin vHidden() {
    visibility: hidden;
}

@mixin wHidden() {
    width: 0 !important;
    min-width: auto !important;
    overflow: hidden !important;
}

@mixin hHidden() {
    height: 0 !important;
    min-height: auto !important;
    padding: 0 !important;
    overflow: hidden !important;
}

@mixin cHidden() {
    content-visibility: hidden;
}

@mixin flex($justifyContent: center, $alignItems: center, $flexFlow: row wrap) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-flow: $flexFlow;
}

@mixin shadow($deltaX: 0, $deltaY: 0.2rem, $span: 0.5rem, $color: $colorGreyCottonHex) {
    box-shadow: $deltaX $deltaY $span $color;
}

// from left to right speeding inner light
@mixin loading() {
    background-color: lightgrey;
    overflow: hidden;
    position: relative;
    &:before {
        display: block;
        position: absolute;
        content: '';
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        animation: loading 0.5s linear infinite;
    }
}

// pulsating inner light
@mixin loading2() {
    background-color: lightgrey;
    overflow: hidden;
    position: relative;
    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: 100%;
        background-color: white;
        animation: loading2 1.4s linear infinite;
    }
}

@mixin scrollbar($width: 0.6rem, $color: $colorGreyCottonHex) {
    &::-webkit-scrollbar {
        width: $width !important;
        height: $width !important;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color !important;
        border-radius: 100px !important;
    }
}
