@keyframes b434e59b_826a_4848_8cf1_6b2bb7a33484 {
    from {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-20deg);
    }
    20% {
        transform: rotate(20deg);
    }
    30% {
        transform: rotate(-20deg);
    }
    40% {
        transform: rotate(20deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    60% {
        transform: rotate(20deg);
    }
    70% {
        transform: rotate(0deg);
    }
}

.menu {
    .-item {
        &.\/notifications {
            padding: 0;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
            }

            &.\/dring {
                svg {
                    transform-origin: 50% 20%;
                    animation-name: b434e59b_826a_4848_8cf1_6b2bb7a33484;
                    animation-duration: 2s;
                }
            }

            .-inner {
                position: relative;
                .-counter {
                    @include flex();
                    background: $colorRedIndianHex;
                    font-size: 70%;
                    border-radius: 5rem;
                    font-weight: bold;
                    text-align: center;
                    height: 1.2rem;
                    min-width: 1.2rem;
                    width: auto;
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-left: 0;
                    padding: 0.1rem 0.2rem;
                    color: white !important;
                    @media (max-width: $sm) {
                        position: relative;
                        margin-left: 0.5rem;
                    }
                }
                .-caption {
                    @media (min-width: $sm + 1) {
                        display: none;
                    }
                }
            }

            ~ .-menu {
                @include scrollbar();
                top: calc(100% + 0.8rem);
                right: -0.3rem;
                width: 24rem;
                padding: 0;
                max-height: 26rem;
                overflow-x: hidden;
                overflow-y: scroll;

                .user\:notification,
                .user\:notificationWaiter {
                    width: 100%;
                    margin-bottom: 0.2rem;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                .-noItems {
                    padding: 2rem 1rem;
                    text-align: center;
                }

                @media (max-width: $sm) {
                    width: 100%;
                    height: 20rem;
                    box-shadow: none;
                    &::-webkit-scrollbar-track {
                        background-color: rgba(0, 0, 0, 0.9);
                    }
                }
            }
        }
    }
}
