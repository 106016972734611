@import 'available';

@keyframes c3bddf88_9bde_4a30_a5b4_a636cd65e6d0 {
    from {
        transform: scale(1.3) translateX(-0.4rem);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.3) translateX(0.5rem);
        opacity: 1;
    }
    100% {
        transform: scale(1.3) translateX(-0.4rem);
        opacity: 0.7;
    }
}
@keyframes b1e00ff2_ede5_4e4b_99ec_db375830a4bb {
    from {
        transform: scale(1.3) translateY(-0.4rem);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.3) translateY(0.5rem);
        opacity: 1;
    }
    100% {
        transform: scale(1.3) translateY(-0.4rem);
        opacity: 0.7;
    }
}

.video\:studioDynamicZone {
    @include flex(center, stretch, row);
    position: relative;
    margin-bottom: 1rem;
    border-top: 3px rgba(0, 0, 0, 0.05) solid;
    padding-top: 0.8rem;
    &:not(.\/mode\/preview) {
        .-available {
            .-compo {
                &:hover,
                &:focus {
                    .-fog {
                        top: 0;
                        background: rgba(0, 0, 0, 0.2);
                        display: flex;
                    }

                    @media (min-width: $md + 1) {
                        z-index: 99999;
                    }
                    @media (max-width: $sm) {
                        transform: none;
                    }
                }
            }
        }
    }
    &.\/mode {
        &\/config {
            border-top: none;
            padding-top: 0;
            margin-bottom: 0;
            min-height: 15rem;
            &.\/animated {
                .-bar,
                .-adminCompos > .-buttons {
                    display: none;
                }
                .-adminCompos {
                    .video\:studioCompo {
                        animation-name: headShake;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                        transition: border 0s;
                        box-shadow: 0 0 0 0.4rem orange !important;
                        border-color: transparent !important;
                        .-description .-buttons {
                            display: none !important;
                        }
                    }
                }
            }
            &.\/noPrefill {
                min-height: initial;
                .video\:studioCompo .-description .-buttons .-prefill {
                    display: none;
                }
                .layout-pane {
                    &:first-of-type {
                        @include hidden();
                        width: 0;
                    }
                    &:last-of-type {
                        width: 100% !important;
                    }
                }
                .layout-splitter {
                    @include hidden();
                }
            }
            .video\:studioCompo .-description .-description .-move {
                display: none;
            }
            .-adminCompos {
                .video\:studioCompo {
                    border-style: solid;
                    border-color: mediumturquoise;
                    border-width: 0 0.4rem;
                    &:first-of-type {
                        border-top-width: 0.4rem;
                    }
                    &:last-of-type {
                        border-bottom-width: 0.4rem;
                    }
                }
            }
        }
    }
    &.\/error {
        .-noItems {
            border: 1px solid red;
            @media (max-width: $sm) {
                border-right-width: 0;
                border-left-width: 0;
            }
        }
        &:not(.\/empty) {
            [data-react-beautiful-dnd-droppable] {
                border: 1px solid red;
            }
        }
    }
    .video\:studioCompo {
        &.\/nonDraggable {
            margin-bottom: 1rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .splitter-layout {
        min-height: 100%;
        height: auto;
    }
    .layout-splitter {
        min-height: 100%;
        width: 0.4rem;
        background-color: transparent !important;
    }
    .layout-pane {
        display: flex;
    }
    .-bar {
        height: calc(100% - 3.6rem);
        min-height: calc(100% - 3.6rem);
        width: 0.4rem;
        background: mediumturquoise;
        position: absolute;
        left: 0;
        top: 3.5rem;
        border-radius: 50px;
    }
    .-adminCompos {
        width: 100%;
        > .-buttons {
            position: absolute;
            top: 0;
            left: 0;
            width: 0.4rem;
            height: 100%;
            padding: 1rem 0 1.5rem 0;
            z-index: 9;
            .-copy,
            .-move,
            .-prefill,
            .-destroy {
                &.\/off {
                    border-color: rgba(72, 209, 204, 0.5) !important;
                    color: rgba(72, 209, 204, 0.5) !important;
                    > .-shell {
                        opacity: 0.5;
                    }
                }
            }
            > .-shell {
                @include flex(flex-start, center, column);
                position: sticky;
                width: 0.4rem;
                top: 10rem;
                .tooltipBox {
                    @include flex();
                    position: relative;
                    margin-top: 0.4rem;
                    cursor: pointer;
                    background-color: white;
                    border: 0.1rem solid mediumturquoise;
                    color: mediumturquoise;
                    border-radius: 500rem;
                    width: 1.7rem;
                    height: 1.7rem;
                    > .-shell {
                        @include flex();
                    }
                }
            }
        }
    }
    .-errorFeedback {
        position: absolute;
        top: 1.2rem;
        left: 0.6rem;
        width: calc(100% - 10rem);
        font-size: 90%;
        color: $colorRedIndianHex;
        @media (max-width: $sm) {
            width: 100%;
            top: 1rem;
            left: 0;
            padding: 0 0.6rem;
            text-align: center;
        }
    }
    .-noItems {
        @include flex(center, center, column);
        width: 100%;
        min-height: 100%;
        padding: 2rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: $borderRadiusMd;
        > div {
            @include flex(center, center, column);
            position: sticky;
            top: calc(#{$headerHeight} + 2rem);
            bottom: 2rem;
        }
        strong {
            width: 100%;
            margin-bottom: 1rem;
        }
        svg {
            transform: scale(1.3);
            color: grey;
            animation-name: c3bddf88_9bde_4a30_a5b4_a636cd65e6d0;
            animation-iteration-count: infinite;
            animation-duration: 1s;
            > path {
                transform-origin: center;
                transform: rotate(-90deg);
                @media (max-width: $sm) {
                    transform: none !important;
                }
            }
            @media (max-width: $sm) {
                animation-name: b1e00ff2_ede5_4e4b_99ec_db375830a4bb;
            }
        }
        @media (max-width: $sm) {
            border-radius: 0;
        }
    }
    .-item {
        border-radius: $borderRadiusMd;
        transition: all 0s;
        width: 1px;
        flex-grow: 1;
        &.\/fullHeight {
            min-height: 100%;
        }
        &.\/draggingOver {
            background: ac7fc58d_20be_45c1_8b1d_554ad0c3eeef();
            transition: all 0.2s;
            .video\:studioCompo {
                transition: all 0.2s;
                &.\/nonDragged {
                    opacity: 0.6;
                    transform: scale(0.9);
                }
                [data-react-beautiful-dnd-draggable] {
                    margin-bottom: 1rem;
                }
            }
        }
        * {
            transition: initial;
        }
        [data-react-beautiful-dnd-draggable] {
            margin-bottom: 1rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .video\:studioCompo {
            transition: all 0s;
            @media (max-width: $md) {
                margin-bottom: 1rem;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: $sm) {
            width: 100%;
        }
    }
    @media (max-width: $sm) {
        flex-flow: column;
        align-items: center;
    }
}
