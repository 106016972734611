.connect\:user\:requestPasswordResetPage {
    @include fb2f896b_f808_47ee_9bf2_216cadc0488a();
    .-form {
        .-email {
            width: 100%;
            margin: 0.5rem;
        }
        .-submit {
            margin: 1.5rem 0.5rem 0.5rem 0.5rem;
            width: 100%;
        }
        > .input .-shell {
            width: 100%;
        }
        @media (max-width: $sm) {
            background-color: transparent;
        }
        @media (max-width: $xs) {
            padding: 2rem 0.5rem;
        }
    }
}
