.connect\:homePage {
    @include fb2f896b_f808_47ee_9bf2_216cadc0488a();
    .-form {
        .-email,
        .-password {
            width: 100%;
            margin: 0.5rem;
        }
        .-submit {
            margin: 1.5rem 0.5rem 0.5rem 0.5rem;
            width: 100%;
        }
        .-forgotPwd {
            width: 100%;
            text-align: right;
            font-size: 80%;
            padding: 0 1rem 1rem 1rem;
            a {
                @include discreetLink();
                cursor: pointer;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
        .-tfa {
            width: 100%;
            margin: 0.5rem;
            .-code {
                width: 100%;
            }
            .-tuto {
                @include flex(center, flex-start, row);
                padding: 0.2rem 0.6rem 1.4rem;
                transition: all 0s !important;
                * {
                    transition: all 0s !important;
                }
                .-steps {
                    @include flex(center, flex-begin, column wrap);
                    font-style: italic;
                    font-size: 90%;
                    padding-left: 0.4rem;
                    flex-grow: 1;
                    height: 200px;
                    span {
                        display: inline-block;
                        width: 100%;
                        margin: 0.5rem;
                    }
                    @media (max-width: $sm) {
                        display: none;
                    }
                }
            }
            .-firstTime {
                @include discreetLink();
                width: 100%;
                text-align: right;
                font-size: 80%;
                padding: 0.5rem;
                cursor: pointer;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                    @media (max-width: $sm) {
                        color: white;
                    }
                }
                @media (max-width: $sm) {
                    color: white;
                }
            }
        }
        .localeSwitcher {
            width: 100%;
            margin-top: 1.6rem;
        }
        > .input .-shell {
            width: 100%;
        }
        @media (max-width: $sm) {
            background-color: transparent;
            color: white;
        }
        @media (max-width: $xs) {
            padding: 2rem 0.5rem;
        }
    }
    .-registerSuggestion {
        width: 100%;
        margin-top: 1rem;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        color: white;
    }
}
