@import 'compos';
@import 'snippet/index';

.video\:studio {
    @include flex(center, flex-start, row wrap);
    position: relative;

    &.\/mode {
        &\/none {
            .-snippet {
                .-header {
                    .-title {
                        .-buttons {
                            position: absolute;
                            top: 0.3rem;
                            right: 0;
                        }
                    }
                }
            }
        }
        &\/light {
            .-snippet .-theme [class$='-menu'] [class$='-option']:last-child {
                display: none;
            }
        }
        &\/preview {
            .-snippet {
                padding-bottom: 0.6rem;
                .-theme {
                    label {
                        display: none;
                    }
                    [class*='-indicator'],
                    [class*='-placeholder'] {
                        display: none;
                    }
                    [class$='-control'] {
                        border: none;
                        > div {
                            @include flex();
                            padding: 0 !important;
                        }
                    }
                    .-itemShell {
                        .tooltipBox {
                            display: none;
                        }
                    }
                }
                .-header .-title .-buttons {
                    @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                    svg {
                        color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                    }
                    .-button {
                        &.\/refresh {
                            svg {
                                &:last-of-type {
                                    width: 1.1rem;
                                    color: orange;
                                    position: absolute;
                                    top: -0.8rem;
                                    right: -0.4rem;
                                    transform: rotateZ(25deg);
                                }
                            }
                        }
                    }
                }
            }
        }
        &\/config {
            .-snippet {
                margin: 2rem 2rem 2rem 0;
                padding-bottom: 0.6rem;
                .-music {
                    @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                }
            }
            .-compos {
                margin: 1rem 0;
            }
            .video\: {
                &studioCompo {
                    &.\/private {
                        display: block;
                        opacity: 0.2;
                        transition: opacity 0.3s;
                        &:hover,
                        &:focus {
                            opacity: initial;
                        }
                    }
                    .-chapters {
                        .-chapter {
                            .-inputs {
                                .-input {
                                    &.\/private {
                                        display: initial;
                                        opacity: 0.2;
                                        transition: opacity 0.3s;
                                        &:hover,
                                        &:focus {
                                            opacity: initial;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .or {
                width: 100% !important;
                margin-top: 0 !important;
                color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
                .-wing {
                    border-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
                }
            }
        }
    }

    &.\/waiting {
        .or,
        .-noItems {
            opacity: 0 !important;
        }
        > *:not(.-waiter) {
            opacity: 0.2;
            &.-snippet {
                background-color: lightgrey;
                transition: 0s background-color;
                * {
                    opacity: 0;
                }
            }
            &.-compos {
                background-color: lightgrey;
                border-radius: $borderRadiusMd;
                margin-top: 2rem;
                overflow: hidden;
                content-visibility: hidden;
            }
            &.-adminCompos {
                > .-bar,
                > .-buttons {
                    display: none;
                }
            }
            .tooltipBox {
                opacity: 0;
            }
            .video\: {
                &studioDynamicZone .-available {
                    opacity: 0;
                    .-compo {
                        background-color: lightgrey;
                        img {
                            opacity: 0;
                        }
                    }
                }
                &studioCompo {
                    border: none !important;
                    .-chapters .-chapter {
                        .-inputs {
                            > * {
                                position: relative;
                                &:before {
                                    content: '';
                                    background-color: lightgrey;
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    border-radius: $borderRadiusMd;
                                }
                                * {
                                    opacity: 0;
                                }
                            }
                        }
                        .-thumbnail {
                            opacity: 0;
                        }
                        .-meta {
                            > * {
                                opacity: 0;
                            }
                        }
                    }
                    .-description .-description .-text {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &.\/animated {
        .video\:studioDynamicZone .-buttons {
            display: none !important;
        }
    }

    .or {
        &.\/beforeGlobal {
            opacity: 0;
        }
    }

    .-waiter {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        border-radius: $borderRadiusMd;
        color: f962c53a_0958_46f7_94d5_d63d329ec14f();
        font-size: 120%;
        font-weight: $fontWeightLg;
        background-color: #f9f9f9;
        .-caption {
            position: sticky;
            top: 50vh;
            text-align: center;
            margin: 5rem 2.4rem;
        }
        .-caption-text {
            position: sticky;
            text-align: center;
            color: black;
            margin: 5rem 2.4rem;
        }
    }
}

.html.\/modalOpen {
    .video\:studio .-snippet .-buttons > .-shell.\/fixed {
        display: none;
    }
}
