.videoCard {
    position: relative;
    width: 20rem;
    height: 25rem;
    display: inline-block;
    margin-right: 3rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transition: all 0.2s ease-in-out;

    .absolute {
        border-radius: 1rem;
        height: 20rem;
    }

    video {
        height: 100%;
        border-radius: 1rem;
    }

    &:hover {
        box-shadow: none;
    }

    @media (max-width: 768px) {
        max-width: 17.5rem;
        max-height: 17.5rem;
    }
}
