.video\:itemPage {
    @include flex(center, flex-start, row wrap);
    &.\/spectator {
        @include flex(flex-start, center, column);
        .creditsBar {
            display: none;
        }
        > .-title {
            padding: 1rem;
            background: transparent;
            text-align: center;
            width: 100%;
            font-size: 130%;
            color: initial;
            font-weight: $fontWeightXl;
        }
        > .-snippet {
            background: transparent;
            > .-preview,
            > .-creator,
            > .-inputs,
            > .-buttons {
                display: none;
            }
        }
        > .-core {
            width: 50rem;
            margin-top: 0;
        }
    }
    > .-block,
    .creditsBar .-block {
        @include flex();
        flex-direction: row;

        > .-title {
            font-size: 130%;
            font-weight: $fontWeightXl;
            margin-right: 2rem;
        }

        > .-action {
            @include flex();
            flex-direction: row;

            > .-item {
                margin-right: 8px;
            }
        }
    }
    > .-full {
        @include flex(space-between, flex-start, row);
        width: 100%;

        > .-snippets {
            @include flex(space-between, flex-start, column);
            width: 30%;
            margin: 2.5rem 2.5rem 0 2.5rem;

            > .-item {
                margin-bottom: 2.5rem;
                width: 100%;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
                padding: 1rem;
                background-color: white;
                border-radius: $borderRadiusMd;
                > .-nav {
                    > .-item {
                        @include flex(flex-start, center, row);
                        cursor: pointer;
                        padding: 0.5rem;
                        margin-top: 5px;

                        > .-icon {
                            margin-right: 1rem;
                            font-weight: 'initial';
                            font-size: 'initial';
                            color: '#000';
                        }
                        > .-text {
                            color: black;
                            margin-bottom: 0;
                            margin-top: 0;
                        }
                    }
                    > .-item:hover {
                        border-radius: 5px;
                        background: linear-gradient(90deg, #49a4b3 1.32%, #4bcccc 52.45%, #52cdb7 98.35%);
                        > .-icon {
                            color: white;
                        }
                        > .-text {
                            color: white;
                        }
                    }
                }
                @media (max-width: $sm) {
                    @include flex(center, flex-start, column);
                    width: 100%;
                    margin: 1rem 0 0 0 !important;
                    border-radius: 0;
                }
                > .-title {
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
                > .-subTitle {
                    width: 100%;
                    font-size: 100%;
                    font-weight: $fontWeightMd;
                    padding-bottom: 1.4rem;
                }
                > .-loading {
                    width: 100%;
                    text-align: center;
                }
                > .-formats {
                    @include flex(center, center, column);
                    width: 100%;
                    margin-top: 2rem;
                    > .-item {
                        @include flex(center, center, column);
                        border-radius: 500rem;
                        color: white;
                        margin: 0.5rem;
                        > .-title {
                            @include flex(space-between, center, row);
                            > .-text {
                                font-size: 100%;
                                font-weight: $fontWeightLg;
                                color: black;
                            }
                            > .-help {
                                margin-left: 5px;
                                color: #9e9e9e;
                            }
                        }
                        > .-actions {
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            > .-in-progress {
                                color: black;
                            }
                        }
                    }
                }
            }

            @media (max-width: $sm) {
                @include flex(center, flex-start, column);
                width: 100%;
                margin: 1rem 0 0 0 !important;
                border-radius: 0;
            }
        }
        > .-core {
            @include flex(center, flex-start, column);
            width: 60%;
            margin: 2.5rem 0 2.5rem 2.5rem;

            > .-video {
                @include flex(center, center, row wrap);
                width: 100%;
                background-color: white;
                border-radius: $borderRadiusMd;
                padding: 2rem;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
                margin-bottom: 2.5rem;

                > .-preview {
                    width: 100%;
                    position: relative;
                }

                > .-note {
                    width: 100%;
                    margin-top: 2rem;

                    > .-title {
                        width: 50px;
                        color: black;
                        font-size: 120%;
                        font-weight: $fontWeightLg;
                        border-bottom: 1px #d3d3d3 solid;
                    }
                    > .-text {
                        color: black;
                        font-size: 100%;
                        margin-top: 1rem;
                    }
                }
                > .-title {
                    width: 100%;
                    text-align: center;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
                > .-buttons {
                    @include flex(center, flex-start, row);
                    width: 100%;
                    margin-top: 2rem;
                    .-button {
                        @include flex();
                        border-radius: 500rem;
                        color: white;
                        width: 3rem;
                        height: 3rem;
                        cursor: pointer;
                        margin: 0.5rem;
                        &:hover {
                            transform: scale(1.1);
                            color: white;
                            svg {
                                fill: white;
                            }
                        }
                        &.\/share {
                            background-color: #57c7d4;
                            svg {
                                fill: white;
                                * {
                                    fill: white;
                                }
                            }
                        }
                        &.\/download {
                            background-color: rgba(70, 190, 138, 1);
                            svg {
                                fill: white;
                                * {
                                    fill: white;
                                }
                            }
                        }
                        &.\/mail {
                            background-color: rgba(46, 204, 113, 1);
                            svg {
                                fill: white;
                                * {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
                > .-progress {
                    width: 100%;
                    height: 2rem;
                    border-radius: 500rem;
                    .bg-success {
                        background-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
                    }
                    &Caption {
                        @include flex(space-between, flex-start, row);
                        width: 100%;
                        padding: 1rem;
                        > .-state {
                            max-width: 60%;
                            &.\/done {
                                span:first-of-type {
                                    font-weight: bold;
                                    color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                                }
                            }
                        }
                        > .-time {
                            > .-clock {
                                font-weight: $fontWeightLg;
                                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                            }
                        }
                        @media (max-width: $sm) {
                            flex-flow: row wrap;
                        }
                    }
                }
                > .-ctas {
                    .-generateWithoutWatermark {
                        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                        margin: 1.6rem 0;
                        border-radius: 50px;
                        font-size: 110%;
                        padding: 0.3rem 1rem;
                        transition: transform 0.3s !important;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                > .-creator {
                    @include flex(center, center, row);
                    width: 100%;
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;

                    > .-name {
                        font-size: 16px;
                        font-weight: $fontWeightLg;
                    }
                    > .-date {
                        margin-left: 20px;
                        font-size: 15px;
                        font-weight: $fontWeightSm;
                    }
                }
                @media (max-width: $sm) {
                    width: 100%;
                }
            }
            > .-share {
                @include flex(flex-start, center, row wrap);
                width: 100%;
                background-color: white;
                border-radius: $borderRadiusMd;
                padding: 2rem;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
                margin-bottom: 2.5rem;

                > .-title {
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }

                > .-list-type {
                    @include flex(flex-start, center, column);
                    width: 100%;

                    > .-type {
                        width: 100%;
                        margin-bottom: 2rem;

                        > .-title {
                            @include flex(flex-start, center, row);
                            width: 100%;
                            font-size: 110%;
                            font-weight: $fontWeightLg;
                            padding-bottom: 1.4rem;

                            > .-icon {
                                font-weight: 'initial';
                                font-size: 'initial';
                            }

                            > .-text {
                                margin-left: 0.7rem;
                            }
                        }
                        > .-items {
                            width: 100%;
                        }
                    }
                }

                @media (max-width: $sm) {
                    width: 100%;
                    margin: 0;
                }
            }

            @media (max-width: $sm) {
                width: 100%;
                margin: 0;
            }
        }
        > .-creator {
            @include flex(center, center, row);
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 1rem;

            > .-name {
                font-size: 16px;
                font-weight: $fontWeightLg;
            }
            > .-date {
                margin-left: 20px;
                font-size: 15px;
                font-weight: $fontWeightSm;
            }
        }
        @media (max-width: $sm) {
            @include flex(center, flex-start, column);

            width: 100%;
            margin: 0;
            border-radius: 0;
        }
    }
}

.thumbnail {
    @include flex(flex-start, center, row);
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;

    @media (max-width: $sm) {
        @include flex(center, center, column);

        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    > .-video {
        width: 60%;

        @media (max-width: $sm) {
            margin-bottom: 3rem;
        }

        > video {
            flex: 0 0 auto;

            @media (max-width: $sm) {
                max-width: 100%;
            }
        }
    }
    > .-actions {
        flex: 1;
        @include flex(flex-start, center, column);
        > .-item {
            margin-bottom: 1rem;
            &.--capture {
                @include flex(flex-start, center, row);
            }
            &.--text {
                margin-bottom: 2rem;
                margin-top: 2rem;
                text-transform: uppercase;
                font-size: 110%;
            }
            > .-file-uploaded {
                @include flex(center, center, column);

                > .-text {
                    @include flex(space-between, center, row);
                    margin-bottom: 1rem;

                    > .-icon {
                        margin-right: 1rem;
                    }
                }
            }
            > .-file {
                @include flex(center, center, column);
                border: dashed 3px #e0e0e0;
                padding: 2rem;

                &:hover {
                    border: dashed 3px #3194a5;
                }
            }
        }
    }
}
