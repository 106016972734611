.workspace\:dangerZonePage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();
    .or {
        margin-top: 1.5rem;
        width: 30rem;
        color: lightslategray;
        @media (max-width: $sm) {
            margin-top: 0.8rem;
            width: 100%;
        }
    }
    > .-buttons {
        @include flex(center, center, row wrap);
        margin: 1rem 0.4rem;
        .-merge,
        .-remove {
            margin: 0.4rem;
        }
    }
}
