@mixin fb2f896b_f808_47ee_9bf2_216cadc0488a() {
    @include flex();
    position: relative;
    padding: 0 0 2rem;
    .-form {
        @include flex(center, flex-start, row wrap);
        width: 30rem;
        padding: 2rem;
        border-radius: $borderRadiusLg;
        background-color: white;
        transition: all 0s;
        .-title {
            width: 100%;
            text-align: center;
            padding-bottom: 1rem;
            font-size: 130%;
            font-weight: $fontWeightXl;
            color: color(2, $l: '*.2');
            @media (max-width: $sm) {
                color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            }
        }
    }
}
