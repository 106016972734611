.organization\:modal {
    &.\/cancelPlanConfirm {
        .-content {
            @include flex(center, center, row wrap);
            width: 100%;
            .-explanation {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
    &.\/settleTimeBomb {
        .-content {
            @include flex();
            width: 100%;
            .-explanation {
                width: 100%;
                margin-bottom: 0.8rem;
            }
            .-confirm {
                width: 100%;
                margin-bottom: 0.6rem;
            }
            .-submit {
                margin-top: 1rem;
            }
        }
    }
}
