.user\:notificationWaiter {
    @include flex(flex-start, flex-start, row wrap);
    background-color: white;
    width: 20rem;
    padding: 1rem 4rem 1rem 1rem;
    .-strip {
        @include loading2();
        width: 100%;
        height: 1.3rem;
        border-radius: $borderRadiusMd;
        margin-bottom: 0.4rem;
        &:last-of-type {
            width: 80%;
            margin-bottom: 0;
        }
    }
    @media (max-width: $md) {
        background-color: initial;
    }
}
