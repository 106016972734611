@mixin ca64ead0_6994_48d1_b530_1779a4ccb5f6($prefix: '') {
    #{$prefix}.tooltipBox {
        @include flex();
        margin-left: 0.4rem;
        cursor: pointer;
        width: 1.7rem;
        height: 1.7rem;
        > .-shell {
            @include flex();
            width: 100%;
            height: 100%;
            background-color: white;
            border: 0.1rem solid f962c53a_0958_46f7_94d5_d63d329ec14f();
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            transition: color 0s, border 0s;
            border-radius: 500rem;
        }
    }
}
