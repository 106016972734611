@import '~react-splitter-layout/lib/index.css';

.splitter {
    &-layout {
        position: relative;
        transition: all 0s !important;
        overflow: initial;
        .layout-pane {
            overflow: initial;
            transition: all 0s !important;
        }
    }
}
