.user\:modal {
    &.\/licenseNeededAmongTheAvailableOnes {
        .-content {
            @include flex(center, center, row wrap);
            width: 100%;

            .-submit {
                margin-top: 1.2rem;
            }

            .-redirect {
                width: 100%;
                text-align: center;
                margin-top: 1rem;
            }
        }
    }

    &.\/paymentIntentsStatus {
        .-content {
            [role='button'] {
                padding-bottom: 1rem;
            }

            .-error {
                margin-bottom: 0.8rem;
                border-radius: 0.4rem;
                padding: 0.8rem 1.1rem;
                background: #fbd0d4;
                color: $colorRedIndianHex;
                @media (max-width: $sm) {
                    margin-left: 2rem;
                    margin-right: 2rem;
                }
            }

            .-success {
                margin-bottom: 0.8rem;
                border-radius: 0.4rem;
                padding: 0.8rem 1.1rem;
                background: #d0fbd0;
                color: $colorGreenLeafRgb;
                @media (max-width: $sm) {
                    margin-left: 2rem;
                    margin-right: 2rem;
                }
            }

            .-warning {
                margin-bottom: 0.8rem;
                border-radius: 0.4rem;
                padding: 0.8rem 1.1rem;
                background: #fbddd0;
                color: #b07941;
                @media (max-width: $sm) {
                    margin-left: 2rem;
                    margin-right: 2rem;
                }
            }

            .-redirect {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 1rem;
                cursor: pointer;
            }

            .-insideLink {
                color: #007bff;
                cursor: pointer;
            }
        }
    }

    &.\/checkout {
        .-content {
            @include flex(center, center, row wrap);
            width: 100%;

            .-submit {
                margin-top: 1rem;
            }

            .-exceeding {
                width: 100%;
                color: slategray;

                .-beware {
                    margin-bottom: 0.6rem;
                }

                .-listItem {
                    margin-right: 0.4rem;
                    font-style: italic;
                }

                .-users,
                .-workspaces {
                    margin: 0.3rem 0;
                }

                .-showAll {
                    > span {
                        color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                        font-size: 90%;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-left: 0.3rem;
                    }
                }

                .-do {
                    margin-top: 0.6rem;
                }
            }
        }
    }

    &.\/newTermsAgreement {
        .-dialog {
            width: 60rem;
            overflow: hidden;
        }

        .-content {
            @include flex();
            width: 100%;
            overflow: hidden;

            iframe {
                width: 100%;
                height: 65vh;
                @media (max-width: $sm) {
                    height: 70vh;
                }
            }

            button {
                margin-top: 1.2rem;
            }
        }
    }

    &.\/lock {
        .-content {
            @include flex();
            width: 100%;

            .nextDirector,
            .-confirm {
                width: 100%;
                margin-bottom: 0.6rem;
            }

            .-submit {
                margin-top: 1rem;
            }
        }
    }
}
