.rc-slider {
    &-track {
        background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614() !important;
    }
    &-handle {
        border-color: var(--color-1-hsl) !important;
        &-dragging {
            border-color: var(--color-1-hsl) !important;
            box-shadow: 0 0 0 5px var(--color-1-hsl) !important;
        }
    }
}

.dnm-video-cut {
    &-root {
        &.audio {
            width: 50vw;
            @media (max-width: $sm) {
                width: 100%;
            }
        }
        transition: all 0s !important;
        * {
            transition: all 0s !important;
        }
    }
    &-loop-icon,
    &-play-icon,
    &-in-icon,
    &-out-icon {
        background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614() !important;
    }
    &-play-icon {
        svg {
            top: 1px !important;
        }
    }
    &-checkmark {
        &:after {
            left: 10px !important;
            top: 7px !important;
        }
        input:checked ~ & {
            background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614() !important;
        }
    }
    &-progress-scrollable-parent {
        @include scrollbar();
    }
    &-mute {
        @include flex(center, center, row);
        label {
            @include flex(center, center, row);
            font-size: 90% !important;
        }
    }
    &-too-short-warning {
        border-radius: $borderRadiusMd;
        padding: 0.8rem;
        background-color: $colorOrangeNoteBackgroundHex;
        color: $colorOrangeNoteTextHex;
    }
}
