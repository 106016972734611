.video\:modal {
    &.\/fullscreen {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        width: 100%;

        &.\/image {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &.\/copy-to-local {
        .input {
            width: 100%;
        }
    }
}
