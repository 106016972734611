.datePicker {
    width: 100%;
    display: inline-block;
    transition: all 0s !important;
    * {
        transition: all 0s !important;
    }
    &.\/warning {
        .-datePicker {
            border-color: darkorange;
        }
        .-feedback {
            color: darkorange;
        }
    }
    &.\/error {
        .-datePicker {
            border-color: red;
        }
        .-feedback {
            color: $colorRedIndianHex;
        }
    }
    .-datePicker {
        &Shell {
            @include flex(center, stretch, row);
        }
    }
    .-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
    }
}
