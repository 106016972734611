.video\:sharePage {
    @include flex(center, flex-start, row wrap);
    &.\/spectator {
        @include flex(flex-start, center, column);
        .creditsBar {
            display: none;
        }
        > .-title {
            padding: 1rem;
            background: transparent;
            text-align: center;
            width: 100%;
            font-size: 130%;
            color: initial;
            font-weight: $fontWeightXl;
        }
        > .-snippet {
            background: transparent;
            > .-preview,
            > .-creator,
            > .-inputs,
            > .-buttons {
                display: none;
            }
        }
        > .-core {
            width: 50rem;
            margin-top: 0;
        }
    }
    > .-block,
    .creditsBar .-block {
        @include flex();
        flex-direction: row;

        > .-title {
            margin-right: 2rem;

            a {
                background: transparent;
                color: $colorGreySlateHex;
                font-size: 130%;
                font-weight: $fontWeightXl;
                &:hover {
                    color: white;
                    @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                    border-radius: $borderRadiusMd;
                }
            }
        }

        > .-action {
            @include flex();
            flex-direction: row;

            > .-item {
                margin-right: 8px;
            }
        }
    }
    > .-full {
        @include flex(center, flex-start, row);
        width: 100%;

        > .-snippets {
            @include flex(space-between, flex-start, column);
            width: 35%;
            margin: 2.5rem 0 0 2.5rem;

            > .-item {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
                padding: 1rem;
                background-color: white;
                border-radius: $borderRadiusMd;
                width: 100%;

                @media (max-width: $sm) {
                    @include flex(center, flex-start, column);
                    width: 100%;
                    margin: 1rem 0 0 0 !important;
                    border-radius: 0;
                }
                > .-content {
                    @include flex(center, center, column);
                    width: 100%;

                    > .-selectFormat {
                        align-self: flex-start;
                        width: 50%;
                        margin-bottom: 1rem;
                    }
                    > .-select-types {
                        @include flex(flex-start, center, row);
                        margin-bottom: 2rem;
                        width: 100%;

                        > .-item {
                            @include flex(flex-start, center, row);
                            cursor: pointer;
                            margin-left: 1rem;
                            text-transform: uppercase;
                            padding: 0.5rem 0.5rem 0.5rem 0.5rem;

                            &.--active {
                                border-bottom: 2px solid $color1Hex;
                                background: #f9f9f9;
                            }
                        }
                    }
                    > .-skeletonHeader {
                        @include flex(flex-start, center, row);
                        width: 100%;
                    }
                    > .-descriptionByNetwork {
                        width: 100%;
                        > .-description {
                            margin-top: 1rem;
                        }
                    }
                    > .-titleByNetwork {
                        width: 100%;
                        padding-bottom: 1.4rem;
                    }

                    > .-video {
                        margin-top: 1rem;
                    }
                }
                > .-subTitle {
                    width: 100%;
                    font-size: 100%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
            }
        }
        > .-core {
            @include flex(flex-start, center, row);
            margin: 2.5rem 0 2.5rem 0;
            background-color: white;
            border-radius: $borderRadiusMd;
            width: 60%;
            padding: 1rem;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;

            > .-step {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            > .-title {
                width: 100%;
                font-size: 120%;
                font-weight: $fontWeightLg;
                padding-bottom: 1.4rem;
            }
            @media (max-width: $sm) {
                width: 100%;
                margin: 1rem 0 0;
            }
        }
        @media (max-width: $sm) {
            @include flex(center, flex-start, column);

            width: 100%;
            margin: 0;
            border-radius: 0;
        }
    }
    .tags {
        @include flex(flex-start, center, row);
        > .-tag-icons {
            @include flex(flex-start, center, row);
            margin-right: 1rem;
            > .-item {
                margin-right: 0.5rem;
            }
        }
        > .-tag-labels {
            @include flex(flex-start, center, row);
            margin-right: 1rem;
            > .-item {
                margin-right: 0.5rem;
            }
        }
    }

    .networks-switch {
        @include flex(flex-start, center, row);
        margin-bottom: 1.5rem;
        border-bottom: solid 2px $colorGreyCottonHex;
        width: 100%;

        > .-item {
            @include flex(flex-start, center, row);
            cursor: pointer;
            margin-right: 0.5rem;

            > .-show-label {
                @include flex(center, center, row);
                margin-left: 1rem;
                margin-right: 1rem;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;

                > .-icon {
                    > .svg-inline--fa {
                        height: 24px !important;
                    }
                }
                > .-label {
                    display: none;
                }
                &.--active {
                    > .-icon {
                        margin-right: 1rem;
                    }
                    > .-label {
                        display: block;
                    }
                }
            }
        }
    }
    .stepper-networks {
        margin-left: 0.6rem;
        > .-title {
            width: 100%;
            font-size: 120%;
            font-weight: $fontWeightLg;
            padding-bottom: 1.4rem;
        }
    }
    .stepper-publication {
        margin-left: 0.6rem;
        > .-title {
            width: 100%;
            font-size: 120%;
            font-weight: $fontWeightLg;
            padding-bottom: 1.4rem;
        }
        > .-form {
            > .-title {
                margin-top: 1rem;
                width: 100%;
                font-size: 120%;
                font-weight: $fontWeightLg;
                padding-bottom: 1.4rem;
            }
            > .-switch-networks {
                margin-bottom: 1.4rem;
            }
            > .-type {
                > .-select-type {
                    @include flex(flex-start, center, row);
                }
                > .-title {
                    margin-top: 1rem;
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
            }
            > .-description {
                > .-title {
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                }
                > .-title-video {
                    margin-bottom: 2rem;
                    width: 100%;
                }
                > .-textarea {
                    > .-description-youtube {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                        color: #cc3633;
                    }
                }
                > .-playlist {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    width: 100%;
                }
            }
        }
    }
    .stepper-send {
        margin-left: 0.6rem;
        > .-title {
            width: 100%;
            font-size: 120%;
            font-weight: $fontWeightLg;
            padding-bottom: 1.4rem;
        }
        > .-form {
            > .-privacy {
                margin-top: 0.5rem;
                > .-title {
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
                > .-allow-users-to {
                    > .-title {
                        width: 100%;
                        font-size: 100%;
                        font-weight: $fontWeightLg;
                    }
                    margin-top: 1rem;
                }
                > .-disclose-video-content {
                    margin-top: 1rem;
                    > .-title {
                        @include flex(flex-start, center, row);
                        width: 100%;
                        font-size: 100%;
                        font-weight: $fontWeightLg;
                    }
                    > p {
                        width: 100%;
                        font-size: 80%;
                        font-weight: $fontWeightMd;
                    }
                    > .-for-my-brand {
                        margin-top: 1rem;
                        > .-title {
                            @include flex(flex-start, center, row);
                            width: 100%;
                            font-size: 100%;
                            font-weight: $fontWeightLg;
                        }
                        > p {
                            width: 100%;
                            font-size: 80%;
                            font-weight: $fontWeightMd;
                        }
                    }
                    > .-for-third-brand {
                        margin-top: 1rem;
                        > .-title {
                            @include flex(flex-start, center, row);
                            width: 100%;
                            font-size: 100%;
                            font-weight: $fontWeightLg;
                        }
                        > p {
                            width: 100%;
                            font-size: 80%;
                            font-weight: $fontWeightMd;
                        }
                    }
                }
            }
            > .-publication {
                margin-top: 0.5rem;
                > .-title {
                    margin-top: 2rem;
                    width: 100%;
                    font-size: 120%;
                    font-weight: $fontWeightLg;
                    padding-bottom: 1.4rem;
                }
                > .-picker {
                    margin-top: 1rem;
                }
            }
        }
    }
    .-text-error {
        color: #ff0000;
        font-weight: $fontWeightLg;
    }

    .-formats {
        opacity: 0.7;
    }
}
