.creditsBar {
    @include flex(space-between, stretch, row);
    width: 100%;
    background-color: white;
    padding: 1.2rem 2rem;

    @media (max-width: $sm) {
        padding: 1rem;
        flex-flow: row wrap;
    }

    a {
        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
        padding: 0.1rem 0.6rem;
        border-radius: $borderRadiusMd;
        &:hover,
        &:focus {
            color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
            text-decoration: none;
        }
    }

    .-register {
        position: fixed;
        top: calc(#{$headerHeight} / 2);
        transform: translateY(-50%);
        right: 1rem;
        z-index: $zIndexHeader + 10;
    }

    .-caption {
        @include flex(center, center, row);
        .-texts {
            @include flex(center, center, row wrap);
            color: grey;
            font-size: 90%;
            .-text {
                display: inline-block;
                width: 100%;
                text-align: right;
                &.\/credits {
                    strong {
                        color: $colorGreenLeafRgb;
                    }
                }
                &.\/storage {
                    strong {
                        color: cornflowerblue;
                    }
                }
                &.\/low {
                    strong {
                        color: darkorange !important;
                    }
                }
                &.\/none {
                    strong {
                        color: $colorRedIndianHex !important;
                    }
                }
            }
        }
        .-progresses {
            @include flex(center, center, row wrap);
            max-width: 10rem;
            margin-left: 1rem;
            .-progress {
                display: inline-block;
                margin: 0.3rem 0;
                height: 0.6rem;
                width: 10rem;
                background-color: rgba(0, 0, 0, 0.08);
                &.\/credits {
                    .progress-bar {
                        background-color: $colorGreenGrassHex !important;
                    }
                }
                &.\/storage {
                    .progress-bar {
                        background-color: cornflowerblue !important;
                    }
                }
                &.\/low {
                    .progress-bar {
                        background-color: darkorange !important;
                    }
                }
                &.\/none {
                    .progress-bar {
                        background-color: $colorRedIndianHex !important;
                    }
                }
                .progress-bar {
                    height: 100%;
                }
            }
        }
        .-buy {
            margin-left: 1rem;
        }
        @media (max-width: $sm) {
            width: 100%;
            flex-flow: row wrap;
        }
    }
}
