.teamPage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();
    @include aecf8dbe_5e54_4dfb_99b3_ca43f3a588f4();

    .-creditsBar {
        margin-bottom: 1.2rem;
        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .or {
        margin-top: 1.5rem;
        width: 30rem;
        color: lightslategray;
        @media (max-width: $sm) {
            margin-top: 0.8rem;
            width: 100%;
        }
    }

    @media (max-width: $sm) {
        padding: 1.2rem 1rem 2rem;
    }
}
