.template\:modal {
    &.\/configRender {
        .-content {
            @include flex();
            width: 100%;
            .-state {
                .-valid {
                    strong {
                        color: $colorGreenGrassHex;
                    }
                }
                .-error {
                    strong {
                        color: $colorRedIndianHex;
                    }
                }
                .-test {
                    strong {
                        color: cornflowerblue;
                    }
                }
            }
            .-default {
                label {
                    color: $colorGreySlateHex;
                }
                .select {
                    width: 100%;
                }
                .-note {
                    font-size: 90%;
                    margin-top: 0.8rem;
                    background-color: $colorBlueNoteBackgroundHex;
                    padding: 1rem;
                    border-radius: $borderRadiusMd;
                    color: $colorBlueNoteTextHex;
                    strong {
                        color: $colorBlueNoteStrongTextHex;
                    }
                }
            }
            .or {
                margin: 1.4rem 0;
            }
        }
    }
}
