@mixin ae4ed70f_b09b_42d8_aec5_396e40aa14b1() {
    > * {
        transition: all 0s;
        @media (max-width: $sm) {
            padding: 0.4rem 0;
        }
    }
}

@mixin fc95b5d7_de7d_40c7_b807_c3a3208cdfcb() {
    @include flex(space-between, center, column);
    height: 1.4rem;
    width: 1.4rem;
    position: fixed;
    top: 1.1rem;
    transition: all 0s !important;
    div {
        width: 100%;
        height: 0.25rem;
        background-color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
        border-radius: 100px;
    }
    @media (min-width: $sm + 1) {
        display: none;
    }
}

@function e5e263d3_69e2_45fd_aa5f_7bc8915cb614() {
    @return linear-gradient(
        to right,
        var(--color-1-hsl),
        color(
            1,
            $h: calc(var(--color-1-h) - var(--color-1-default) * 30),
            $l: calc(var(--color-1-l) + 10% * ((-1 * var(--color-1-light)) + (1 * var(--color-1-dark))))
        )
    );
}

@function fa06f195_a6c3_4d86_9334_79323bbe5ce3() {
    @return color(1, $l: calc(var(--color-1-dark) * #{colorL(1, 100%)} + var(--color-1-light) * #{colorL(1, 20%)}));
}

@mixin b127860c_c45c_4f23_834e_24d4f8b67f1a() {
    background: e5e263d3_69e2_45fd_aa5f_7bc8915cb614();
    color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
}
