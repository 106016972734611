.user\:modal {
    &.\/emailSender {
        .-content {
            @include flex(center, center, row wrap);
            width: 100%;

            > span {
                text-align: center;
                display: inline-block;
                width: 100%;
            }

            button {
                margin-top: 1.4rem;
            }

            .-users {
                padding: 1rem 0.8rem 2rem;
                position: relative;

                &:after {
                    content: '';
                    width: 90%;
                    height: 0;
                    position: absolute;
                    bottom: -0.6rem;
                    left: 5%;
                    box-shadow: 0 -1.8rem 1.2rem 1rem white;
                }

                &.\/all {
                    &:after {
                        display: none;
                    }
                }

                .-showAll {
                    font-size: 80%;
                    cursor: pointer;
                    position: absolute;
                    bottom: -1rem;
                    right: 0;
                    font-style: italic;
                    color: grey;
                }
            }
        }
    }

    &.\/signature {
        .-content {
            width: 100%;
        }

        .-form {
            @include flex(center, center, column wrap);
            border-radius: $borderRadiusMd;
            width: 100%;
            max-width: 50rem;

            .-name,
            .-signature,
            .-location {
                width: 100%;
                margin-bottom: 0.7rem;
            }

            label {
                width: 100%;
            }

            .-submit {
                margin-top: 1rem;
            }

            .-inputs {
                @include flex(center, center, column);
                width: 100%;
                padding: 1rem;
                position: relative;
                max-width: 50rem;
            }
        }
    }
}
