.bo\:template\:modal {
    &.\/configBuilder {
        .-content {
            @include flex(center, flex-start, row wrap);
            width: 100%;
            .-inputs {
                width: 100%;
                > .-input {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
            .-submit {
                margin-top: 0.4rem;
            }
        }
    }

    &.\/preview {
        .-dialog {
            max-width: calc(100% - 10rem);
            width: calc(100% - 10rem);

            .-contentShell {
                padding: 0;
            }

            .video\:studio {
                .-snippet {
                    top: -2rem;
                }
            }

            .video\:studioCompo .-chapters .-chapter .-thumbnail .-shell {
                top: -2rem;
            }

            .video\:studioDynamicZone .-available {
                > div {
                    top: -2rem;
                }
            }
        }
        .-content {
            width: 100%;
            .video\:studio {
                width: 100%;
            }
        }
    }
}
