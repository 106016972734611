.slider {
    position: relative;
    margin-right: -1rem;

    .-controls {
        position: absolute;
        right: 0;
        margin-top: -4rem;
        display: flex;
    }

    .-container {
        position: relative;
        margin-right: -1rem;
        overflow-y: auto !important;
        overflow-x: scroll !important;
        overscroll-behavior-x: contain !important;
        white-space: nowrap !important;
        padding: 0.5rem;
        -ms-overflow-style: none !important;
        scrollbar-width: none !important;

        .-skeletonItem {
            display: inline-block;
            margin-right: 3rem;
            width: 20rem;
            height: 20rem;
            min-width: 20rem;
            animation: fade-out 0.25s infinite;
            @media (max-width: 768px) {
                min-width: 17.5rem;
                width: 17.5rem;
                height: 17.5rem;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
