.video\:studioDynamicZone {
    .-available {
        @include scrollbar();
        margin: 0 0 0 1rem;
        position: relative;
        &Quicklook {
            position: fixed;
            right: 11.5rem;
            background: rgba(0, 0, 0, 0.7);
            padding: 1rem;
            border-radius: $borderRadiusMd;
            z-index: 99999999;
            .video\:studioCompo {
                width: 60vw;
                z-index: 99999999;
            }
        }
        > div {
            @include scrollbar($width: 0.3rem, $color: grey);
            position: sticky;
            top: calc(#{$headerHeight} + 2rem);
            max-height: 80vh;
            padding-right: 0.2rem;
            overflow-y: scroll;
            @media (max-width: $sm) {
                @include flex(center, center, column wrap);
                height: 7rem;
                max-height: initial;
                overflow-y: hidden;
                width: 100%;
                top: 0;
                margin-left: 0;
                margin-top: 1rem;
                padding-right: 0;
                position: relative;
                overflow-x: scroll;
            }
        }
        .-moreShadow {
            display: inline-block;
            width: 100%;
            box-shadow: 0 0 1rem 1rem $colorGreyCloudHex;
            height: 0;
            position: sticky;
            bottom: 0;
            left: 0;
            @media (max-width: $sm) {
                display: none;
            }
        }
        .-compo {
            position: relative;
            border-radius: $borderRadiusMd;
            width: 8rem;
            height: auto;
            cursor: pointer;
            margin-bottom: 0.8rem;
            box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
            background-color: lightgrey;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                border-radius: $borderRadiusMd;
                @media (max-width: $sm) {
                    height: 100%;
                    width: auto;
                    position: relative;
                }
            }
            > .-tooltip {
                width: 60vw;
                padding: 1rem;
                text-align: initial;
                color: initial;
            }
            .-padder {
                @media (max-width: $sm) {
                    display: none;
                }
            }
            .-buttons {
                @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
                position: absolute;
                top: 0.4rem;
                right: 0.4rem;
                .-button {
                    &.\/refresh {
                        transition: all 0s;
                        &.\/on {
                            cursor: initial;
                            > .-shell {
                                animation-name: flash;
                                animation-iteration-count: infinite;
                                animation-duration: 1.4s;
                                animation-timing-function: linear;
                            }
                            &.\/definitelyOn {
                                cursor: pointer;
                                > .-shell {
                                    border-color: white !important;
                                    color: white !important;
                                    background-color: rgba(0, 0, 0, 0.5) !important;
                                    animation-name: fd59274a_35cf_4e06_9f1d_73fbbcaddb5b;
                                    animation-iteration-count: infinite;
                                    animation-duration: 2s;
                                    animation-timing-function: linear;
                                }
                            }
                        }
                        * {
                            transition: all 0s;
                        }
                    }
                }
            }
            .-fog {
                @include flex();
                transition: all 0s;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 100%;
                left: 0;
                color: white;
                background: transparent;
                user-select: none;
                display: none;
            }
            @media (max-width: $sm) {
                width: auto;
                height: 100%;
                margin-bottom: 0;
                margin-right: 1rem;
                box-shadow: none;
            }
        }
        @media (max-width: $sm) {
            @include flex(center, center, column wrap);
            margin: 0;
            width: calc(100% - 2 * 1rem);
        }
    }
}
