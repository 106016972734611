.dealSummary {
    padding: 0.3rem 0.6rem;
    border-radius: $borderRadiusMd;
    background-color: $colorBlueNoteBackgroundHex;
    color: $colorBlueNoteTextHex;
    font-style: italic;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: left;

    > * {
        display: inline-block;
        width: 100%;
    }

    strong {
        color: $colorBlueNoteStrongTextHex;
    }

    .-noMore {
        text-decoration: line-through;
    }

    .-chatbot {
        text-decoration: underline;
        cursor: pointer;
    }
}
