.menu {
    .-addWorkspaceCore {
        @include flex(flex-start);
        position: absolute;
        top: 50%;
        left: 1.4rem;
        transform: translateY(-50%);
        padding: 0 !important;
        cursor: pointer;
        svg {
            margin-right: 0.3rem;
        }
        span {
            @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
            padding: 0.1rem 0.4rem;
            border-radius: $borderRadiusMd;
        }

        @media (max-width: $sm) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            margin-left: -0.2rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
        }
    }

    .-workspaceBox {
        @include flex(flex-start);
        position: absolute;
        top: 50%;
        left: 1.4rem;
        transform: translateY(-50%);

        &:after {
            position: relative;
            top: 0.14rem;
            left: 0.14rem;
        }

        .-toggle {
            @include flex(flex-start, center, row);
            color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();

            &:hover,
            &:focus {
                color: e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
            }
        }

        .-menu {
            @include scrollbar();
            top: calc(100% + 0.8rem);
            max-height: 20.8rem;
            overflow-y: scroll;

            .-item {
                cursor: pointer;
                padding: 0.6rem 1rem;
                margin-left: 0;

                color: initial;

                &:hover,
                &:focus {
                    color: initial;
                }

                .-addWorkspaceCore {
                    @include flex(flex-start, center, row);
                    width: 100%;
                    height: 100%;
                    margin-left: 0;
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: none;

                    span {
                        font-style: italic;
                    }
                }

                .-shell {
                    @include flex(flex-start, center, row);
                    width: 100%;
                    height: 100%;
                }
            }

            @media (max-width: $sm) {
                max-width: 100%;
                width: 100%;
                max-height: 13.7rem;
                &::-webkit-scrollbar-track {
                    background-color: rgba(0, 0, 0, 0.9);
                }
            }
        }

        .-notificationsCount {
            @include flex();
            background: red;
            font-size: 70%;
            border-radius: 5rem;
            text-align: center;
            height: 1.3rem;
            min-width: 1.3rem;
            position: absolute;
            top: -0.6rem;
            right: -0.5rem;
            line-height: normal;
            color: white !important;
            z-index: 4;
            transition: all 0s;
        }

        .-notificationsDot {
            @include flex();
            background: red;
            border-radius: 5rem;
            height: 0.8rem;
            width: 0.8rem;
            position: absolute;
            top: -0.2rem;
            right: -0.2rem;
            z-index: 4;
            transition: all 0s;

            span {
                background-color: white;
                border-radius: 5rem;
                height: 20%;
                width: 20%;
            }
        }

        .-perspective {
            position: relative;
            left: 0.2rem;
            width: 2rem;
            height: 2rem;
            margin-right: 0.9rem;
            transition: all 0s;
            .-placeholder {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                background-color: #0b7fc7;
                border: 1px solid e6f934e0_3cd3_406a_99f8_9f51dbd7bbe6();
                border-radius: 50px;
                position: absolute;
                opacity: 0.5;
                transition: all 0s;
                &:nth-of-type(1) {
                    background-color: limegreen;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }
                &:nth-of-type(2) {
                    background-color: darkturquoise;
                    bottom: 0.06rem;
                    right: 0.26rem;
                    z-index: 2;
                }
                &:nth-of-type(3) {
                    background-color: blue;
                    bottom: 0.12rem;
                    right: 0.52rem;
                    z-index: 1;
                }
            }
        }

        .-logo {
            @include flex();
            position: relative;
            border-radius: 500rem;
            width: 2rem;
            height: 2rem;
            margin-right: 0.8rem;
            text-transform: uppercase;
            color: white;
            transition: all 0s;

            @media (max-width: $sm) {
                margin-left: 0 !important;
            }
        }

        @media (max-width: $sm) {
            position: initial;
            top: initial;
            left: initial;
            transform: none;
        }
    }
}
