.video\:modal {
    &.\/searchFootage {
        .-dialog {
            width: 50rem;
        }

        .-content {
            @include flex();
            width: 100%;
            position: relative;
            max-height: 40rem;
            overflow: hidden;

            > * {
                width: 100%;
            }

            > .-header {
                @include flex(space-between, flex-start);
                width: 100%;

                > .-search {
                    flex-grow: 1;
                }

                > .-type {
                    @include flex();
                    margin-left: 1rem;

                    > * {
                        transition: all 0s !important;
                        width: 33%;
                        min-width: 5rem;
                        text-align: center;
                        background-color: #0a0a0a;
                        color: white;
                        padding: 0.3rem 0.2rem;
                        cursor: pointer;
                        border: 1px solid white;
                        background: linear-gradient(45deg, $colorGreySlateHex, $colorGreySpaceHex);

                        &.\/active {
                            @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                        }

                        &:first-of-type {
                            border-top-left-radius: 500px;
                            border-bottom-left-radius: 500px;
                        }

                        &:last-of-type {
                            border-top-right-radius: 500px;
                            border-bottom-right-radius: 500px;
                        }
                    }

                    @media (max-width: $sm) {
                        width: 100%;
                        margin-top: 1rem;
                        margin-left: 0;
                    }
                }
            }

            .-loader {
                @include flex();
                width: 100%;
                padding: 2rem;
                min-height: 100vh;

                .animation {
                    position: fixed;
                    top: 48%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 9999999;

                    .-dot {
                        border: 0.14rem solid white;
                    }
                }
            }

            .-by {
                @include flex(flex-end);
                margin-top: 0.4rem;
                position: absolute;
                background-color: white;
                bottom: 0;
                left: 0;
                width: 100%;
                @media (max-width: $sm) {
                    position: fixed;
                    padding-bottom: 0.4rem;
                }
            }

            .dnm-rsfe {
                &-root {
                    margin-top: 1.2rem;
                    padding-bottom: 160px;
                    text-align: center;
                }

                &-credits {
                    display: none;
                }

                &-card {
                    transition: all 0s !important;

                    &-content {
                        width: 100%;

                        * {
                            color: white !important;
                            font-size: 80%;
                        }
                    }
                }
            }

            @media (max-width: $sm) {
                overflow: initial;
                max-height: initial;
            }
        }
    }

    &.\/waitingUploadVideo {
        .-dialog {
            width: auto;
        }

        .-content {
            @include flex(flex-start, flex-start, column);
            transition: all 0s !important;
            width: 100%;
        }

        .-progress {
            width: 100%;
        }

        .content-processing {
            @include flex(flex-start, flex-start, row);
            transition: all 0s !important;
            width: 100%;
        }

        @media (max-width: $sm) {
            @include flex();
            padding: 1rem 0 2rem 0;
            background-color: transparent;
        }
    }

    &.\/crop,
    &.\/cut {
        z-index: 1301;

        .-dialog {
            width: auto;
        }

        .-contentShell {
            height: 80vh;
            overflow-y: scroll;
        }

        // we remove the height for the music
        .-contentShell:not(.-content .music) {
            height: auto;
        }

        .-content {
            text-align: center; // for image align
            transition: all 0s !important;
            width: 100%;

            &.portraitFit {
                width: 75vw;
            }

            &.\/heightFit {
                img,
                video {
                    height: 55vh;
                }
            }

            &.\/widthFit {
                img,
                video {
                    width: 75vw;
                }
            }

            .-progress {
                .-bar {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    @media (max-width: $sm) {
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                    }
                }

                .-caption {
                    margin-top: 0.1rem;
                    text-align: right;

                    em {
                        color: grey;
                    }

                    .-adjective {
                        &.\/danger {
                            color: indianred;
                        }

                        &.\/warning {
                            color: orange;
                        }

                        &.\/success {
                            color: green;
                        }
                    }
                }

                @media (max-width: $sm) {
                    width: calc(100% - 2 * 5rem);
                    margin-top: 3rem;
                }
                @media (max-width: $xs) {
                    width: calc(100% - 2 * 1rem);
                    margin-top: 2rem;
                }
            }

            @media (max-width: $sm) {
                @include flex();
                padding: 1rem 0 2rem 0;
                background-color: transparent;
            }
        }
    }

    &.\/apiRequestExample {
        .-dialog {
            width: 50rem;
        }

        .-content {
            width: 100%;

            .-tabs {
                @include flex(center, center, row);

                .-choice {
                    margin: 0 0.6rem;

                    &.\/disabled {
                        label,
                        input {
                            opacity: 0.4;
                        }
                    }
                }
            }

            .-data {
                width: 100%;
            }

            .-more {
                padding: 1rem 0.4rem 0.4rem;
                text-align: center;

                > span {
                    font-style: italic;
                    font-size: 90%;
                }
            }
        }
    }

    &.\/play {
        overflow: hidden;
        transition: all 0s !important;

        * {
            transition: all 0s !important;
        }

        .-dialog {
            width: auto;

            > .-header {
                > .-close {
                    right: -2rem;
                    fill: white;
                }

                > .-title {
                    height: 0;
                    color: transparent;
                    background: transparent;
                }
            }

            .-contentShell {
                padding: 0;
                background-color: transparent;
            }
        }

        iframe {
            border-radius: $borderRadiusMd;
        }
    }

    &.\/theme {
        .-content {
            @include flex();

            .input {
                margin-bottom: 1rem;
            }

            .-colors {
                @include flex(center, center, row);
                flex-wrap: wrap;
                width: 100%;

                .-color {
                    transition: background-color 0s;
                    margin: 0.4rem;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 100%;
                    border-color: lightgrey;
                    border-style: solid;
                    border-width: 0.1rem;
                    cursor: pointer;

                    &.\/focused {
                        box-shadow: 0 0 0 0.2rem f962c53a_0958_46f7_94d5_d63d329ec14f();
                    }
                }
            }

            .-colorPicker {
                margin: 1rem;

                > div:nth-child(2) > div:nth-child(1) > div:nth-child(1),
                > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
                    display: none;
                }
            }

            .-footer {
                @include flex();
                padding: 1rem;
                width: 100%;

                .-submit {
                    @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                }
            }
        }
    }

    &.\/share {
        .-dialog {
            width: 50rem;

            .-contentShell {
                padding: 1rem;
            }
        }

        .-content {
            .-preview {
                width: 100%;
                white-space: normal;
                background: wheat;
                padding: 1rem 3rem 1rem 1rem;
                border-radius: $borderRadiusMd;
                word-break: break-word;
                margin-bottom: 0.3rem;
                position: relative;

                .-clipboard {
                    font-size: 125%;
                    background-color: white;
                    border-radius: 0 $borderRadiusMd 0 $borderRadiusMd;
                    border-style: solid;
                    border-color: saddlebrown;
                    border-width: 0.1rem;
                    color: saddlebrown;
                    cursor: pointer;
                    padding: 0.375rem 0.75rem;

                    &Shell {
                        position: absolute !important;
                        top: 0;
                        right: 0;
                    }
                }
            }

            .-parameters {
                @include flex(center, flex-start, row);
                padding-top: 0.5rem;

                .-colorPicker {
                    width: 10rem;
                    @media (max-width: $xs) {
                        width: 100% !important;
                    }
                }

                .-inputs {
                    @include flex(space-between, flex-start, row wrap);
                    width: calc(100% - 10rem);
                    padding-left: 1rem;

                    .-input {
                        &.\/width,
                        &.\/height {
                            width: calc(50% - 0.8rem / 2);
                            margin-bottom: 1rem;
                        }

                        &.\/autoplay,
                        &.\/muted,
                        &.\/title,
                        &.\/social,
                        &.\/controls,
                        &.\/loop {
                            width: calc(50% - 0.8rem / 2);
                            margin: 0.5rem 0;
                        }
                    }

                    @media (max-width: $xs) {
                        width: 100%;
                        padding-left: initial;
                        padding-top: 1.4rem;
                    }
                }

                @media (max-width: $xs) {
                    flex-flow: row wrap;
                }
            }
        }

        @media (min-width: $md + 1) {
            overflow: hidden;
        }
    }
}
