.template\:modal {
    &.\/config {
        .-content {
            @include flex(center, center, row wrap);
            width: 100%;
            .-name,
            .-workspaces {
                width: 100%;
                margin-bottom: 0.6rem;
            }
            .-submit,
            .-remove {
                margin: 1rem;
            }
            .-beware {
                font-size: 90%;
            }
            .or {
                width: 100%;
            }
        }
    }
}
