.image {
    position: relative;
    background-color: $colorGreyCloudHex;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    transition: all 0s;
    width: 4rem;
    height: 4rem;
    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
    &.\/loading {
        @include loading2();
        img {
            @include hidden();
        }
    }
    * {
        transition: all 0s;
    }
}
