.bo\:list {
    @include flex();
    padding: 1.3rem 1.2rem 1.7rem 1.2rem;
    background-color: white;
    border-radius: $borderRadiusMd;
    width: 100%;
    position: relative;
    .-list {
        width: 100%;
        th {
            border: 0.1rem solid #dddddd;
            text-align: left;
            padding: 0.4rem 0.7rem;
            min-width: 6rem;
        }
        td {
            border: 0.1rem solid #dddddd;
            text-align: left;
            > a {
                @include flex(flex-start, center);
                padding: 0.4rem 0.7rem;
            }
            > div {
                padding: 0.4rem 0.7rem;
            }
        }
        th {
            position: relative;
            svg {
                position: absolute;
                top: 50%;
                right: 0.7rem;
                transform: translateY(-50%);
                &:first-of-type {
                    opacity: 0.3;
                }
            }
        }
        .-item:nth-child(odd) {
            background-color: #eeeeee;
        }
    }
    .-mosaic {
        @include flex(center, flex-start, row wrap);
        width: 100%;
    }
    .-loading,
    .-none {
        width: 100%;
        padding-top: 2rem;
        text-align: center;
    }
    .-total {
        position: absolute;
        font-size: 80%;
        color: grey;
        top: -1.6rem;
        right: 0.4rem;
    }
    .-controls {
        @include flex(space-between, flex-start, row);
        width: 100%;
        height: 3.5rem;
        &.\/top {
            padding-bottom: 1rem;
        }
        &.\/bottom {
            padding-top: 1rem;
        }
        .-paginationShell {
            @include flex(center, flex-start, row);
            position: relative;
        }
        .-size {
            @include flex();
            margin-left: 1rem;
            .-select {
                width: 6rem;
                margin-left: 0.6rem;
            }
        }
    }
}
