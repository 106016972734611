.objectInput {
    @include flex(flex-start, center, row wrap);
    .-row {
        @include flex(flex-start, center, row);
        margin-bottom: 0.4rem;
        width: 100%;
        .-input {
            flex-grow: 1;
            &.\/value {
                input {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }
            &.\/label {
                input {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-left-width: 0 !important;
                }
            }
        }
        .-remove,
        .-add {
            @include flex();
            user-select: none;
            margin-left: 0.4rem;
            cursor: pointer;
            width: 1.2rem;
        }
        .-remove {
            svg {
                width: 100%;
                fill: $colorRedIndianHex;
            }
        }
        .-add {
            font-size: 130%;
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            font-weight: bold;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
