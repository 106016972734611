@import '~bootstrap/scss/bootstrap.scss';
@import '../mixin'; // /!\ That is very dirty... but needed because bootstrap overrides our color function. Will not be necessary using @use/@forward (migration to come in a near future)

.modal .-dialog {
    pointer-events: auto;
}

.dropdown {
    &-item {
        cursor: pointer;
        a {
            color: initial;
            text-decoration: none;
            &:hover {
                color: initial;
                text-decoration: none;
            }
        }
    }
    &-menu {
        box-shadow: 0 0.1rem 0.4rem -0.1rem grey;
        border: none;
    }
}

.form {
    &-check {
        padding-left: 0;
        &-label {
            @include flex(flex-start, flex-start, row);
            user-select: none;
            * {
                user-select: none;
            }
        }
    }
    &-control {
        &:focus {
            border-color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            box-shadow: none;
        }
    }
}

.custom {
    &-checkbox {
        z-index: 0;
    }
    &-file {
        &-label {
            z-index: -1;

            &:after {
                content: '\f093' !important;
                font-family: 'Font Awesome 5 Free', Times, serif;
            }
        }
    }
    &-control {
        z-index: unset;
        &-input {
            ~ .custom-control-label::before {
                border: #adb5bd solid 1px;
            }

            &:not(:checked) ~ .custom-control-label::before {
                background-color: white !important;
            }
            &:checked ~ .custom-control-label::before {
                color: white;
                border-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
                background-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
            }
            &:focus:not(:checked) ~ .custom-control-label::before {
                border-color: f962c53a_0958_46f7_94d5_d63d329ec14f() !important;
            }
            &:focus ~ .custom-control-label::before {
                box-shadow: 0 0 0 0.2rem ac7fc58d_20be_45c1_8b1d_554ad0c3eeef();
            }
        }
    }
}

.page-item.active .page-link {
    z-index: unset;
}
