.template\:snippet {
    @include flex(center, center, column wrap);
    @include shadow();
    @include discreetLink();
    background: $colorGreyCloudHex;
    width: 20rem;
    border-radius: $borderRadiusLg;
    margin: 0.7rem;
    cursor: default;
    z-index: 0;
    position: relative;
    &.\/enterable {
        cursor: pointer;
    }
    &.\/locked {
        opacity: 0.3;
    }
    &:hover {
        opacity: 1;
        box-shadow: 0 0.2rem 0.7rem #c6c6cb;
        > .-locked {
            opacity: 1;
        }
        > .-buttons {
            > * {
                width: 1.7rem !important;
                height: 1.7rem !important;
                visibility: visible;
                svg {
                    width: auto !important;
                    * {
                        display: initial !important;
                    }
                }
            }
        }
        @media (min-width: $md + 1) {
            transform: translateY(-0.4rem);
            transition-timing-function: ease-out;
        }
    }
    > .-preview {
        @include flex(center, center, row);
        flex-grow: 1;
        border-radius: $borderRadiusLg $borderRadiusLg 0 0;
        height: 11.25rem;
        overflow: hidden;
        width: 100%;
        background-color: $colorGreySnowHex;
        position: relative;
        img,
        video {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            z-index: 1;
            &:focus {
                outline: none;
            }
        }
    }
    > .-footer {
        padding: 0.6rem 4rem 0.6rem 1rem;
        text-align: left;
        width: 100%;
        position: relative;
        border-radius: 0 0 $borderRadiusLg $borderRadiusLg;
        > .-name {
            font-size: 95%;
            font-weight: $fontWeightMd;
            line-height: 110%;
            margin-bottom: 0.3rem;
        }
    }
    > .-buttons {
        @include ca64ead0_6994_48d1_b530_1779a4ccb5f6();
        position: absolute;
        top: 1rem;
        right: -0.7rem;
        z-index: 2;
        > * {
            margin-bottom: 0.6rem;
            width: 0 !important;
            height: 0 !important;
            visibility: hidden;
            svg {
                width: 0 !important;
                * {
                    display: none !important;
                }
            }
        }
        .-lock {
            * {
                border-color: darkorange !important;
                fill: darkorange;
            }
        }
        .-remove {
            * {
                border-color: indianred !important;
                fill: indianred;
            }
        }
    }
    > .-locked {
        position: absolute;
        left: 1rem;
        top: 0;
        text-align: left;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 75%;
        z-index: 2;
        opacity: 0;
        padding: 0.2rem 0.6rem;
        border-radius: 0 0 $borderRadiusMd $borderRadiusMd;
        small {
            line-height: 120%;
            display: inline-block;
        }
    }
}
