.bo\:listBox {
    @include flex();
    padding: 2rem;
    position: relative;
    .-header {
        width: 100%;
        text-align: center;
        font-size: 130%;
        font-weight: $fontWeightXl;
        padding-bottom: 1rem;
    }
    .-new {
        position: absolute;
        top: 2rem;
        right: 2rem;
        svg {
            width: 2.4rem;
            cursor: pointer;
            fill: var(--color-1-hsl);
            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }
    }
    .-toggleFilters {
        width: 100%;
        text-align: center;
        user-select: none;
        margin-bottom: 1rem;
        span {
            text-decoration: underline;
            color: blue;
            cursor: pointer;
        }
    }
}
