.video\:library {
    @include flex();
    width: 100%;
    position: relative;
    background-color: white;
    > .-filters {
        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
        @include flex(center, flex-end, row wrap);
        width: 100%;
        padding: 0.4rem 1rem 1.3rem;
        text-align: left;
        label {
            color: fa06f195_a6c3_4d86_9334_79323bbe5ce3();
        }
        > .-filter {
            min-width: 14rem;
            margin: 0.6rem 0.6rem 0 0;
            @media (max-width: $xs) {
                min-width: auto;
                width: 100%;
            }
        }
        > .-submit {
            background-color: white;
            margin-top: 1rem;
            font-weight: $fontWeightLg;
            box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.2);
            &:hover,
            &:focus {
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            }
            &:hover {
                box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.1);
            }
            &:focus {
                box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.2);
            }
            .-shell {
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            }
        }
    }
    > .-videos {
        @include flex(center, stretch, row wrap);
        padding: 2rem 1rem 10rem;
        max-width: 70rem;
        > .-noItems {
            padding-top: 0.6rem;
        }
        @media (max-width: $sm) {
            max-width: 100%;
        }
        @media (max-width: $xs) {
            padding: 0.7rem 0 1.4rem;
        }
    }
}
