.trialBanner {
    background-color: $colorGreenGrassHex;
    color: white;
    padding: 0.4rem 0.4rem;
    width: 100%;
    text-align: center;
    user-select: none;

    &.\/warning {
        background-color: darkorange;
    }

    &.\/danger {
        background-color: red;
    }

    .-getLicenses {
        margin-left: 0.3rem;
        border: 0.14rem solid white;
        padding: 0.1rem 0.3rem;
        border-radius: $borderRadiusMd;
        font-weight: bold;
        cursor: pointer;
        color: white;
        text-decoration: none;

        &:hover,
        &:focus {
            color: white;
            text-decoration: none;
        }
    }
}
