.organization\:dangerZonePage {
    .-creditsBar {
        margin-bottom: 1.2rem;

        > .-title {
            @include e32d2781_67c8_4008_9b12_a5ad124b565c();
        }
    }

    .-dangerZone {
        @include flex(center, center, row wrap);
        padding: 1rem;

        .-speech {
            width: 100%;
            text-align: center;
            color: $colorRedIndianHex;
        }

        button {
            margin-top: 1rem;
        }

        > * {
            margin: 0.5rem;
        }
    }
}
