.button {
    padding: 0;
    background-color: var(--color-1-hsl);
    transition: all 0s;
    border-radius: $borderRadiusMd;
    &:hover {
        color: ebeed49d_10cb_4593_a48a_dab771353e78();
    }
    &:focus {
        box-shadow: none;
        color: ebeed49d_10cb_4593_a48a_dab771353e78();
    }
    &.\/disabled {
        cursor: not-allowed !important;
        opacity: 0.74 !important;
    }
    &.\/success {
        background-color: var(--color-1-hsl);
    }
    &.\/warning {
        background-color: coral;
    }
    &.\/danger {
        background-color: $colorRedIndianHex;
    }
    &.\/info {
        background-color: cornflowerblue;
    }
    &.\/warning,
    &.\/danger,
    &.\/info {
        .-shell {
            color: white;
        }
        &:hover,
        &:focus {
            color: white;
            .-shell {
                color: white;
            }
        }
    }
    .-shell {
        // Buttons aren't able to handle flexbox properties. Solution: shell inside
        @include ab2e2ba1_a669_4bd9_95d7_715ebbd636f1();
        width: 100%;
        height: 100%;
    }
}
