.workspace\:managePage {
    @include f6fe0b12_cb47_44a1_9adf_54a7fd355e8b();
    @include aecf8dbe_5e54_4dfb_99b3_ca43f3a588f4();
    &.\/isolated {
        padding-left: 1rem;
    }
    .or {
        margin-top: 1.5rem;
        width: 30rem;
        color: lightslategray;
        @media (max-width: $sm) {
            margin-top: 0.8rem;
            width: 100%;
        }
    }
    > .-logo {
        margin: 2rem 1rem 0;
        width: 10rem;
        height: 10rem;
        .uploader-zone {
            width: 10rem;
            height: 10rem;
        }
    }
    .-enter {
        text-align: center;
        button {
            margin-top: 1rem;
        }
        .-caption {
            font-size: 85%;
            max-width: 20rem;
            font-style: italic;
            margin-top: 0.4rem;
            color: grey;
        }
    }
    .-dangerZone {
        @include flex();
        padding: 0.4rem;
        > * {
            margin: 0.6rem 0.4rem;
        }
    }
}
