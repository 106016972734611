.menu {
    position: relative;
    > .-logo {
        @include flex();
        cursor: pointer;
        height: 100%;
        @media (max-width: $sm) {
            height: 2rem;
            position: absolute;
            top: calc(#{$headerHeight} / 2);
            left: 1rem;
            transform: translateY(-50%);
        }
    }
}
