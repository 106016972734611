.user\:profilePage {
    @include flex(flex-start, center, column);
    padding: 1rem 1rem 1.5rem 1rem;

    > .or {
        width: 50%;
        margin-top: 1.5rem;
        color: lightslategray;
        @media (max-width: $sm) {
            width: 100%;
            margin-top: 0.6rem;
        }
    }

    .-backHome {
        @include discreetLink();

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .-completions {
        @include flex();
        > * {
            margin: 0 0.3rem;
        }
        .-noteOnLicense,
        .-state {
            background-color: $colorGreenNoteBackgroundHex;
            color: $colorGreenNoteTextHex;
            padding: 0.3rem 0.6rem;
            border-radius: $borderRadiusMd;
            margin-top: 0.4rem;
            &:after {
                content: '\f00c' !important;
                font-family: 'Font Awesome 5 Free', Times, serif;
                margin-left: 0.8rem;
            }
            &.\/warning {
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
                &:after {
                    content: '\f017' !important;
                }
            }
        }
        .-state {
            &.\/pendingWithEmailSent {
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
                font-weight: bold;
                font-size: 110%;
                &:after {
                    content: '' !important;
                }
            }

            &.\/pendingWithNoEmailSentYet {
                @include flex(center, center, column);
                background-color: $colorOrangeNoteBackgroundHex;
                color: $colorOrangeNoteTextHex;
                font-weight: bold;
                font-size: 110%;
                max-width: 40rem;
                padding: 1rem 1rem 1.3rem 1rem;
                margin: 1rem;
                &:after {
                    content: '' !important;
                }
            }

            &.\/locked {
                color: red;
            }

            .-img {
                width: 3rem;
                transform: rotate(-15deg);
                margin: 0 1rem 1rem 0;
            }

            .-speech {
                padding: 0.7rem;
                font-size: 90%;
                font-style: italic;
                opacity: 0.4;
                color: initial;
            }
        }
    }

    > .-title {
        @include flex(center, center, column);
        margin-top: 1rem;
        width: 100%;
        text-align: center;
        font-size: 130%;
        font-weight: $fontWeightXl;
    }

    .-generalForm {
        @include flex(center, flex-start, row);
        background-color: white;
        border-radius: $borderRadiusMd;
        padding: 1rem 1rem 5rem 1rem;
        margin: 1rem 2.5rem;
        width: 100%;
        max-width: 50rem;
        position: relative;

        .-locales {
            @include flex();
            .-locale {
                margin: 0.2rem;
                width: 1.7rem;
                cursor: pointer;
                opacity: 0.5;
                &.\/current,
                &:hover,
                &:focus {
                    opacity: 1;
                }
            }
        }

        .-uploader {
            margin: 1rem;

            .uploader-zone {
                width: 12rem !important;
                height: 12rem !important;
                border-radius: 500rem;
            }
        }

        .-inputs {
            @include flex(flex-start, flex-start, row wrap);
            width: calc(100% - 2 * 1rem - 12rem);
            padding: 1rem;
            position: relative;
            @media (max-width: $xs) {
                width: 100%;
            }
        }

        .-firstname,
        .-lastname,
        .-email,
        .-phone,
        .-job,
        .-badge,
        .-language {
            width: calc(50% - 2 * 0.3rem);
            margin: 0 0.3rem 0.6rem;
            @media (max-width: $sm) {
                width: 100%;
            }
        }

        .localeSwitcher {
            width: 100%;
            margin-top: 1rem;
        }

        .-submit {
            margin-top: 1rem;
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            @media (max-width: $sm) {
                position: initial;
                margin-top: 0;
                bottom: 0;
                right: 0;
            }
            @media (max-width: $xs) {
                width: calc(100% - 2 * 1rem);
            }
        }

        @media (max-width: $sm) {
            align-items: start;
            flex-flow: row wrap;
            padding: 1rem 0 2rem;
        }
    }

    .-organizationBadges {
        @include flex(center, stretch, row wrap);
        padding: 0.5rem 10rem 0;
        width: 100%;

        .-organizationBadge {
            @include flex(flex-start, center, column wrap);
            background-color: white;
            position: relative;
            width: 10rem;
            min-height: 10rem;
            padding: 1rem;
            margin: 0.7rem;
            border-radius: $borderRadiusMd;

            &:hover,
            &:focus {
                @include shadow();

                .-remove {
                    svg {
                        width: 1.2rem;
                    }
                }
            }

            .-remove {
                position: absolute;
                top: 0.7rem;
                right: 0.7rem;
                cursor: pointer;

                svg {
                    width: 0;
                    fill: red;
                }
            }

            .-badgeIcon {
                height: 4rem;
            }

            .-workspace {
                width: 100%;
                text-align: center;
                font-size: 90%;
                opacity: 0.6;
                margin-top: 1rem;
            }

            .-badge {
                width: 100%;
                margin-top: 0.8rem;
                text-align: center;
                font-weight: $fontWeightXl;
            }
        }

        .-addBadgeForm {
            @include flex(center, flex-start, row wrap);
            position: relative;
            border-radius: $borderRadiusMd;
            width: 17rem;
            height: 10rem;
            padding: 0 1rem;
            margin: 0.7rem;

            .-workspace {
                width: 100%;
                margin-bottom: 0.3rem;
            }

            .-badge {
                width: 100%;
            }

            .-explanations {
                width: 10rem;
                position: absolute;
                left: 100%;
                opacity: 1;
                font-size: 90%;
                color: lightslategray;
                padding-left: 1rem;

                .-icon {
                    @include flex(flex-start);
                    width: 100%;
                    margin-bottom: 0.6rem;

                    svg {
                        height: 3rem;
                    }
                }

                &.\/hidden {
                    opacity: 0;
                    left: 150%;
                }

                @media (max-width: $sm) {
                    display: none;
                }
            }

            .-validate {
                margin-top: 1rem;
                text-decoration: underline;
                cursor: pointer;
                color: f962c53a_0958_46f7_94d5_d63d329ec14f();
                user-select: none;
            }
        }

        @media (max-width: $sm) {
            padding: 0.5rem 1rem 0;
        }
    }

    .-terms {
        @include flex();

        margin: 1rem 0 0;

        > * {
            margin: 0.5rem;
        }

        a {
            @include ab2e2ba1_a669_4bd9_95d7_715ebbd636f1();
            background-color: color(1, $l: calc(0.9 * var(--color-1-l)));
            border-radius: $borderRadiusMd;
        }
    }

    .-dangerZone {
        @include flex();
        width: 50rem;
        padding: 0 1rem 1rem 1rem;
        margin: 1rem;
        border-radius: $borderRadiusMd;

        .-pokeUserForActivationForm,
        .-lockUserForm,
        .-addDirectorBadgeForm,
        .-removeDirectorBadgeForm,
        .-excludeUserFromWorkspaceForm {
            margin: 0.5rem;
        }

        .-pokeUserForActivationForm {
            .-dot {
                background-color: white !important;
            }
        }

        @media (max-width: $sm) {
            padding: 0.6rem;
            margin: 0;
            width: 100%;
            > * {
                margin: 0.3rem;
            }
        }
    }

    .-emailNotificationSettings,
    .-notificationSettings {
        padding: 1.5rem 1rem 0;

        .-setting {
            @include flex(flex-start, flex-start, row);
            margin: 1rem;

            > .-label {
                margin-left: 1rem;
            }
        }

        @media (max-width: $sm) {
            padding: 1rem 0 0;
        }
    }

    @media (max-width: $sm) {
        padding: 1rem;
    }
}
