.questionMark {
    display: inline-block;
    > .-shell {
        @include flex(center, center, row);
        > .-icon {
            display: inline-block;
            svg {
                width: 100%;
                position: relative;
                bottom: 0.04rem;
                > path:first-of-type {
                    fill: deepskyblue;
                }
            }
        }
    }
}
