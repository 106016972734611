.user\:checkoutForm {
    @include flex(space-between, flex-start, row wrap);
    width: 100%;
    .-cardElement,
    .-sepaElement {
        border-radius: 0.25rem;
        padding: 12px;
        border: 1px solid #ced4da;
        max-height: 44px;
        width: 100%;
        background: white;
        box-sizing: border-box;
        &Label {
            @include flex(flex-start, center, row);
            .form-check-label {
                align-items: center;
            }
        }
    }
    .-sepaWarning {
        @include flex(flex-end);
        width: 100%;
        font-style: italic;
        color: grey;
        text-align: justify;
        text-align-last: end;
        margin-top: 1rem;
        font-size: 80%;
    }
    label {
        width: 100%;
        text-align: left;
    }
    .-quantity,
    .-name,
    .-taxNumber,
    .-company,
    .-sepaMode,
    .-location,
    .-paymentMethod,
    .-toBeLicensed,
    .-sponsorCode,
    .-submit {
        width: 100%;
    }
    .-firstname,
    .-email {
        margin-right: 0.3rem;
    }
    .-lastname,
    .-phone {
        margin-left: 0.3rem;
    }
    .-quantity,
    .-name,
    .-taxNumber,
    .-company,
    .-sepaMode,
    .-location,
    .-firstname,
    .-lastname,
    .-email,
    .-phone,
    .-toBeLicensed,
    .-sponsorCode {
        margin-bottom: 0.6rem;
    }
    .-firstname,
    .-lastname,
    .-email,
    .-phone {
        width: calc(50% - 0.3rem);
    }
    .-terms,
    .-now {
        width: 100%;
        margin-top: 0.6rem;
        margin-bottom: 1rem;
    }
    .-flag {
        width: 1.2rem;
    }
    .-paymentMethod {
        > label {
            font-size: 80%;
            font-style: italic;
        }
        .-select {
            caret-color: transparent;
            * {
                caret-color: transparent;
            }
            [class$='-option'],
            [class$='-singleValue'] {
                > span {
                    svg {
                        &.-flag,
                        &.-brand {
                            height: 1.4rem;
                            width: 1.4rem;
                            margin-right: 0.5rem;
                            position: relative;
                            bottom: 0.02rem;
                        }
                    }
                }
                .-hider {
                    display: inline-block;
                    font-weight: $fontWeightXl;
                    margin: 0 0.4rem;
                }
                em {
                    opacity: 0.8;
                    font-size: 90%;
                    margin-left: 0.3rem;
                }
            }
        }
    }
    .-referent {
        @include flex(center, flex-start);
        margin-bottom: 0.6rem;
    }
    .-warning {
        margin-bottom: 0.8rem;
        border-radius: $borderRadiusMd;
        padding: 0.8rem 1.1rem;
        background: $colorOrangeNoteBackgroundHex;
        color: $colorOrangeNoteTextHex;
        strong {
            color: $colorOrangeNoteStrongTextHex;
        }
    }
    .-submit {
        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
    }
    .-redirect {
        text-align: center;
        width: 100%;
        padding: 1rem 0.2rem 0;
        font-size: 85%;
    }
    .or {
        width: 100%;
        .-body {
            width: 0;
            padding: 0;
            overflow: hidden;
        }
        .-wing {
            border-color: lightgrey;
        }
    }
}
