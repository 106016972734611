.team {
    @include flex();
    svg {
        transition: all 0.3s;
    }

    .-licenses {
        @include flex();
        padding: 1rem 2rem 0.3rem 2rem;
        width: 100%;
        text-align: center;
        input {
            width: 6rem !important;
        }
        .-row {
            @include flex();
            width: 100%;
            margin: 0.3rem;
            > .-core {
                @include flex(flex-end);
                //width: 24rem;
                .-label {
                    user-select: none;
                    > .-core {
                        opacity: 0.6;
                        user-select: none;
                    }
                }
                .-validate {
                    @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                    margin-left: 1rem;
                    padding: 0.3rem 0.6rem;
                    border-radius: $borderRadiusMd;
                    user-select: none;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.04);
                    }
                    &.\/off {
                        opacity: 0.6;
                        cursor: not-allowed;
                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
            }
            &.\/maxLicensedWithPeriodicCloudyMembersCount {
                .-label {
                    margin-right: 0.7rem;
                }
            }
            &.\/maxLicensedWithLifetimeLocalMembersCount {
                .-label {
                    margin-right: 2.1rem;
                }
            }
        }
        .input-group-append,
        .input-group-prepend {
            .input-group-text {
                padding: 0;
                > * {
                    @include flex();
                    width: 100%;
                    height: 100%;
                    font-weight: bold;
                    padding: 0 0.4rem;
                    cursor: pointer;
                }
            }
        }
    }
    .-directors,
    .-nonDirectors {
        @include flex(center, flex-start, row wrap);
        width: 100%;
        padding: 0.5rem;
        .-user {
            @include flex();
            @include discreetLink();
            margin: 0.5rem;
            width: 15rem;
            background-color: white;
            border-radius: $borderRadiusMd;
            padding: 1rem;
            cursor: pointer;
            position: relative;
            &:hover {
                @include shadow();
                opacity: 1;
                .-edit {
                    visibility: visible;
                    height: 2rem;
                }
            }
            &.\/noBorder {
                border: none !important;
            }
            &.\/ready {
                .-portrait {
                    border-color: limegreen;
                }
                .-state {
                    color: limegreen;
                }
            }
            &.\/pendingWithEmailSent,
            &.\/pendingWithNoEmailSentYet {
                .-portrait {
                    border-color: orange;
                }
                .-state {
                    color: orange;
                }
            }
            &.\/pendingWithNoEmailSentYet {
                .-stateImg {
                    margin-right: 0.3rem;
                    width: 1.2rem;
                    transform: translateY(-0.1rem);
                }
            }
            .\/locked {
                opacity: 0.4;
                cursor: initial;
                .-edit {
                    display: none;
                }
                .-portrait {
                    border-color: red;
                }
                .-state {
                    color: red;
                }
            }
            .-edit {
                visibility: hidden;
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                height: 0;
            }
            .-portrait {
                width: 6rem;
                height: 6rem;
                border-radius: 500rem;
                border-style: solid;
                border-width: $borderWidthMd;
            }
            .-names {
                padding-top: 0.6rem;
                width: 100%;
                text-align: center;
            }
            .-state {
                width: 100%;
                text-align: center;
                font-weight: $fontWeightLg;
            }
            .-license {
                margin-top: 0.4rem;
            }
        }
        @media (max-width: $xs) {
            padding: 0;
        }
    }
    .-addForm {
        @include flex(center, center, column);
        width: 100%;
        .-tabs {
            @include flex(center, center, row);
            > * {
                margin: 0 0.5rem;
            }
        }
        .-tabs,
        .-firstname,
        .-lastname,
        .-email,
        .-user,
        .-badge,
        .-phone,
        .-workspaces {
            width: 20rem;
            margin: 0.5rem;
            @media (max-width: $xs) {
                width: 100%;
            }
        }
        .-submit {
            margin-top: 1rem;
        }
    }
    @media (max-width: $xs) {
        width: 100%;
    }
    .-showAddForm {
        @include flex();
        width: 100%;
        &.disabled {
            opacity: 0.7;
            cursor: initial;
            span {
                color: grey;
            }
        }
        span {
            text-align: center;
            margin-top: 1rem;
            text-decoration: underline;
            cursor: pointer;
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            user-select: none;
        }
        &.\/afterPurchase {
            span {
                @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                padding: 0.3rem 0.6rem;
                text-decoration: none;
                border-radius: $borderRadiusMd;
            }
        }
    }
}
