.user\:notification {
    @include flex(flex-start, flex-start, row wrap);
    @include discreetLink();
    background-color: white;
    width: 20rem;
    padding: 1rem;
    text-align: left;
    transition: background-color 3s;
    user-select: none;
    &:nth-of-type(2n + 1) {
        background-color: $colorGreyCloudHex;
        @media (max-width: $sm) {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    &.\/success {
        &.\/new {
            background-color: rgba(0, 255, 0, 0.15) !important;
        }
        .-icon,
        strong {
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
        }
    }
    &.\/danger {
        &.\/new {
            background-color: rgba(255, 0, 0, 0.15) !important;
        }
        .-icon,
        strong {
            color: $colorRedIndianHex;
        }
    }
    &.\/warning {
        &.\/new {
            background-color: rgba(254, 110, 0, 0.15) !important;
        }
        .-icon,
        strong {
            color: darkorange;
        }
    }
    &.\/info {
        &.\/new {
            background-color: rgba(0, 0, 255, 0.15) !important;
        }
        .-icon,
        strong {
            color: dodgerblue;
        }
    }
    .-icon {
        font-weight: $fontWeightLg;
    }
    .-content {
        width: 100%;
    }
    .-meta {
        @include flex(space-between, flex-start, row);
        width: 100%;
        padding-top: 0.5rem;
        &.\/onlyDate {
            @include flex(flex-end);
        }
        .-date {
            font-size: 80%;
            max-width: calc(50% - 1rem);
            width: 100%;
            text-align: right;
            font-style: italic;
            color: grey;
            @media (max-width: $sm) {
                max-width: calc(80% - 1rem);
            }
        }
    }
    @media (max-width: $md) {
        background-color: initial;
    }
}
