.user\:modal {
    &.\/login {
        overflow: hidden;
        .-dialog {
            width: 20rem;
        }
        .-content {
            @include flex(center, flex-start, row wrap);
            .-email,
            .-password {
                width: 100%;
                margin: 0.5rem;
                @media (max-width: $sm) {
                    margin: 0.5rem 0;
                }
            }
            .-submit {
                margin: 1.5rem 0.5rem 0.5rem 0.5rem;
                width: 100%;
                @media (max-width: $sm) {
                    margin: 1.5rem 0 0.5rem;
                }
            }
            > .input .-shell {
                width: 100%;
            }
        }
    }
}
