.template\:library {
    @include flex();
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: white;
    > .-tabs {
        @include flex(center, flex-start, row);
        width: 100%;
        height: 2.4rem;
        z-index: 2;
        > .-tab {
            @include flex();
            background: $colorGreyCottonHex;
            color: $colorGreySlateHex;
            font-weight: $fontWeightMd;
            width: 50%;
            cursor: pointer;
            opacity: 0.8;
            height: 2.4rem;
            transition: box-shadow 0s, height 0.3s;
            * {
                transition: color 0s;
            }
            svg {
                margin-right: 0.6rem;
            }
            span {
                @media (max-width: $sm) {
                    display: none;
                }
            }
            &.\/active {
                @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
                cursor: initial;
                height: 2.8rem;
                opacity: 1;
                &:first-of-type {
                    border-radius: 0 0 $borderRadiusMd 0;
                    box-shadow: -0.1rem 0.2rem 0.2rem -0.1rem color(1, $l: 30%);
                }
                &:last-of-type {
                    border-radius: 0 0 0 $borderRadiusMd;
                    box-shadow: 0.1rem 0.2rem 0.2rem -0.1rem color(1, $l: 30%);
                }
            }
        }
    }
    > .-templates {
        @include flex(center, stretch, row wrap);
        padding: 2rem 1rem 2.6rem;
        max-width: 70rem;
        > .-noItems {
            padding-top: 1rem;
        }
        > .-loadMore {
            margin-top: 1.4rem;
            display: inline-block;
            width: 100%;
            text-align: center;
            color: f962c53a_0958_46f7_94d5_d63d329ec14f();
            text-decoration: underline;
            cursor: pointer;
        }
        @media (max-width: $sm) {
            max-width: 100%;
        }
        @media (max-width: $xs) {
            padding: 1.4rem 0;
        }
    }
    > .-footer {
        @include b127860c_c45c_4f23_834e_24d4f8b67f1a();
        @include flex(center, center, row);
        width: 100%;
        padding: 2rem 1rem 4rem;
        color: ebeed49d_10cb_4593_a48a_dab771353e78();
        svg {
            fill: ebeed49d_10cb_4593_a48a_dab771353e78();
            width: 10rem;
            height: 10rem;
            margin: 1rem;
            * {
                fill: ebeed49d_10cb_4593_a48a_dab771353e78();
            }
            @media (max-width: $sm) {
                display: none;
            }
        }
        > .-text {
            @include flex(center, center, row wrap);
            width: 26rem;
            max-width: 100%;
            text-align: center;
            margin: 1rem;
            > .-catch {
                font-size: 160%;
                margin-bottom: 1.4rem;
                font-weight: bold;
            }
            .-btn {
                border: 0.12rem solid ebeed49d_10cb_4593_a48a_dab771353e78();
            }
            > .-dl {
                @include flex(center, center, row wrap);
                > .-btn {
                    @include flex(center, center, row);
                    padding: 0.4rem 1rem;
                    position: relative;
                    color: ebeed49d_10cb_4593_a48a_dab771353e78() !important;
                    border-radius: 500rem;
                    cursor: pointer;
                    background-color: color(1, $l: calc(var(--color-1-l) - 12%));
                    margin-bottom: 0.2rem;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
                a {
                    color: ebeed49d_10cb_4593_a48a_dab771353e78() !important;
                    &:hover,
                    &:focus {
                        color: ebeed49d_10cb_4593_a48a_dab771353e78() !important;
                    }
                }
                > .-rest {
                    width: 100%;
                }
            }
            > .-contact {
                @include flex(center, center, row wrap);
                > .-btn {
                    @include flex(center, center, row);
                    padding: 0.4rem 1rem;
                    position: relative;
                    color: ebeed49d_10cb_4593_a48a_dab771353e78() !important;
                    border-radius: 500rem;
                    cursor: pointer;
                    background-color: color(1, $l: calc(var(--color-1-l) - 6%));
                    margin-bottom: 0.2rem;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
                > .-rest {
                    width: 100%;
                }
            }
            .or {
                margin: 0.6rem;
                width: 16rem;
                max-width: 100%;
                font-size: 85%;
                .-wing {
                    border-color: ebeed49d_10cb_4593_a48a_dab771353e78();
                }
            }
        }
    }
}
