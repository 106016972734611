.video\:studio {
    .-compos {
        width: calc(100% - 320px - 3 * 2rem);
        position: relative;
        margin: 2rem 2rem 2rem 0;
        transition: 0s background-color;
        * {
            transition: 0s background-color;
        }
        label {
            font-size: 90%;
            padding-bottom: 0.2rem;
            user-select: none;
            #text {
                user-select: none;
            }
        }
        .form-check-label {
            margin-top: 1rem;
        }

        .form-check.disabled {
            label {
                cursor: not-allowed;
                &::before {
                    background-color: #b1b1b1 !important;
                    border-color: #b1b1b1 !important;
                }
            }
        }

        @media (min-width: 2101px) {
            width: calc(100% - 26rem - 3 * 2rem);
        }
        @media (max-width: $sm) {
            margin: 0 0 4rem;
            width: 100%;
        }
    }
}
