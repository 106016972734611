.localeSwitcher {
    @include flex(center, flex-start);
    width: 100%;
    .-locale {
        margin: 0.2rem;
        width: 1.7rem;
        cursor: pointer;
        opacity: 0.5;
        &.\/current,
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}
