.or {
    @include flex();
    &.\/danger {
        .-wing {
            border-color: red;
        }
        .-body {
            color: red;
        }
    }
    &.\/info {
        .-wing {
            border-color: cornflowerblue;
        }
        .-body {
            color: cornflowerblue;
        }
    }
    &.\/success {
        .-wing {
            border-color: limegreen;
        }
        .-body {
            color: limegreen;
        }
    }
    &.\/warning {
        .-wing {
            border-color: orange;
        }
        .-body {
            color: orange;
        }
    }
    .-wing {
        flex-grow: 1;
        height: 0;
        border-style: solid;
        border-width: $borderWidthSm 0 0 0;
        border-color: lightslategray;
    }
    .-body {
        padding: 0.5rem 1rem;
        user-select: none;
    }
}
