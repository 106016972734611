.tryPage {
    @include flex(flex-start, center, column);
    text-align: center;

    .-creditsBar > .-title {
        @include e32d2781_67c8_4008_9b12_a5ad124b565c();
    }

    .template\:library .-templates {
        padding-top: 0;
    }
}

.html[class*='/routed/frontOffice/'][class*='front-office::'] {
    &[class*='::try'] {
        background-color: white;
    }
}
